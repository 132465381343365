import {useDispatch} from "react-redux";
import {
    approveReviewReqAct,
    deleteReviewReqAct,
    fetchApprovedReviewsReqAct,
    fetchUnapprovedReviewsReqAct
} from "./reducers";
import {Review} from "../../../@types/types";

export const useReviewsActions = () => {
    const dispatch = useDispatch();

    const fetchApprovedReviews = (limit: number, offset: number) => {
        dispatch(
            fetchApprovedReviewsReqAct({
                reqRsltLimit: limit,
                reqRsltOffset: offset,
            })
        );
    }

    const fetchUnapprovedReviews = (limit: number, offset: number) => {
        dispatch(
            fetchUnapprovedReviewsReqAct({
                reqRsltLimit: limit,
                reqRsltOffset: offset,
            })
        );
    }

    const approveReview = (review: Review) => {
        dispatch(approveReviewReqAct({review}));
    }

    const deleteReview = (review: Review) => {
        dispatch(deleteReviewReqAct({review}));
    }


    return {
        fetchApprovedReviews,
        fetchUnapprovedReviews,
        approveReview,
        deleteReview,
    }
}