import React, {FC, useContext, useLayoutEffect, useRef, useState} from 'react';
import styles from './MobileNavMenu.module.css';
import closeImage from '../../../images/close-icon@2x.png';
import flowerImageLeft from '../../../images/flowers-about-me-home-page-1@2x.png';
import flowerImageRight from '../../../images/flowers-testimonial-home-page-1@2x.png';
import {MobileNavDisplayContext} from "../../../store/context/mobile-nav-context";
import {navigationClickHandler} from "../../../utils/utils";
import {useLocation, useNavigate} from "react-router-dom";
import useNavigationLocale from "../Navigation/NavigationLocale";
import {useLang} from "../../../store/context/lang-context";

interface MobileNavMenuProps {
}

const MobileNavMenu: FC<MobileNavMenuProps> = () => {
    const {isDisplayed, setDisplayed} = useContext(MobileNavDisplayContext);
    const navWrapper = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [touchStartX, setTouchStartX] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [touchStartY, setTouchStartY] = useState<number>(0);
    const [touchEndX, setTouchEndX] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [touchEndY, setTouchEndY] = useState<number>(0);
    const [navMenuHeight, setNavMenuHeight] = useState<number>(0);
    const location = useLocation();
    const navigator = useNavigate();
    const locales = useNavigationLocale();
    const {lang} = useLang();

    /**
     *  the required distance between touchStart and touchEnd to be detected as a swipe
     */
    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        /**
         * setting the touch end to 0, otherwise the swipe is fired even with usual touch events
         */
        setTouchEndX(0)
        setTouchStartX(e.targetTouches[0].clientX);
        setTouchStartY(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e: any) => {
        setTouchEndX(e.targetTouches[0].clientX);
        setTouchEndY(e.targetTouches[0].clientY);
    };

    const onTouchEnd = () => {
        if (!touchStartX || !touchEndX) {
            return;
        }

        const distanceX: number = touchStartX - touchEndX;
        const isLeftSwipe: boolean = distanceX > minSwipeDistance;
        /**
         const distanceY: number = touchStartY - touchEndY;
         const isRightSwipe: boolean = distanceX > minSwipeDistance;
         const isDownSwipe:boolean = distanceY > minSwipeDistance;
         const isUpSwipe:boolean = distanceY < minSwipeDistance;
         */

        if (isLeftSwipe) {
            setDisplayed();
        }
    };

    useLayoutEffect(() => {
        if (!isDisplayed) {
            setTimeout(() => {
                if (navWrapper.current) {
                    navWrapper.current.style.display = 'none';
                    setNavMenuHeight(document.documentElement.scrollTop + 68)
                }

            }, 500);
        } else {
            setNavMenuHeight(document.documentElement.scrollTop + 68)
            navWrapper.current.style.display = 'block';
        }
    }, [isDisplayed]);

    return (
        <div style={{top: `${navMenuHeight}px`}}
             ref={navWrapper}
             className={isDisplayed ? styles["mobile-nav-container"] : `${styles.slideout}`}
             data-testid="MobileNavMenu"
             onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
        >

            <img className={styles.close} src={closeImage} alt="icon-close"
                 onClick={() => setDisplayed()}/>
            <ul style={{
                visibility: isDisplayed ? 'visible' : 'hidden',
            }} className={"montserrat-normal-black-17px"}>

                <li className={styles.item} onClick={() => {
                    setDisplayed();
                    navigator(`/${lang}`)}
                }>
                    {locales.NAV_HOMEPAGE}
                </li>

                <li className={styles.item} onClick={() => {
                    setDisplayed();
                    navigationClickHandler.servicesClickHandler(location, navigator, lang);
                }}>
                    {locales.NAV_SERVICES}
                </li>

                <li className={styles.item} onClick={() => {
                    setDisplayed();
                    navigator(`${lang}/courses`)}
                }>
                    {locales.NAV_COURSES}
                </li>

                <li className={styles.item} onClick={() => {
                    setDisplayed();
                    navigationClickHandler.myWorkClickHandler(location, navigator, lang);
                }}>
                    {locales.NAV_GALLERY}
                </li>

                <li className={styles.item} onClick={() => {
                    setDisplayed();
                    navigationClickHandler.aboutMeClickHandler(location, navigator, lang);
                }}>
                    {locales.NAV_ABOUT_ME}
                </li>
                {
                 /*
                <li className={styles.item} onClick={() => {
                    navigationClickHandler.blogClickHandler(location, navigator, lang);
                }}>
                    {locales.NAV_BLOG}
                </li>
                  */
                }
            </ul>
            <img className={
                isDisplayed
                    ? `${styles["flower-left"]} ${styles["flower-grow"]}`
                    : `${styles["flower-left"]} ${styles["flower-shrink"]}`
            }
                 src={flowerImageLeft} alt="flowers_testimonial_home_page"/>
            <img className={
                isDisplayed
                    ? `${styles["flower-right"]} ${styles["flower-grow"]}`
                    : `${styles["flower-right"]} ${styles["flower-shrink"]}`
            }
                 src={flowerImageRight} alt="flowers_testimonial_home_page"/>
        </div>
    )
        ;
}

export default MobileNavMenu;
