import {
    fetchMakeUpCoursesFailureAction,
    fetchMakeUpCoursesReqAction, fetchMakeUpCoursesSuccessAction,
    fetchMakeUpServicesFailureAction,
    fetchMakeUpServicesReqAction,
    fetchMakeUpServicesSuccessAction
} from "./reducers";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {FetchMakeUpServicesReqAct} from "./interfaces";
import MakeUpService from "../../../modules/shared/services/MakeUpService";
import {MakeUpType} from "../../../enums/enums";
import {AxiosResponse} from "axios";
import {MakeUp} from "../../../interfaces/MakeUp";
import {of} from "rxjs";

export const fetchMakeUpServicesEpic = (action$: any) => action$.pipe(
    filter(fetchMakeUpServicesReqAction.match),
    switchMap((action: FetchMakeUpServicesReqAct) => {
        const reqRsltLimit: number = action.payload.reqRsltLimit || 10;
        const reqRsltOffset: number = action.payload.reqRsltOffset || 1;
        const makeUpType: MakeUpType = action.payload.makeUpType || MakeUpType.SERVICE;

        return MakeUpService.fetchAllMakeUps(reqRsltOffset, reqRsltLimit, makeUpType).pipe(
            map((res: AxiosResponse<MakeUp[]>) => {
                if (res.status !== 200) {
                    return {
                        type: fetchMakeUpServicesFailureAction.type,
                        payload: {
                            error: "Server error"
                        }
                    }
                }

                return {
                    type: fetchMakeUpServicesSuccessAction.type,
                    payload: {
                        makeUpServices: res.data,
                        error: null
                    }
                }
            }),
            catchError(errorHandler)
        );
    }),
    catchError(errorHandler)
);

export const fetchMakeUpCoursesEpic = (action$: any) => action$.pipe(
    filter(fetchMakeUpCoursesReqAction.match),
    switchMap((action: FetchMakeUpServicesReqAct) => {
        const reqRsltLimit: number = action.payload.reqRsltLimit || 10;
        const reqRsltOffset: number = action.payload.reqRsltOffset || 1;
        const makeUpType: MakeUpType = action.payload.makeUpType || MakeUpType.COURSE;

        return MakeUpService.fetchAllMakeUps(reqRsltOffset, reqRsltLimit, makeUpType).pipe(
            map((res: AxiosResponse<MakeUp[]>) => {
                if (res.status !== 200) {
                    return {
                        type: fetchMakeUpCoursesFailureAction.type,
                        payload: {
                            error: "Server error"
                        }
                    }
                }

                return {
                    type: fetchMakeUpCoursesSuccessAction.type,
                    payload: {
                        makeUpCourses: res.data,
                    }
                }
            }),
            catchError(errorHandler)
        )
    }),
    catchError(errorHandler)
);

const errorHandler = (err: Error) => {
    return of({
        type: fetchMakeUpServicesFailureAction.type,
        payload: {
            error: err.message
        }
    });
}