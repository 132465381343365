import {CreateReviewData, Review} from "../../../@types/types";
import {Observable} from "rxjs";
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from "axios";
import {CONTENT_TYPE, CONTENT_TYPE_JSON} from "../../auth/constants/auth-constants";
import {_Axios} from "../../../services-config/axios/axios-conf";
import app from "../../../App";

const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const baseUrl = `${apiHost}/api/review`;
const approveEndPoint = `${baseUrl}/approve`;

const ReviewService = {
    createReview: (data: CreateReviewData): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.post(`${baseUrl}`, JSON.stringify(data), requestOptions);
    },

    fetchAllReviews: (approved: boolean, page: number, limit: number): Observable<AxiosResponse<Review[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            params: {
                approved: approved,
                page: page,
                limit: limit
            }
        };

        if (!approved) {
            return _Axios.AuthInstance.get(baseUrl, requestOptions);
        }

        return _Axios.DefInstance.get(baseUrl, requestOptions);
    },

    approveReview: (id: string): Observable<AxiosResponse<boolean>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            params: {
                review_id: id
            }
        };

        return _Axios.AuthInstance.post(approveEndPoint, null, requestOptions);
    },

    deleteReview: (id: string): Observable<AxiosResponse<boolean>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            params: {
                review_id: id
            }
        };

        return _Axios.AuthInstance.delete(baseUrl, requestOptions);
    }
}

export default ReviewService;

Object.freeze(ReviewService);
