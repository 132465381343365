import {createContext, useContext} from 'react';

export enum Lang {
    bg = 'bg',
    en = 'en',
}

export type LangContextType = {
    lang: Lang;
    setLang: (lang: Lang) => void;
}

export const LangContext = createContext<LangContextType>({
    lang: Lang.bg, setLang: lang => console.warn('no theme provider')
});

export const useLang = () => useContext(LangContext);