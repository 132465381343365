import React, {FC} from 'react';
import styles from './HomeAboutMeMobile.module.css';
import blagaAboutMe from '../../../../images/blaga-about-me-mobile.png';
import "swiper/css";
import "swiper/css/effect-cards";
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import flower from "../../../../images/group-1-1@2x.svg"
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import useHomePageLocale from "../../locales/HomePageLocales";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface homeAboutMeMobileProps {
}

const HomeAboutMeMobile: FC<homeAboutMeMobileProps> = () => {
    const locales = useHomePageLocale();


    return (
        <div id="about-me" className={styles.container} data-testid="homeAboutMeMobile">
            <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__zoomIn"
                duration={1.2}
            >
                <div className={styles["info-wrapper"]}>
                    <SectionHeading title={locales.ABOUT_ME_TITLE} sentence={locales.ABOUT_ME_SHORT_SENTENCE}
                                    flowerSrc={flower}/>
                </div>
                <img id={styles.img} src={blagaAboutMe} alt={blagaAboutMe}/>
                <div className={styles["show-more-wrapper"]}>
                    <ShowMoreBtn location={"/about-me"} label={locales.ABOUT_ME_BTN_LABEL}/>
                </div>
            </AnimationOnScroll>
        </div>

    );
}

export default HomeAboutMeMobile;
