import React, { FC, useState } from 'react';
import styles from './ServiceInfoSection.module.css';
import SectionHeading from '../../../shared/components/SectionHeading/SectionHeading';
import flower from '../../../../images/group-1-1@2x.svg';
import ZoomImage from '../../../shared/components/ZoomImage/ZoomImage';
import line from '../../../../images/line-11-1@2x.png';
import removalFirstStepIcon from '../../../../images/icon-01@2x.png';
import removalSecondStepIcon from '../../../../images/icon-02@2x.png';
import removalThirdStepIcon from '../../../../images/icon-03@2x.png';
import { useTranslation } from 'react-i18next';
import { MakeUpFullData } from '../../../../interfaces/MakeUp';
import { Lang, useLang } from '../../../../store/context/lang-context';
import UpdateImagesModal, { AddImagesCtx } from '../UpdateImagesModal/UpdateImagesModal';
import { MakeUpType } from '../../../../enums/enums';

interface ServiceInfoSectionProps {
  makeUp: MakeUpFullData | null;
  isUsrAdmin: boolean | null | undefined;
}

const ServiceInfoSection: FC<ServiceInfoSectionProps> = (props) => {
  const [isChangeImageModalOpen, setIsChangeImageModalOpen] = useState(false);

  const { lang } = useLang();
  const { t } = useTranslation();
  const removalTitle = t('SERVICES_REMOVAL_ADVISE', { title: lang === Lang.bg ? props.makeUp?.titleBG : props.makeUp?.titleEN });

  return (
    <React.Fragment>
      {props.isUsrAdmin && (
        <UpdateImagesModal
          context={AddImagesCtx.MAKE_UP_MAIN_IMAGE}
          isModalOpen={isChangeImageModalOpen}
          onModalClose={() => setIsChangeImageModalOpen(false)}
          makeUpId={props.makeUp?.id}
          makeUpType={props.makeUp?.type}
        />
      )}

      <div className={styles['title-wrapper']}>
        <SectionHeading
          title={lang === Lang.bg ? props.makeUp?.titleBG : props.makeUp?.titleEN}
          sentence={undefined}
          flowerSrc={flower}
        />
      </div>

      {props.isUsrAdmin && (
        <button
          className={`${styles['change-image-btn']} montserrat-normal-outer-space-17px`}
          onClick={() => setIsChangeImageModalOpen(true)}
        >
          Смени главната снимка
        </button>
      )}

      <h3 className={`${styles['short-desc']} monotypecorsiva-regular-normal-avocado-25px`}>
        {lang === Lang.bg ? props.makeUp?.shortDescriptionBG : props.makeUp?.shortDescriptionEN}
      </h3>
      <p className={`${styles.description} montserrat-normal-outer-space-17px`}>
        {lang === Lang.bg ? props.makeUp?.descriptionBG : props.makeUp?.descriptionEN}
      </p>

      <section className={styles['steps-section']}>
        <ZoomImage src={props.makeUp?.mainImage.url || ''} />

        <div className={styles['steps-wrapper']}>
          <div className={`${styles['step-numbers']} monotypecorsiva-regular-normal-avocado-80px`}>
            <p>01</p>
            <img className={styles.line} src={line} alt={'line'} />
            <p>02</p>
            <img className={styles.line} src={line} alt={'line'} />
            <p>03</p>
          </div>

          <div className={styles.steps}>
            <div className={styles['first-step']}>
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.firstStepSentenceBG : props.makeUp?.firstStepSentenceEN}
              </p>
              <hr />
            </div>

            <div className={styles['second-step']}>
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.secondStepSentenceBG : props.makeUp?.secondStepSentenceEN}
              </p>
              <hr />
            </div>

            <div className={styles['third-step']}>
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.thirdStepSentenceBG : props.makeUp?.thirdStepSentenceEN}
              </p>
              <hr />
            </div>
          </div>
        </div>
      </section>

      {props.makeUp?.type === MakeUpType.SERVICE && (
        <React.Fragment>
          <h3 className={`${styles['short-desc']} monotypecorsiva-regular-normal-avocado-25px`}>{removalTitle}</h3>
          <p className={`${styles.description} montserrat-normal-outer-space-17px`}>
            {lang === Lang.bg ? props.makeUp?.removalAdviceBG : props.makeUp?.removalAdviceEN}
          </p>

          <section className={`${styles['steps-section']} ${styles['removal-steps-section']}`}>
            <div className={styles['removal-first-step']}>
              <img style={{ width: '35px' }} className={styles['removal-icon']} src={removalFirstStepIcon} alt={'removal'} />
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.removalFirstStepBG : props.makeUp?.removalFirstStepEN}
              </p>
            </div>
            <div className={styles['removal-second-step']}>
              <img className={styles['removal-icon']} src={removalSecondStepIcon} alt={'removal'} />
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.removalSecondStepBG : props.makeUp?.removalSecondStepEN}
              </p>
            </div>

            <div className={styles['removal-third-step']}>
              <img className={styles['removal-icon']} src={removalThirdStepIcon} alt={'removal'} />
              <p className="montserrat-normal-outer-space-17px">
                {lang === Lang.bg ? props.makeUp?.removalThirdStepBG : props.makeUp?.removalThirdStepEN}
              </p>
            </div>
          </section>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ServiceInfoSection;
