import React, {FC, useState} from 'react';
import styles from './ServiceBox.module.css';
import arrow from '../../../../images/expand-right-3@2x.png';
import { useLocation, useNavigate} from 'react-router-dom';
import {Lang, useLang} from '../../../../store/context/lang-context';
import {MakeUp} from '../../../../interfaces/MakeUp';
import {formatNameUrlParam} from '../../../../utils/utils';
import {MakeUpType} from '../../../../enums/enums';
import MiniLoader from "../Loader/MiniLoader";
import {t} from "i18next";

interface ServiceBoxProps {
    makeUp: MakeUp;
    isAdmin?: boolean;
}

const ServiceBox: FC<ServiceBoxProps> = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const {lang} = useLang();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div
            className={styles['box-wrapper']}
            data-testid="ServiceBox"
            onClick={() => {

                const route: string = `/${lang}/${props.makeUp.type === MakeUpType.SERVICE ? 'services' : 'courses'}/${props.makeUp.id}/${lang === Lang.bg ? formatNameUrlParam(props.makeUp.titleBG) : formatNameUrlParam(props.makeUp.titleEN)}`
                navigate(route, {state: {from: location}})
            }}
        >
            {!isLoaded && <div className={styles['mini-loader']}><MiniLoader/></div>}
            <img className={styles['main-image']}
                 style={{display: isLoaded ? 'block' : 'none'}}
                 src={props.makeUp.mainImageUrl}
                 alt={'make-up'}
                 onLoad={() => setIsLoaded(true)}
            />

            <div className={styles['info-wrapper']}>
                <h3 className={'arnopro-caption-normal-black-25px'}>{lang === Lang.bg ? props.makeUp.titleBG : props.makeUp.titleEN}</h3>
                <div className={styles['show-more-wrapper']}>
                    <p className={'montserrat-normal-mine-shaft-17px'}>{t('SHOW_MORE')}</p>
                    <img className={styles.arrow} src={arrow} alt={'arrow'}/>
                </div>
            </div>
        </div>
    );
};

export default ServiceBox;
