import React, {FC, useEffect, useState} from 'react';
import styles from './ImageList.module.css';
import {Image} from "../../../../interfaces/Image";
import closeIcon from "../../../../images/cancel.svg";
import leftArrow from "../../../../images/left-gallery-arrow.png";
import rightArrow from "../../../../images/right-gallery-arrow.png";
import ReactModal from "react-modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCreative, Navigation, Pagination} from "swiper";
import ZoomImage from "../../../shared/components/ZoomImage/ZoomImage";
import saveIcon from '../../../../images/save.png';
import ConfirmModal from "../../../shared/components/ConfirmModal/ConfirmModal";
import {toast} from "react-toastify";
import GalleryService from "../../services/galleryService";
import {of, Subject, tap} from "rxjs";
import useGalleryImages from "../../../../store/redux/gallery-images/useGalleryImages";
import {catchError} from "rxjs/operators";
import {toastObj} from "../../../../utils/utils";
import {AuthData} from "../../../auth/interfaces/AuthData";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/redux/store-config/globalStoreConfig";
import ImageWithLoader from "./ImageWIthLoader";

interface ImageListProps {
    images: Image[];
    galleryId?: string;
    isUserAdmin: boolean | undefined;
}

const ImageList: FC<ImageListProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [initialSlide, setInitialSlide] = useState<number>(0);
    const [swiper, setSwiper] = useState<any>(null);
    const [imageIdsForRemoval, setImageIdsForRemoval] = useState<string[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);

    const {clearDeletedGalleryImages, updateImageLikes} = useGalleryImages();

    const authData: AuthData | null = useSelector((state: RootState) => state.persistedAuthData?.xxfh?.authData ?? null);
    const destroy$ = new Subject<void>();

    useEffect(() => {
        if (authData) {
            setIsUserAuthenticated(true);
        }
    }, [authData]);

    useEffect(() => {
        return () => {
            destroy$.next();
            destroy$.complete();
        }
    }, []);

    useEffect(() => {
        setImages(props.images);
    }, [props.images]);

    function imageClickHandler(event: React.MouseEvent, image: Image, index: number) {
        setInitialSlide(index);
        setIsModalOpen(true);
    }

    const addImageForRemoval = (id: string) => {
        setImageIdsForRemoval([...imageIdsForRemoval, id]);
        setImages(images.filter((img: Image) => img.id !== id));
    }

    const deleteSelectedImages = () => {
        if (!props.galleryId) return;

        const {galleryId} = props;

        GalleryService.removeImages(galleryId, imageIdsForRemoval).pipe(
            tap((res) => {
                if (res.status !== 200 || res.data as number !== imageIdsForRemoval.length) {
                    throw new Error("Възникна грешка при премахването на снимките!");
                }

                clearDeletedGalleryImages(galleryId, imageIdsForRemoval);
                setImageIdsForRemoval([]);
                setIsConfirmModalOpen(false);
                toast.success("Снимките бяха премахнати успешно!", toastObj);
            }),
            catchError((err) => {
                toast.error(err.message);
                return of(err);
            })
        ).subscribe();
    }

    const likeImage = (imageId: string) => {
        if (!props.galleryId) return;

        const {galleryId} = props;

        GalleryService.likeImage(imageId).pipe(
            tap((res) => {
                if (res.status !== 200) {
                    throw new Error("Възникна грешка при харесването на снимката!");
                }

                const likesCount:number = images.find((img: Image) => img.id === imageId)?.likesCount as number;
                const hasCrrUserLiked: boolean = res.data > likesCount;

                updateImageLikes(galleryId, imageId, res.data, hasCrrUserLiked);
            }),
            catchError((err) => {
                toast.error(err.message);
                return of(err);
            })
        ).subscribe();
    }

    return (
        <React.Fragment>

            <div className={styles.ImageList} data-testid="ImageList">
                <ReactModal
                    className={styles.overlay}
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    shouldCloseOnOverlayClick={false}
                    closeTimeoutMS={300}
                >
                    <img
                        onClick={() => setIsModalOpen(false)}
                        className={styles["close-icon"]}
                        src={closeIcon}
                        alt={"close"}/>

                    <img
                        onClick={() => swiper.slidePrev()}
                        src={leftArrow}
                        alt={"left"}
                        className={styles["left-arrow"]}/>
                    <Swiper
                        onSwiper={(swiper) => setSwiper(swiper)}
                        watchSlidesProgress={false}
                        spaceBetween={30}
                        initialSlide={initialSlide}
                        creativeEffect={{
                            prev: {
                                shadow: false,
                                translate: [0, 0, -400],
                            },
                            next: {
                                translate: ["100%", 0, 0],
                            },
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation, Pagination, EffectCreative]}
                        className={styles["swiper-container"]}
                    >
                        {
                            props.images.map((image, index) => {
                                return <SwiperSlide key={image.id + index} className={styles["swiper-slide"]}>
                                    <ZoomImage src={image.url}/>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                    <img
                        onClick={() => swiper.slideNext()}
                        src={rightArrow}
                        alt={"right"}
                        className={styles["right-arrow"]}/>

                </ReactModal>
                {
                    images.map((image, index) => {
                        return <ImageWithLoader
                            key={image.id}
                            image={image}
                            onClick={(event) => imageClickHandler(event, image, index)}
                            isUserAuthenticated={isUserAuthenticated}
                            likeImage={likeImage}
                            isUserAdmin={props.isUserAdmin}
                            onDelete={addImageForRemoval}
                            styles={styles}
                        />
                    })
                }
            </div>

            {
                props.isUserAdmin && imageIdsForRemoval.length > 0 &&
                <React.Fragment>
                    <img
                        className={styles["save-icon"]}
                        src={saveIcon}
                        alt={"save"}
                        onClick={() => setIsConfirmModalOpen(true)}
                    />

                    <ConfirmModal
                        isOpen={isConfirmModalOpen}
                        onConfirm={deleteSelectedImages}
                        onModalClose={() => setIsConfirmModalOpen(false)}
                        confirmMessage={`Сигурни ли сте, че искате да премахнете ${imageIdsForRemoval.length} снимки от тази галиерия?`}
                    />
                </React.Fragment>
            }

        </React.Fragment>
    );
}

export default ImageList;
