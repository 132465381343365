import {Observable} from "rxjs";
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from "axios";
import {ACCEPT_JSON} from "../../auth/constants/auth-constants";
import {_Axios} from "../../../services-config/axios/axios-conf";
import {Image} from "../../../interfaces/Image";
import {GetAllImagesReq} from "../../../@types/types";

const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const baseUrl = `${apiHost}/api/media`;
const uploadImageEndPoint: string = `${baseUrl}/upload/single-image`;
const uploadMultipleImagesEndPoint: string = `${baseUrl}/upload/multiple-images`;

const ImageService = {
    uploadImage: (file: File): Observable<AxiosResponse<string>> => {
        const formData = new FormData();
        formData.append("file", file, file.name);

        const headers: AxiosRequestHeaders = {
            accept: ACCEPT_JSON,
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.post(uploadImageEndPoint, formData, requestOptions)
    },

    uploadMultipleImages: (files: File[]): Observable<AxiosResponse<string[]>> => {
        const formData = new FormData();

        files.forEach(file => {
            formData.append("files", file, file.name);
        })

        const headers: AxiosRequestHeaders = {
            accept: ACCEPT_JSON,
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.post(uploadMultipleImagesEndPoint, formData, requestOptions)
    },

    getAllImages: (reqData: GetAllImagesReq): Observable<AxiosResponse<Image[]>> => {
        const headers: AxiosRequestHeaders = {
            accept: ACCEPT_JSON,
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
        }

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            params: {
                page: reqData.page,
                size: reqData.size,
            }
        };

        return _Axios.AuthInstance.post(baseUrl, JSON.stringify(reqData.imageUrlsToExclude), requestOptions);
    }
}

export default ImageService

Object.freeze(ImageService);