import {useTranslation} from "react-i18next";

export default function useServiceDetailsLocale() {
    const {t} = useTranslation();
    const APPOINTMENT_TITLE = t('SERVICES_APPOINTMENT_TITLE');
    const APPOINTMENT_DESC = t('SERVICES_APPOINTMENT_DESC');
    const APPOINTMENT_BTN_LABEL = t('SERVICES_APPOINTMENT_BTN_LABEL');
    const SELECTED_IMG_TITLE = t('SERVICES_SELECTED_IMG_TITLE');
    const SELECTED_IMG_SENTENCE = t('SERVICES_SELECTED_IMG_SENTENCE');
    const SELECTED_REL_GALLERIES = t('SERVICES_SELECTED_REL_GALLERIES');
    const SELECTED_REL_GAL_DESC = t('SERVICES_SELECTED_REL_GAL_DESC');
    const PRICE_TITLE = t('SERVICES_PRICE_TITLE');
    const PRICE_INFO = t('SERVICES_PRICE_INFO');
    const MORE_INFO_SENTENCE = t('SERVICES_MORE_INFO_SENTENCE');
    const MORE_INFO_SEC_SENTENCE = t('SERVICES_MORE_INFO_SEC_SENTENCE');
    const APPOINTMENT_ACTION = t('APPOINTMENT_ACTION');

    return {
        APPOINTMENT_TITLE,
        APPOINTMENT_DESC,
        APPOINTMENT_BTN_LABEL,
        SELECTED_IMG_TITLE,
        SELECTED_IMG_SENTENCE,
        SELECTED_REL_GALLERIES,
        SELECTED_REL_GAL_DESC,
        PRICE_TITLE,
        PRICE_INFO,
        MORE_INFO_SENTENCE,
        MORE_INFO_SEC_SENTENCE,
        APPOINTMENT_ACTION
    }
}