import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { FetchUserAct, LoginAct, LogoutAct } from './interfaces';
import { globalStore } from '../store-config/globalStoreConfig';
import { AuthService } from '../../../modules/auth/services/authService';
import { Observable, of } from 'rxjs';
import { AxiosResponse } from 'axios';
import { User } from '../../../modules/auth/interfaces/User';
import { TokenContext } from './enums';
import {
  fetchUserFailureAction,
  fetchUserReqAction,
  fetchUserSuccessAction,
  loginReqAction,
  loginSuccessAction,
  logoutReqAction,
  logoutSuccessAction,
} from './authReducers';
import { AuthProvider } from '../../../enums/enums';
import { GoogleLogoutReqBody, KeycloakLogoutReqBody } from '../../../modules/auth/interfaces/LogoutResBody';
import Cookies from 'js-cookie';
import { cookieUserEtag } from '../../../cookies/constants';
export const loginEpic = (action$: any) =>
  action$.pipe(
    filter(loginReqAction.match),
    switchMap((action: LoginAct) => {
      return of(
        globalStore.dispatch(
          loginSuccessAction({
            authProvider: action.payload.authProvider,
            authData: action.payload.authData,
            tokenCtx: action.payload.tokenCtx,
            error: null,
          })
        )
      );
    }),
    map((action: LoginAct) => {
      if (action.payload.tokenCtx === TokenContext.REFRESH) {
        return {};
      }

      return {
        type: fetchUserReqAction.type,
        payload: {
          authProvider: action.payload.authProvider,
          user: null,
          error: null,
        },
      };
    })
  );

export const fetchUserEpic = (action$: any) =>
  action$.pipe(
    filter(fetchUserReqAction.match),
    switchMap((action: FetchUserAct) => {
      return AuthService.fetchUser(action.payload.authProvider).pipe(
        map((data: AxiosResponse<User> | null) => {
          if (!data) {
            return {
              type: fetchUserFailureAction.type,
              payload: {
                authProvider: null,
                user: null,
                error: 'User not found',
              },
            };
          }

          Cookies.set(cookieUserEtag, data.headers.etag, {
            sameSite: 'Strict',
            secure: false,
          });

          return {
            type: fetchUserSuccessAction.type,
            payload: {
              authProvider: action.payload.authProvider,
              user: data.data,
              error: null,
            },
          };
        }),
        catchError((error: Error) => {
          return of({
            type: fetchUserFailureAction.type,
            payload: {
              authProvider: null,
              user: null,
              error: error.message,
            },
          });
        })
      );
    })
  );

export const logoutEpic = (action$: any) =>
  action$.pipe(
    filter(logoutReqAction.match),
    switchMap((action: LogoutAct) => {
      deleteCookie('remember_me');

      if (action.payload.authProvider === AuthProvider.KEYCLOAK) {
        return keycloakLogout(action.payload.keycloakReqBody!);
      } else {
        return googleLogout(action.payload.googleReqBody!);
      }
    })
  );

const keycloakLogout = (reqBody: KeycloakLogoutReqBody): Observable<any> => {
  return AuthService.logout(reqBody).pipe(
    map((data) => {
      return {
        type: logoutSuccessAction.type,
        payload: {},
      };
    }),
    catchError((error: Error) => {
      return of({
        type: logoutSuccessAction.type,
        payload: {
          error: error.message,
        },
      });
    })
  );
};

const googleLogout = (reqBody: GoogleLogoutReqBody): Observable<any> => {
  return AuthService.googleLogout(reqBody).pipe(
    map((data) => {
      return {
        type: logoutSuccessAction.type,
        payload: {},
      };
    }),
    catchError((error: Error) => {
      return of({
        type: logoutSuccessAction.type,
        payload: {
          error: error.message,
        },
      });
    })
  );
};

const deleteCookie = (name: string, path = '/', sameSite = 'strict') => {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  const secure = window.location.protocol === 'https:' ? '; secure' : '';
  document.cookie = `${name}=;${secure} ${expires}; path=${path}; sameSite=${sameSite}`;
};