import React, {FC} from 'react';
import styles from './PrivacyPolicy.module.css';

interface PrivacyPolicyProps {
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => (
    <div className={styles.PrivacyPolicy} data-testid="PrivacyPolicy">
        <h1>Политика за поверителност на MakeUp by Blaga Dimitrova</h1>
        <p>В MakeUp by Blaga Dimitrova, достъпен от https://blagadimitrova.bg, един от нашите основни приоритети е
            поверителността на нашите посетители. Този документ за Политика за поверителност съдържа типове информация,
            която се събира и записва от MakeUp by Blaga Dimitrova и как я използваме.</p>
        <p>Ако имате допълнителни въпроси или се нуждаете от повече информация за нашата Политика за поверителност, не
            се колебайте да се свържете с нас.</p>
        <p>Тази Политика за поверителност се отнася само за нашите онлайн дейности и е валидна за посетители на нашия
            уебсайт, които споделят и/или събират информация в MakeUp by Blaga Dimitrova. Тази политика не се отнася за
            информация, събрана офлайн или чрез канали, различни от този уебсайт.</p>
        <h2>Съгласие</h2>
        <p>Чрез използването на нашия уебсайт вие давате съгласието си за нашата Политика за поверителност и се
            съгласявате с нейните условия.</p>
        <h2>Информация, която събираме</h2>
        <p>Личната информация, която ви се иска да предоставите, и причините, поради които ви се иска да я предоставите,
            ще ви бъдат ясно обяснени в момента, в който ви помолим да предоставите личната си информация.</p>
        <p>Ако се свържете директно с нас, можем да получим допълнителна информация за вас, като вашето име, имейл
            адрес, телефонен номер, съдържанието на съобщението и/или прикачените файлове, които може да ни изпратите, и
            всяка друга информация, която може да изберете да предоставите.</p>
        <p>Когато се регистрирате за профил, можем да ви помолим за контактната ви информация, включително елементи като
            име, име на фирма, адрес, имейл адрес и телефонен номер.</p>
        <h2>Как използваме вашата информация</h2>
        <p>Използваме информацията, която събираме по различни начини, включително:</p>
        <ul>
            <li>Предоставяне, експлоатация и поддържане на нашия уебсайт;</li>
            <li>Подобряване, персонализиране и разширяване на нашия уебсайт;</li>
            <li>Разбиране и анализиране как използвате нашия уебсайт;</li>
            <li>Разработване на нови продукти, услуги, функции и функционалности;</li>
            <li>Комуникация с вас, директно или чрез един от нашите партньори, включително за обслужване на клиенти, за
                предоставяне на актуализации и друга информация, свързана с уебсайта, и за маркетингови и промоционални
                цели;
            </li>
            <li>Изпращане на имейли;</li>
            <li>Намиране и предотвратяване на измами.</li>
        </ul>
        <h2>Файлове за регистрация</h2>
        <p>MakeUp by Blaga Dimitrova следва стандартна процедура за използване на файлове за регистрация.
            Тези файлове регистрират посетителите, когато те посещават уебсайтове.
            Всички хостинг компании правят това и това е част от услугите за хостинг и аналитика.
            Информацията, събрана от файловете за регистрация, включва интернет протокол (IP) адреси, типа на браузъра,
            Интернет доставчика на услуги (ISP), времеви маркери, страниците за препращане/изход, и възможно броя на
            кликовете.
            Те не са свързани с никаква информация, която е лично идентифицируема. Целта на информацията е за
            анализиране на тенденциите,
            администриране на сайта, следене на движението на потребителите на уебсайта и събиране на демографска
            информация.</p>

        <h2>Бисквитки и уеб маяци</h2>
        <p>Както всеки друг уебсайт, MakeUp by Blaga Dimitrova използва "бисквитки". Тези бисквитки се използват за
            съхраняване на информация, включително предпочитанията на посетителите и страниците на уебсайта, които
            посетителят е достъпил или посетил. Информацията се използва за оптимизиране на потребителското изживяване,
            като персонализираме съдържанието на нашата уеб страница въз основа на типа на браузъра на посетителите
            и/или друга информация.</p>
        <p>Също така използваме услуги като Google Analytics, Google Tag Manager и Google OAuth, които могат да събират
            някои от тези данни за анализ и подобряване на нашите услуги.</p>
        <h2>Политики за поверителност на рекламните партньори</h2>
        <p>Можете да се консултирате с този списък, за да намерите Политиката за поверителност за всеки от рекламните
            партньори на MakeUp by Blaga Dimitrova.</p>
        <p>Сървърите за реклами от трети страни или мрежите за реклами използват технологии като бисквитки, JavaScript
            или Уеб маяци, които се използват в съответните им реклами и връзки, които се появяват на MakeUp by Blaga
            Dimitrova, и се изпращат директно до браузъра на потребителите. Те автоматично получават вашия IP адрес,
            когато това се случи. Тези технологии се използват за измерване на ефективността на техните рекламни
            кампании и/или за персонализиране на рекламното съдържание, което виждате на уебсайтовете, които
            посещавате.</p>
        <p>Имайте предвид, че MakeUp by Blaga Dimitrova няма достъп до или контрол върху тези бисквитки, които се
            използват от рекламодатели от трети страни.</p>
        <h2>Политики за поверителност на трети страни</h2>
        <p>Политиката за поверителност на MakeUp by Blaga Dimitrova не се прилага за други рекламодатели или уебсайтове.
            Затова ви съветваме да се консултирате със съответните политики за поверителност на тези сървъри за реклами
            от трети страни за по-подробна информация. Тя може да включва техните практики и инструкции за това как да
            се откажете от някои опции.</p>

        <p>Можете да изберете да деактивирате бисквитките чрез вашите индивидуални настройки на браузъра. За да получите
            по-подробна информация за управлението на бисквитките с конкретни уеб браузъри, тя може да бъде намерена на
            съответните уебсайтове на браузърите.</p>

        <h2>Права за защита на данните по GDPR</h2>
        <p>Бихме искали да се уверим, че сте напълно информирани относно всички ваши права за защита на данните. Всеки
            потребител има право на следното:</p>
        <p>Право на достъп – Имате право да поискате копия на вашите лични данни. За тази услуга може да ви бъде
            таксувана малка сума.</p>
        <p>Право на корекция – Имате право да поискате от нас да коригираме информация, която считате за неточна. Също
            така имате право да поискате от нас да допълним информация, която считате за непълна.</p>
        <p>Право на изтриване – Имате право да поискате от нас да изтрием вашите лични данни, при определени
            условия.</p>
        <p>Право на ограничаване на обработката – Имате право да поискате от нас да ограничим обработката на вашите
            лични данни, при определени условия.</p>
        <p>Право на възражение срещу обработка – Имате право да възразите срещу нашата обработка на вашите лични данни,
            при определени условия.</p>
        <p>Право на преносимост на данни – Имате право да поискате от нас да прехвърлим данните, които сме събрали, към
            друга организация или директно до вас, при определени условия.</p>
        <p>Ако направите искане, ние имаме един месец да отговорим на вас. Ако искате да упражните някои от тези права,
            моля свържете се с нас.</p>

        <h2>Информация за деца</h2>
        <p>Друга част от приоритетите ни е добавянето на защита за деца, докато използват интернет. Ние насърчаваме
            родителите и законните представители да наблюдават, участват в и/или да наблюдават и да насочват онлайн
            дейността на своите деца.</p>
        <p>MakeUp by Blaga Dimitrova не събира намерено лични данни от деца под 14 години. Ако мислите, че вашето дете е
            предоставило такъв тип информация на нашия уебсайт, ние силно ви препоръчваме да се свържете с нас веднага и
            ние ще положим всички усилия да премахнем бързо такава информация от нашите записи.</p>

        <h2>Промени в тази политика за поверителност</h2>
        <p>Може да актуализираме нашата политика за поверителност от време на време. Затова ви съветваме периодично да
            преглеждате тази страница за всички промени. Ще ви уведомим за всички промени, като публикуваме новата
            политика за поверителност на тази страница. Тези промени влизат в сила веднага след като са публикувани на
            тази страница.</p>

        <h2>Свържете се с нас</h2>
        <p>Телефон: 0888733004</p>
        <p>Мейл: service@blagadimitrova.eu</p>

        <p>Ако имате въпроси или предложения относно нашата Политика за поверителност, не се колебайте да се свържете с
            нас.</p>
    </div>
);

export default PrivacyPolicy;
