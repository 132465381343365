import React, { FC, useEffect } from 'react';
import styles from './HomeReview.module.css';
import grid from '../../../../images/line-about-me@1x.svg';
import makeUpImg from '../../../../images/picture-testimonial@1x.jpg';
import flower from '../../../../images/group-1-5@2x.png';
import SectionHeading from '../../../shared/components/SectionHeading/SectionHeading';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import ReviewMessageBox from '../ReviewMessageBox/ReviewMessageBox';
import ShowMoreBtn from '../../../shared/components/ShowMoreBtn/ShowMoreBtn';
import useHomePageLocale from '../../locales/HomePageLocales';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Review } from '../../../../@types/types';
import { useReviewsActions } from '../../../../store/redux/reviews/useReviews';
import { ReviewsState } from '../../../../store/redux/reviews/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/redux/store-config/globalStoreConfig';

interface HomeReviewProps {}

const HomeReview: FC<HomeReviewProps> = () => {
  const [reviews, setReviews] = React.useState<Review[]>([]);

  const { fetchApprovedReviews } = useReviewsActions();
  const locales = useHomePageLocale();

  const reviewsState: ReviewsState = useSelector((state: RootState) => state.reviews);

  useEffect(() => {
    if (!reviewsState.approvedReviews && !reviewsState.loading && !reviewsState.error) {
      fetchApprovedReviews(10, 1);
    }
  }, [fetchApprovedReviews, reviewsState.loading]);

  useEffect(() => {
    setReviews(reviewsState.approvedReviews || []);
  }, [reviewsState.approvedReviews]);

  return (
    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceInRight" duration={1.2}>
      <section id={'reviews'} className={styles.HomeReview} data-testid="HomeReview">
        <div className={styles['info-wrapper']}>
          <SectionHeading title={locales.REVIEW_TITLE} sentence={undefined} flowerSrc={flower} />
        </div>
        <img src={grid} alt={grid} className={styles.grid} />
        <img src={makeUpImg} alt={grid} className={styles['make-up-image']} />
        <div className={styles['leave-review-wrapper']}>
          <ShowMoreBtn location={'/review'} label={locales.LEAVE_REVIEW_BTN_LABEL} />
        </div>
        <Swiper
          style={{
            position: 'absolute',
            zIndex: 11111,
            width: '100%',
            height: '700px',
            left: '60%',
            bottom: '8%',
            transform: 'translate(-60%, 8%)',
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: true,
          }}
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              translate: ['-125%', 0, -800],
              rotate: [0, 0, -90],
            },
            next: {
              translate: ['125%', 0, -800],
              rotate: [0, 0, 90],
            },
          }}
          modules={[EffectCreative, Autoplay, Pagination]}
          className={styles['review-slider']}
        >
          {reviews.map((review: Review) => (
            <SwiperSlide
              key={review.id}
              style={{
                position: 'relative',
                zIndex: 11111,
                width: '100%',
              }}
            >
              <div className={styles['review-wrapper']}>
                <ReviewMessageBox
                  userFullName={`${review.ownerFirstname} ${review.ownerLastname}`}
                  reviewMessage={review.content}
                  userImg={review.ownerProfileImgUrl}
                  rating={review.rating}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </AnimationOnScroll>
  );
};

export default HomeReview;
