import React, {FC, useEffect} from 'react';
import styles from './HomeReviewMobile.module.css';
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import flower from "../../../../images/group-1-1@2x.svg"
import ReviewMessageBox from "../ReviewMessageBox/ReviewMessageBox";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";

import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCreative, Autoplay, Pagination} from "swiper";
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import useHomePageLocale from "../../locales/HomePageLocales";
import {AnimationOnScroll} from "react-animation-on-scroll";
import {Review} from "../../../../@types/types";
import {useReviewsActions} from "../../../../store/redux/reviews/useReviews";
import {ReviewsState} from "../../../../store/redux/reviews/interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/redux/store-config/globalStoreConfig";

interface HomeReviewMobileProps {
}

const HomeReviewMobile: FC<HomeReviewMobileProps> = () => {
    const [reviews, setReviews] = React.useState<Review[]>([]);

    const {fetchApprovedReviews} = useReviewsActions();
    const locales = useHomePageLocale();

    const reviewsState: ReviewsState = useSelector((state: RootState) => state.reviews);

    useEffect(() => {
        if (!reviewsState.approvedReviews && !reviewsState.loading && !reviewsState.error) {
            fetchApprovedReviews(20, 1);
        }
    }, [fetchApprovedReviews, reviewsState.loading]);

    useEffect(() => {
        setReviews(reviewsState.approvedReviews || []);
    }, [reviewsState.approvedReviews]);

    return (
        <section className={styles.HomeReviewMobile} data-testid="HomeReviewMobile">
            <div className={styles["info-wrapper"]}>
                <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animate__jackInTheBox"
                    duration={1.2}
                >
                    <div className={styles["section-heading-wrapper"]}>
                        <SectionHeading title={locales.REVIEW_TITLE} sentence={undefined}
                                        flowerSrc={flower}/>
                    </div>
                </AnimationOnScroll>

                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 5500,
                        disableOnInteraction: true,
                    }}
                    grabCursor={true}
                    pagination={{
                        clickable: true,
                    }}
                    effect={"creative"}
                    creativeEffect={{
                        prev: {
                            shadow: false,
                            origin: "left center",
                            translate: ["-45%", 0, -100],
                            rotate: [0, 100, 0],
                        },
                        next: {
                            shadow: false,
                            origin: "right center",
                            translate: ["45%", 0, -100],
                            rotate: [0, -100, 0],
                        },
                    }}
                    modules={[EffectCreative, Autoplay, Pagination]}
                    className={styles["swiper-container"]}
                >
                    {reviews.map((review:Review) => (
                        <SwiperSlide
                            key={review.id}
                            className={styles.slide}>
                            <div className={styles["review-wrapper"]}>
                                <div className={styles["review-message-wrapper"]}>
                                    <ReviewMessageBox
                                        userFullName={`${review.ownerFirstname} ${review.ownerLastname}`}
                                        reviewMessage={review.content}
                                        userImg={review.ownerProfileImgUrl}
                                        rating={review.rating}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className={styles["leave-review-wrapper"]}>
                <ShowMoreBtn location={"/review"} label={locales.LEAVE_REVIEW_BTN_LABEL}/>
            </div>
        </section>
    );
}

export default HomeReviewMobile;
