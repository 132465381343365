import {useTranslation} from "react-i18next";

export default function useAboutMePageLocale () {
    const {t} = useTranslation();

    const ABOUT_ME_TITLE = t('ABOUT_ME_TITLE');
    const ABOUT_ME_SENTENCE_1 = t('ABOUT_ME_SENTENCE_1');
    const ABOUT_ME_SENTENCE_2 = t('ABOUT_ME_SENTENCE_2');
    const ABOUT_ME_SENTENCE_3 = t('ABOUT_ME_SENTENCE_3');
    const ABOUT_ME_HEADING_2 = t('ABOUT_ME_HEADING_2');
    const ABOUT_ME_HOW_IT_STARTED_TITLE = t('ABOUT_ME_HOW_IT_STARTED_TITLE');
    const ABOUT_ME_HOW_IT_STARTED_SENTENCE = t('ABOUT_ME_HOW_IT_STARTED_SENTENCE');
    const ABOUT_ME_MY_MISSION_TITLE = t('ABOUT_ME_MY_MISSION_TITLE');
    const ABOUT_ME_MY_MISSION_SENTENCE_1 = t('ABOUT_ME_MY_MISSION_SENTENCE_1');
    const ABOUT_ME_MY_MISSION_SENTENCE_2 = t('ABOUT_ME_MY_MISSION_SENTENCE_2');
    const ABOUT_ME_MY_MISSION_SENTENCE_3 = t('ABOUT_ME_MY_MISSION_SENTENCE_3');
    const ABOUT_ME_MY_MISSION_SENTENCE_4 = t('ABOUT_ME_MY_MISSION_SENTENCE_4');
    const ABOUT_ME_MY_MISSION_BOTTOM_LEFT_SENTENCE = t('ABOUT_ME_MY_MISSION_BOTTOM_LEFT_SENTENCE');
    const ABOUT_ME_MY_MISSION_BOTTOM_RIGHT_SENTENCE = t('ABOUT_ME_MY_MISSION_BOTTOM_RIGHT_SENTENCE');

    return {
        ABOUT_ME_TITLE,
        ABOUT_ME_SENTENCE_1,
        ABOUT_ME_SENTENCE_2,
        ABOUT_ME_SENTENCE_3,
        ABOUT_ME_HEADING_2,
        ABOUT_ME_HOW_IT_STARTED_TITLE,
        ABOUT_ME_HOW_IT_STARTED_SENTENCE,
        ABOUT_ME_MY_MISSION_TITLE,
        ABOUT_ME_MY_MISSION_SENTENCE_1,
        ABOUT_ME_MY_MISSION_SENTENCE_2,
        ABOUT_ME_MY_MISSION_SENTENCE_3,
        ABOUT_ME_MY_MISSION_SENTENCE_4,
        ABOUT_ME_MY_MISSION_BOTTOM_LEFT_SENTENCE,
        ABOUT_ME_MY_MISSION_BOTTOM_RIGHT_SENTENCE
    };
}