import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './Navigation.module.css';
import profileIcon from '../../../images/default-profile-icon.png';
import closeIcon from '../../../images/close-icon@2x.png';
import mobileMenuIcon from '../../../images/group-159@2x.svg';
import MobileNavMenu from '../MobileNavMenu/MobileNavMenu';
import { MobileNavContext, MobileNavDisplayContext } from '../../../store/context/mobile-nav-context';
import logo from '../../../images/logo.png';
import useWindowDimensions from '../../../hooks/screen-resolution-checker';
import { debounce, navigationClickHandler, toastObj } from '../../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import langSelectorIcon from '../../../images/lang-selector.png';
import useNavigationLocale from './NavigationLocale';
import { useCookies } from 'react-cookie';
import { Lang, useLang } from '../../../store/context/lang-context';
import { useSelector } from 'react-redux';
import { GoogleLogoutReqBody, KeycloakLogoutReqBody } from '../../auth/interfaces/LogoutResBody';
import { CLIENT_ID } from '../../auth/constants/auth-constants';
import { RootState } from '../../../store/redux/store-config/globalStoreConfig';
import { toast } from 'react-toastify';
import { AuthProvider } from '../../../enums/enums';
import useAuthActions from '../../../store/redux/auth/useAuthActions';
import { User } from '../../auth/interfaces/User';
import { cookieLang } from '../../../cookies/constants';
import i18n from 'i18next';
import useGalleryImages from '../../../store/redux/gallery-images/useGalleryImages';

interface NavigationProps {}

interface Language {
  bg: string;
  en: string;
}

const styleClasses: string = `${styles.nav} montserrat-normal-mine-shaft-15px`;

const Navigation: FC<NavigationProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies([cookieLang]);
  const [isNavMenuDisplayed, setIsNavMenuDisplayed] = useState<boolean>(true);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [navMenuHeight, setNavMenuHeight] = useState<number>(0);
  const [isUserMenuDisplayed, setIsUserMenuDisplayed] = useState<boolean>(false);
  const [profileIconXPosition, setProfileIconXPosition] = useState<number>(0);
  const [langMenuRightPosition, setLangMenuRightPosition] = useState<number>(-220);
  const [shouldReRender, setShouldReRender] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<string>('');
  const [authProvider, setAuthProvider] = useState<AuthProvider | null>(null);
  const [userImageUrl, setUserImageUrl] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const location = useLocation();
  const navigator = useNavigate();
  const nav = useRef() as React.MutableRefObject<HTMLElement>;
  const profileImg = useRef() as React.MutableRefObject<HTMLImageElement>;
  const locales = useNavigationLocale();

  const { lang, setLang } = useLang();
  const { doKeycloakLogout, doGoogleLogout } = useAuthActions();
  const { clearImageState } = useGalleryImages();

  const authData = useSelector((state: RootState) => state.persistedAuthData);
  const stateErr = useSelector((state: RootState) => state.authData.error);
  const user: User = authData?.xxfh?.user;

  useEffect(() => {
    if (authData.xxfh && authData.xxfh.authData) {
      setIsLoggedIn(true);
      const { refreshToken } = authData.xxfh.authData;
      const { authProvider } = authData.xxfh;
      setRefreshToken(refreshToken);
      setAuthProvider(authProvider);
    } else {
      setIsLoggedIn(false);
    }
  }, [authData, shouldReRender]);

  const languages: Language = {
    bg: 'bg',
    en: 'en',
  };

  useEffect(() => {
    if (stateErr) {
      toast.error(toastObj, stateErr);
    }
  }, [stateErr]);

  useEffect(() => {
    if (user) {
      setUserImageUrl(user.profileImageUrl);
    } else {
      setUserImageUrl('');
    }
  }, [user]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      setLangMenuRightPosition(langMenuRightPosition === -220 ? 0 : -220);
      setCookie(cookieLang, lng, {
        path: '/',
        sameSite: 'strict',
      });
      setLang(lng === 'bg' ? Lang.bg : Lang.en);

      const currentPath = location.pathname;
      const newPath = currentPath.replace(/^\/[a-zA-Z]{2}/, `/${lng}`);
      navigator(newPath);

      //window.location.reload();
    });
  };

  const setDisplayed = () => {
    setMobileViewDisplayed((prevState) => ({
      isDisplayed: !prevState.isDisplayed,
      setDisplayed,
    }));
  };

  const [isMobileViewDisplayed, setMobileViewDisplayed] = useState<MobileNavContext>({
    isDisplayed: false,
    setDisplayed,
  });

  const handleScroll = debounce(
    (): void => {
      const currentScrollPos: number = Math.abs(document.documentElement.scrollTop);
      const isDownScrolling: boolean = prevScrollPos > currentScrollPos;

      if (isMobileViewDisplayed.isDisplayed) {
        isMobileViewDisplayed.setDisplayed();
      }

      if (isUserMenuDisplayed) {
        setIsUserMenuDisplayed(false);
      }

      setIsNavMenuDisplayed(isDownScrolling || currentScrollPos <= 0);
      setPrevScrollPos(currentScrollPos);
    },
    150,
    false
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    const profileImgRects: DOMRect = profileImg.current.getClientRects()[0];
    const profileImgXPosition: number = profileImgRects.x;
    const navRects: DOMRect = nav.current.getClientRects()[0];
    const navMenuPos: number = navRects.top + navRects.height + 10;

    setNavMenuHeight(navMenuPos);
    setProfileIconXPosition(profileImgXPosition - 250);

    setPath(location.pathname);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, isNavMenuDisplayed, handleScroll]);

  const logout = () => {
    if (authProvider === AuthProvider.KEYCLOAK) {
      const logoutData: KeycloakLogoutReqBody = {
        client_id: CLIENT_ID,
        refresh_token: refreshToken,
      };

      doKeycloakLogout(logoutData);
    } else {
      const logoutData: GoogleLogoutReqBody = {
        token: refreshToken,
      };

      doGoogleLogout(logoutData);
    }

    setIsUserMenuDisplayed(false);
    setShouldReRender(true);
    clearImageState();
    navigator(`/${lang}/`);
  };

  return (
    <MobileNavDisplayContext.Provider value={isMobileViewDisplayed}>
      <nav
        ref={nav}
        id="navbar"
        style={{ top: isNavMenuDisplayed ? '0' : '-150px' }}
        className={styleClasses}
        data-testid="Navigation"
      >
        <ul>
          {useWindowDimensions().width > 768 && (
            <React.Fragment>
              <li onClick={() => navigator(`${lang}/`)}>
                <img className={styles.logo} src={logo} alt={'logo'} />
              </li>

              <li
                className={path.includes('services') ? styles['item-active'] : styles.item}
                onClick={() => {
                  navigationClickHandler.servicesClickHandler(location, navigator, lang);
                }}
              >
                {locales.NAV_SERVICES}
              </li>

              <li
                className={path.includes('courses') ? styles['item-active'] : styles.item}
                onClick={() => {
                  navigator(`${lang}/courses`, { state: { from: location } });
                }}
              >
                {locales.NAV_COURSES}
              </li>

              <li
                className={path.includes('gallery') || path.includes('galleries') ? styles['item-active'] : styles.item}
                onClick={() => {
                  navigationClickHandler.myWorkClickHandler(location, navigator, lang);
                }}
              >
                {locales.NAV_GALLERY}
              </li>

              <li
                className={path.includes('about-me') ? styles['item-active'] : styles.item}
                onClick={() => {
                  navigationClickHandler.aboutMeClickHandler(location, navigator, lang);
                }}
              >
                {locales.NAV_ABOUT_ME}
              </li>

              {/*
                               <li className={path.includes("blog") ? styles["item-active"] : styles.item}
                                   onClick={() => {
                                       navigationClickHandler.blogClickHandler(location, navigator, lang);
                                   }}>
                                   {locales.NAV_BLOG}
                               </li>
                                 */}
            </React.Fragment>
          )}
          <img className={styles['mobile-menu-icon']} src={mobileMenuIcon} alt="" onClick={() => setDisplayed()} />

          <li
            className={styles.hour}
            onClick={() => {
              navigator(`/${lang}/appointment`, { state: { from: location } });
            }}
          >
            <p className="montserrat-normal-white-17px">{locales.APPOINTMENT_ACTION}</p>
          </li>

          <img
            style={{
              border: isLoggedIn ? '1px solid #666666' : 'none',
            }}
            ref={profileImg}
            onClick={() => setIsUserMenuDisplayed(!isUserMenuDisplayed)}
            className={styles['profile-icon']}
            src={userImageUrl !== '' ? userImageUrl : profileIcon}
            alt="profile"
          ></img>
        </ul>

        <img
          onClick={() => {
            setLangMenuRightPosition(langMenuRightPosition === -220 ? 0 : -220);
          }}
          src={langSelectorIcon}
          alt={'lang'}
          className={styles.lang}
        />
        <div
          style={{
            top: `${navMenuHeight - 20}px`,
            right: `${langMenuRightPosition}px`,
          }}
          className={styles['lang-menu-wrapper']}
        >
          <div className={styles['lang-inner-wrapper']}>
            <img
              onClick={() => setLangMenuRightPosition(langMenuRightPosition === -220 ? 0 : -220)}
              className={`${styles['close-icon']} ${styles['align-left']}`}
              src={closeIcon}
              alt={'close'}
            />

            <p onClick={() => changeLanguage(languages.bg)} className={'montserrat-normal-outer-space-15px'}>
              {locales.LANG_BG}
            </p>
            <p onClick={() => changeLanguage(languages.en)} className={'montserrat-normal-outer-space-15px'}>
              {locales.LANG_EN}
            </p>
          </div>
        </div>

        <div
          style={{
            top: isUserMenuDisplayed ? `${navMenuHeight - 7}px` : '-150px',
            left: `${profileIconXPosition}px`,
          }}
          className={styles.dropdown}
        >
          <div className={styles['menu-wrapper']}>
            <img onClick={() => setIsUserMenuDisplayed(false)} className={styles['close-icon']} src={closeIcon} alt={'close'} />
            {isLoggedIn ? (
              <React.Fragment>
                <p onClick={logout} className="montserrat-normal-white-17px">
                  {locales.NAV_USER_MENU_LOGOUT}
                </p>
                <p
                  onClick={() => {
                    setIsUserMenuDisplayed(false);
                    navigator(`/${lang}/profile`, { state: { from: location } });
                  }}
                  className="montserrat-normal-white-17px"
                >
                  {locales.NAV_USER_MENU_PROFILE}
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p
                  onClick={() => {
                    setIsUserMenuDisplayed(false);
                    navigator(`${lang}/login`, { state: { from: location } });
                  }}
                  className="montserrat-normal-white-17px"
                >
                  {locales.NAV_USER_MENU_LOGIN}
                </p>
                <p
                  onClick={() => {
                    setIsUserMenuDisplayed(false);
                    navigator(`${lang}/register`, { state: { from: location } });
                  }}
                  className="montserrat-normal-white-17px"
                >
                  {locales.NAV_USER_MENU_REGISTER}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
      {useWindowDimensions().width <= 768 && <MobileNavMenu />}
    </MobileNavDisplayContext.Provider>
  );
};

export default Navigation;
