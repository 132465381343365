import React, { FC } from 'react';
import styles from './ServicePriceSection.module.css';
import ShowMoreBtn from '../../../shared/components/ShowMoreBtn/ShowMoreBtn';
import useServiceDetailsLocale from '../../locales/ServiceDetailsLocale';

interface ServicePriceSectionProps {
  price: number | null | undefined;
}

const ServicePriceSection: FC<ServicePriceSectionProps> = (props) => {
  const locales: any = useServiceDetailsLocale();
  const tel = process.env.REACT_APP_CONTACT_PHONE_NUMBER;
  const email = process.env.REACT_APP_CONTACT_EMAIL;

  return (
    <React.Fragment>
      <section className={styles['price-section']}>
        <div className={'monotypecorsiva-regular-normal-outer-space-17px'}>
          <span className={'monotypecorsiva-regular-normal-outer-space-17px'}>{locales.PRICE_TITLE}&nbsp;</span>
          <span className={'monotypecorsiva-regular-normal-avocado-17px'}>{props.price || 0}+&nbsp;лв.</span>
        </div>
        <p className={'monotypecorsiva-regular-normal-outer-space-17px'}>{locales.PRICE_INFO}</p>
        <div
          id={styles['sentence-container']}
          style={{ maxWidth: '900px' }}
          className={'monotypecorsiva-regular-normal-outer-space-17px'}
        >
          <span className={'monotypecorsiva-regular-normal-outer-space-17px'}>{locales.MORE_INFO_SENTENCE}</span>
          <p id={styles.tel} className={'monotypecorsiva-regular-normal-avocado-17px'}>
            {tel}
          </p>
          <span className={'monotypecorsiva-regular-normal-outer-space-17px'}>{locales.MORE_INFO_SEC_SENTENCE}&nbsp;</span>
          <p id={styles.email} className={'monotypecorsiva-regular-normal-avocado-17px'}>
            {email}
          </p>
        </div>
      </section>
      <ShowMoreBtn location={'/appointment'} label={locales.APPOINTMENT_ACTION} />
    </React.Fragment>
  );
};

export default ServicePriceSection;
