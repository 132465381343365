import {Epic} from "redux-observable";
import {Observable, of, switchMap} from "rxjs";
import {catchError, filter, map} from "rxjs/operators";
import {
    fetchGalleryImagesFailureAction,
    fetchGalleryImagesReqAction,
    fetchGalleryImagesSuccessAction
} from "./reducers";
import {FetchGalleryImagesReqAct} from "./interfaces";
import {AxiosResponse} from "axios";
import {Image} from "../../../interfaces/Image";
import GalleryService from "../../../modules/gallery-page-module/services/galleryService";

export const fetchGalleryImagesEpic: Epic = (action$: Observable<any>) => action$.pipe(
    filter(fetchGalleryImagesReqAction.match),
    switchMap((action: FetchGalleryImagesReqAct) => {

        const {
            authenticated,
            galleryId,
            page,
            limit,
        } = action.payload;

        return GalleryService.getImagesForGallery(galleryId, page, limit, authenticated).pipe(
            map((res: AxiosResponse<Image[]>) => {
                if (res.status !== 200) {
                    return {
                        type: fetchGalleryImagesFailureAction.type,
                        payload: {
                            error: "Server error while fetching gallery images"
                        }
                    }
                }

                return {
                    type: fetchGalleryImagesSuccessAction.type,
                    payload: {
                        galleryId: galleryId,
                        images: res.data,
                        page: page
                    }
                }
            })
        )
    }),
    catchError((err: any) => {
        return of({
            type: fetchGalleryImagesFailureAction.type,
            payload: {
                error: "Server error while fetching gallery images"
            }
        })
    })
);