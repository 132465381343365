import {
    AddGalleryAct,
    FetchGalleriesFailAct,
    FetchGalleriesReqAct,
    FetchGalleriesScssAct,
    GalleriesState, UpdateGalleryAct
} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";

const initialState: GalleriesState = {
    galleries: null,
    loading: false,
    fetched: false,
    error: null,
}

const fetchGalleriesReq: CaseReducer<GalleriesState, FetchGalleriesReqAct> = (state: GalleriesState, action) => {
    state.galleries = state.galleries || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchGalleriesSuccess: CaseReducer<GalleriesState, FetchGalleriesScssAct> = (state: GalleriesState, action) => {
    state.galleries = action.payload?.galleries || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchGalleriesFailure: CaseReducer<GalleriesState, FetchGalleriesFailAct> = (state: GalleriesState, action) => {
    state.galleries = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload?.error || null;
}

const addGallery: CaseReducer<GalleriesState, AddGalleryAct> = (state: GalleriesState, action) => {
    state.galleries?.push(action.payload.gallery);
}

const updateGallery: CaseReducer<GalleriesState, UpdateGalleryAct> = (state: GalleriesState, action) => {
    const gallery = action.payload.gallery;
    const index = state.galleries?.findIndex(g => g.id === gallery.id);

    if (!!index && index >= 0) {
        state.galleries?.splice(index, 1, gallery);
    }
}
export const GalleriesSlice = createSlice({
    name: "galleries",
    initialState,
    reducers: {
        fetchGalleriesReq,
        fetchGalleriesSuccess,
        fetchGalleriesFailure,
        addGallery,
        updateGallery,
    }
});

export const {
    fetchGalleriesReq: fetchGalleriesReqAction,
    fetchGalleriesSuccess: fetchGalleriesSuccessAction,
    fetchGalleriesFailure: fetchGalleriesFailureAction,
    addGallery: addGalleryAction,
    updateGallery: updateGalleryAction,
} = GalleriesSlice.actions;