import React, {FC, useEffect, useState} from 'react';
import styles from './MakeUpSelectedImages.module.css';
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import flower from "../../../../images/group-1-1@2x.svg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import useServiceDetailsLocale from "../../locales/ServiceDetailsLocale";
import {Image} from "../../../../interfaces/Image";
import UpdateImagesModal, {AddImagesCtx} from "../UpdateImagesModal/UpdateImagesModal";
import {ToastContainer} from "react-toastify";

import MakeUpSelectedImagesSlider from "../MakeUpSelectedImagesSlider/MakeUpSelectedImagesSlider";

interface MakeUpSelectedImagesProps {
    selectedImages: Image[] | null | undefined;
    isUsrAdmin: boolean | null | undefined;
    makeUpId?: string;
}

const MakeUpSelectedImages: FC<MakeUpSelectedImagesProps> = (props) => {
    const [images, setImages] = useState<Image[] | null>();
    const [isAddSelectedImagesModalOpen, setIsAddSelectedImagesModalOpen] = useState(false);

    const locales: any = useServiceDetailsLocale();

    useEffect(() => {
        if (!props.selectedImages) return;

        setImages(props.selectedImages);
    }, [props.selectedImages]);

    return (
        <React.Fragment>
            <ToastContainer/>

            <section className={styles["img-section"]}>
                {
                images && images.length > 0 && <SectionHeading
                    title={locales.SELECTED_IMG_TITLE}
                    sentence={locales.SELECTED_IMG_SENTENCE}
                    flowerSrc={flower}/>
                }
                {
                    props.isUsrAdmin &&
                    <div className={`${styles["add-selected-images-wrapper"]} montserrat-normal-outer-space-17px`}>
                        {
                            images?.length === 0 &&
                            <p>Нямате избрани снимки, добавете няколко...</p>
                        }
                        <button className={`${styles["add-action-btn"]} montserrat-normal-outer-space-17px`}
                                onClick={() => {
                                    setIsAddSelectedImagesModalOpen(true)
                                    window.scrollTo(0, 0);
                                }}
                        >
                            Добави
                        </button>

                        <UpdateImagesModal
                            context={AddImagesCtx.MAKE_UP_SELECTED_IMAGES}
                            isModalOpen={isAddSelectedImagesModalOpen}
                            onModalClose={() => {
                                setIsAddSelectedImagesModalOpen(false)
                            }}
                            makeUpId={props.makeUpId}
                            imageUrlsToExclude={images?.map((img: Image) => img.url)}
                        />
                    </div>
                }
                {
                    images && images.length > 0 && <MakeUpSelectedImagesSlider makeUpId={props.makeUpId} images={images} styles={styles} isUsrAdmin={props.isUsrAdmin}/>
                }
            </section>
        </React.Fragment>
    );
}

export default MakeUpSelectedImages;
