import {catchError, filter, map, switchMap} from "rxjs/operators";
import {fetchGalleriesFailureAction, fetchGalleriesReqAction, fetchGalleriesSuccessAction} from "./reducers";
import {Epic} from "redux-observable";
import {FetchGalleriesReqAct} from "./interfaces";
import GalleryService from "../../../modules/gallery-page-module/services/galleryService";
import {AxiosResponse} from "axios";
import {Gallery} from "../../../@types/types";
import {of} from "rxjs";

export const fetchGalleriesEpic: Epic = (action$) => action$.pipe(
    filter(fetchGalleriesReqAction.match),
    switchMap((action: FetchGalleriesReqAct) => {
        return GalleryService.getAllGalleries().pipe(
            map((res: AxiosResponse<Gallery[]>) => {
                    if (res.status !== 200) {
                        return {
                            type: fetchGalleriesFailureAction.type,
                            payload: {
                                error: "Server error"
                            }
                        }
                    }

                    return {
                        type: fetchGalleriesSuccessAction.type,
                        payload: {
                            galleries: res.data,
                        }
                    }
                }
            ),
            catchError(fetchGalleriesErrorHandler)
        )
    }), catchError(fetchGalleriesErrorHandler)
);

const fetchGalleriesErrorHandler = (err: any) => {
    return of({
        type: fetchGalleriesFailureAction.type,
        payload: {
            error: err.message
        }
    })
}