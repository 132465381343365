import { Observable, of } from 'rxjs';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { RootState } from '../store-config/globalStoreConfig';
import {
  approveReviewFailAct,
  approveReviewReqAct,
  approveReviewScssAct,
  deleteReviewFailAct,
  deleteReviewReqAct,
  deleteReviewScssAct,
  fetchApprovedReviewsFailAct,
  fetchApprovedReviewsReqAct,
  fetchApprovedReviewsScssAct,
  fetchUnapprovedReviewsFailAct,
  fetchUnapprovedReviewsReqAct,
  fetchUnapprovedReviewsScssAct,
} from './reducers';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ApproveReviewReqAct, FetchApprovedReviewsReqAct, FetchUnapprovedReviewsReqAct } from './interfaces';
import ReviewService from '../../../modules/shared/services/ReviewService';
import { AxiosResponse } from 'axios';
import { Review } from '../../../@types/types';
import { toast } from 'react-toastify';
import { toastObj } from '../../../utils/utils';

export const fetchApprovedReviewsEpic = (action$: Observable<Action<string>>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(fetchApprovedReviewsReqAct.match),
    switchMap((action: FetchApprovedReviewsReqAct) => {
      const { reqRsltLimit, reqRsltOffset } = action.payload;

      const limit: number = reqRsltLimit || 10;
      const offset: number = reqRsltOffset || 1;

      return ReviewService.fetchAllReviews(true, offset, limit).pipe(
        map((res: AxiosResponse<Review[]>) => {
          if (res.status !== 200) {
            return {
              type: fetchApprovedReviewsFailAct.type,
              payload: {
                error: 'Error fetching approved reviews',
              },
            };
          }

          return {
            type: fetchApprovedReviewsScssAct.type,
            payload: {
              approvedReviews: res.data,
            },
          };
        })
      );
    }),
    catchError((err: Error) => {
      return of({
        type: fetchApprovedReviewsFailAct.type,
        payload: {
          error: err.message,
        },
      });
    })
  );

export const fetchUnapprovedReviewsEpic = (action$: Observable<Action<string>>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(fetchUnapprovedReviewsReqAct.match),

    switchMap((action: FetchUnapprovedReviewsReqAct) => {
      const { reqRsltLimit, reqRsltOffset } = action.payload;

      const limit: number = reqRsltLimit || 20;
      const offset: number = reqRsltOffset || 1;

      return ReviewService.fetchAllReviews(false, offset, limit).pipe(
        map((res: AxiosResponse<Review[]>) => {
          if (res.status !== 200) {
            return {
              type: fetchUnapprovedReviewsFailAct.type,
              payload: {
                error: 'Error fetching approved reviews',
              },
            };
          }

          return {
            type: fetchUnapprovedReviewsScssAct.type,
            payload: {
              unapprovedReviews: res.data,
            },
          };
        })
      );
    }),
    catchError((err: Error) => {
      return of({
        type: fetchUnapprovedReviewsFailAct.type,
        payload: {
          error: err.message,
        },
      });
    })
  );

export const approveReviewEpic = (action$: Observable<Action<string>>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(approveReviewReqAct.match),
    switchMap((action: ApproveReviewReqAct) => {
      return ReviewService.approveReview(action.payload.review.id).pipe(
        map((res: AxiosResponse<boolean>) => {
          if (res.status !== 200) {
            toast.error('Възникна грешка при одобряването на отзива!', toastObj);

            return {
              type: approveReviewFailAct.type,
              payload: {
                error: 'Error approving review',
              },
            };
          }

          toast.success('Отзивът беше одобрен успешно!', toastObj);
          return {
            type: approveReviewScssAct.type,
            payload: {
              review: action.payload.review,
            },
          };
        })
      );
    }),
    catchError((err: Error) => {
      toast.error('Възникна грешка при одобряването на отзива!', toastObj);
      return of({
        type: approveReviewFailAct.type,
        payload: {
          error: err.message,
        },
      });
    })
  );

export const deleteReviewEpic = (action$: Observable<Action<string>>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(deleteReviewReqAct.match),
    switchMap((action: ApproveReviewReqAct) => {
      return ReviewService.deleteReview(action.payload.review.id).pipe(
        map((res: AxiosResponse<boolean>) => {
          if (res.status !== 200) {
            toast.error('Възникна грешка при изтриването на отзива!', toastObj);

            return {
              type: deleteReviewFailAct.type,
              payload: {
                error: 'Error deleting review',
              },
            };
          }

          toast.success('Отзивът беше изтрит успешно!', toastObj);
          return {
            type: deleteReviewScssAct.type,
            payload: {
              review: action.payload.review,
            },
          };
        })
      );
    }),
    catchError((err: Error) => {
      toast.error('Възникна грешка при изтриването на отзива!', toastObj);
      return of({
        type: deleteReviewFailAct.type,
        payload: {
          error: err.message,
        },
      });
    })
  );
