import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {AuthData} from "../modules/auth/interfaces/AuthData";
import {useSelector} from "react-redux";
import {RootState} from "../store/redux/store-config/globalStoreConfig";
import {useLang} from "../store/context/lang-context";

interface ProtectedRouteProps {
    children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const {lang} = useLang();
    const authData: AuthData | null = useSelector((state: RootState) => state.persistedAuthData?.xxfh?.authData ?? null);
    const isAuthenticated = !!authData;
    const routeLocation = useLocation();

    if (!isAuthenticated) {
        const loginPath = `/${lang || 'bg'}/login`;
        return <Navigate to={loginPath} replace state={{from: routeLocation}}/>;
    }

    return <>{children || <Outlet/>}</>;
}

export default ProtectedRoute;
