import {FC, useEffect} from 'react';
import {useLang} from "../../../../store/context/lang-context";
import {useLocation, useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {cookieLang} from "../../../../cookies/constants";

interface BaseRouterHandlerProps {
}

const BaseRouterHandler: FC<BaseRouterHandlerProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {lang} = useLang();
    const [cookie, setCookie] = useCookies([cookieLang]);

    useEffect(() => {
       if( !location.pathname.startsWith("/en") && !location.pathname.startsWith("/bg")) {
           navigate(`/${lang ?? "bg"}${location.pathname}`);
       }

    }, [lang, location.pathname, navigate, cookie, setCookie]);

    return null;
}

export default BaseRouterHandler;
