import {useTranslation} from "react-i18next";

export default function useHomePageLocale() {
    const {t} = useTranslation();

    const AGITATION_SECTION_TITLE: string = t('AGITATION_SECTION_TITLE');
    const AGITATION_SECTION_SHORT_SENTENCE: string = t('AGITATION_SECTION_SHORT_SENTENCE');
    const AGITATION_SECTION_BTN_LABEL: string = t('AGITATION_SECTION_BTN_LABEL');
    const ABOUT_ME_TITLE: string = t('ABOUT_ME_TITLE');
    const ABOUT_ME_SHORT_SENTENCE: string = t('ABOUT_ME_SHORT_SENTENCE');
    const ABOUT_ME_BTN_LABEL: string = t('ABOUT_ME_BTN_LABEL');
    const MY_WORK_TITLE: string = t('MY_WORK_TITLE');
    const MY_WORK_SHORT_SENTENCE: string = t('MY_WORK_SHORT_SENTENCE');
    const MY_WORK_BTN_LABEL: string = t('MY_WORK_BTN_LABEL');
    const REVIEW_TITLE: string = t('REVIEW_TITLE');
    const REVIEW_SHORT_SENTENCE: string = t('REVIEW_SHORT_SENTENCE');
    const LEAVE_REVIEW_BTN_LABEL: string = t('LEAVE_REVIEW_BTN_LABEL');
    const RECENT_BLOG_SECTION_TITLE: string = t('RECENT_BLOG_SECTION_TITLE');
    const RECENT_BLOG_SECTION_SHORT_SENTENCE: string = t('RECENT_BLOG_SECTION_SHORT_SENTENCE');
    const RECENT_BOLG_SECTION_BTN_LABEL: string = t('RECENT_BOLG_SECTION_BTN_LABEL');
    const SERVICES_TITLE: string = t('SERVICES_TITLE');
    const SERVICES_SHORT_SENTENCE: string = t('SERVICES_SHORT_SENTENCE');
    const SERVICES_BTN_LABEL: string = t('SERVICES_BTN_LABEL');

    return {
        AGITATION_SECTION_TITLE,
        AGITATION_SECTION_SHORT_SENTENCE,
        AGITATION_SECTION_BTN_LABEL,
        ABOUT_ME_TITLE,
        ABOUT_ME_SHORT_SENTENCE,
        ABOUT_ME_BTN_LABEL,
        MY_WORK_TITLE,
        MY_WORK_SHORT_SENTENCE,
        MY_WORK_BTN_LABEL,
        REVIEW_TITLE,
        REVIEW_SHORT_SENTENCE,
        LEAVE_REVIEW_BTN_LABEL,
        RECENT_BLOG_SECTION_TITLE,
        RECENT_BLOG_SECTION_SHORT_SENTENCE,
        RECENT_BOLG_SECTION_BTN_LABEL,
        SERVICES_TITLE,
        SERVICES_SHORT_SENTENCE,
        SERVICES_BTN_LABEL
    }
}