import React, {FC} from 'react';
import styles from './AppointmentSection.module.css';
import background from '../../../../images/background-hour.jpg';
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import useServiceDetailsLocale from "../../locales/ServiceDetailsLocale";

interface AppointmentSectionProps {
}

const AppointmentSection: FC<AppointmentSectionProps> = () => {
    const locales = useServiceDetailsLocale();

    return (
        <section className={styles.AppointmentSection} data-testid="AppointmentSection">
            <img className={styles.background} src={background} alt={"background"}/>
            <div className={styles["info-wrapper"]}>
                <h2 className={"monotypecorsiva-regular-normal-white-40px"}>{locales.APPOINTMENT_TITLE}</h2>
                <p className={"montserrat-normal-white-20px"}>{locales.APPOINTMENT_DESC}</p>
                <ShowMoreBtn location={"/appointment"} label={locales.APPOINTMENT_BTN_LABEL}/>
            </div>
        </section>
    );
}

export default AppointmentSection;
