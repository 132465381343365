import React, { FC } from 'react';
import styles from './RelatedGalleriesSectionMobile.module.css';
import SectionHeading from '../../../shared/components/SectionHeading/SectionHeading';
import flower from '../../../../images/group-1-1@2x.svg';
import GalleryBox from '../../../shared/components/GalleryBox/GalleryBox';
import { Autoplay, EffectFlip, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import useServiceDetailsLocale from '../../locales/ServiceDetailsLocale';
import { MakeUpFullData } from '../../../../interfaces/MakeUp';
import { Lang, useLang } from '../../../../store/context/lang-context';

interface RelatedGalleriesSectionMobileProps {
  makeUp: MakeUpFullData | null;
  isUsrAdmin: boolean | null | undefined;
}

const RelatedGalleriesSectionMobile: FC<RelatedGalleriesSectionMobileProps> = (props) => {
  const locales = useServiceDetailsLocale();

  const { makeUp } = props;
  const { lang } = useLang();

  return (
    <React.Fragment>
      {makeUp && makeUp.galleries.length > 0 && (
        <section className={styles.RelatedGalleriesSectionMobile} data-testid="RelatedGalleriesSection">
          <div className={styles['section-heading-wrapper']}>
            <SectionHeading title={locales.SELECTED_REL_GALLERIES} sentence={undefined} flowerSrc={flower} />
          </div>

          <Swiper
            effect={'flip'}
            grabCursor={true}
            loop={true}
            speed={1000}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFlip, Pagination, Navigation, Autoplay]}
            className={styles['swiper-container']}
          >
            {makeUp?.galleries.map((gallery) => (
              <SwiperSlide className={styles.slide} key={gallery.id}>
                <div className={styles['gallery-box-wrapper']}>
                  <GalleryBox
                    id={gallery.id}
                    title={lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
                    mainImage={gallery.mainImageUrl}
                    imageCount={gallery.imagesCount}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )}
    </React.Fragment>
  );
};

export default RelatedGalleriesSectionMobile;
