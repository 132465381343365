import styles from "./GalleryModificationModal.module.css";
import pencil from "../../../../images/pencil.png";
import React from "react";
import {EditableImageProps} from "./types";

export function EditableImage(props: EditableImageProps): JSX.Element {
    const {
        src,
        alt,
        onHover,
        isHovered,
        onClick
    }: EditableImageProps = props;

    return (
        <div
            className={styles["main-img-container"]}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
        >
            <img
                className={styles['main-image']}
                src={src}
                alt={alt}
            />
            {
                isHovered &&
                <img
                    className={styles['edit-icon']}
                    src={pencil}
                    alt={'edit'}
                    onClick={onClick}
                />
            }
        </div>
    );
}