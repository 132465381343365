import {useTranslation} from "react-i18next";

export default function useAuthLocale() {
    const {t} = useTranslation();

    const LOGIN_TITLE = t('LOGIN_TITLE');
    const LOGIN_REGISTRATION_SENTENCE = t('LOGIN_REGISTRATION_SENTENCE');
    const LOGIN_REGISTRATION_BTN = t('LOGIN_REGISTRATION_BTN');
    const LOGIN_GOOGLE_BTN = t('LOGIN_GOOGLE_BTN');
    const LOGIN_FORGOT_PASSWORD = t('LOGIN_FORGOT_PASSWORD');
    const LOGIN_MAIL = t('LOGIN_MAIL');
    const LOGIN_PASSWORD = t('LOGIN_PASSWORD');
    const LOGIN_EMAIL_ERR_MSG = t('LOGIN_EMAIL_ERR_MSG');
    const LOGIN_ERROR_MSG = t('LOGIN_ERROR_MSG');
    const LOGIN_SUCCESS_MSG = t('LOGIN_SUCCESS_MSG');
    const LOGIN_PASSWORD_ERR_MSG = t('LOGIN_PASSWORD_ERR_MSG');
    const OR = t('OR');
    const REGISTER_TITLE = t('REGISTER_TITLE');
    const REGISTER_SENTENCE = t('REGISTER_SENTENCE');
    const REGISTER_LOGIN_BTN = t('REGISTER_LOGIN_BTN');
    const TOC = t('TOC');
    const GOOGLE_TOC = t('GOOGLE_TOC');
    const REGISTER_FIRSTNAME_LABEL = t('REGISTER_FIRSTNAME_LABEL');
    const REGISTER_LASTNAME_LABEL = t('REGISTER_LASTNAME_LABEL');
    const REGISTER_MAIL_LABEL = t('REGISTER_MAIL_LABEL');
    const REGISTER_CONFIRM_MAIL_LABEL = t('REGISTER_CONFIRM_MAIL_LABEL');
    const REGISTER_PASSWORD_LABEL = t('REGISTER_PASSWORD_LABEL');
    const REGISTER_PASSWORD_CONFIRM_LABEL = t('REGISTER_PASSWORD_CONFIRM_LABEL');
    const REGISTER_BTN = t('REGISTER_BTN');
    const REGISTER_SUCCESS_MSG = t('REGISTER_SUCCESS_MSG');
    const REGISTER_ERROR_MSG = t('REGISTER_ERROR_MSG');
    const REGISTER_ERROR_NAME = t('REGISTER_ERROR_NAME');
    const REGISTER_ERROR_MAIL = t('REGISTER_ERROR_MAIL');
    const REGISTER_ERROR_CONFIRM_MAIL = t('REGISTER_ERROR_CONFIRM_MAIL');
    const REGISTER_ERROR_PASSWORD = t('REGISTER_ERROR_PASSWORD');
    const REGISTER_ERROR_CONFIRM_PASSWORD = t('REGISTER_ERROR_CONFIRM_PASSWORD');
    const REGISTER_TOC_ACCEPT = t('REGISTER_TOC_ACCEPT');
    const REGISTER_TOC = t('REGISTER_TOC');
    const REGISTER_PRIVACY_POLICY = t('REGISTER_PRIVACY_POLICY');
    const REGISTER_NOT_POSSIBLE = t('REGISTER_NOT_POSSIBLE');
    const REGISTER_USER_EXIST = t('REGISTER_USER_EXIST');
    const REGISTER_USERNAME = t('REGISTER_USERNAME');
    const REGISTER_USER_EXIST_BY_EMAIL = t('REGISTER_USER_EXIST_BY_EMAIL');
    const REGISTER_USER_EXIST_BY_USERNAME = t('REGISTER_USER_EXIST_BY_USERNAME');
    const AUTH_KEEP_ME_LOGGED_IN = t('AUTH_KEEP_ME_LOGGED_IN');

    return {
        LOGIN_TITLE,
        LOGIN_REGISTRATION_SENTENCE,
        LOGIN_REGISTRATION_BTN,
        LOGIN_GOOGLE_BTN,
        LOGIN_FORGOT_PASSWORD,
        LOGIN_MAIL,
        LOGIN_PASSWORD,
        LOGIN_EMAIL_ERR_MSG,
        LOGIN_ERROR_MSG,
        LOGIN_SUCCESS_MSG,
        LOGIN_PASSWORD_ERR_MSG,
        OR,
        REGISTER_TITLE,
        REGISTER_SENTENCE,
        REGISTER_LOGIN_BTN,
        TOC,
        GOOGLE_TOC,
        REGISTER_FIRSTNAME_LABEL,
        REGISTER_LASTNAME_LABEL,
        REGISTER_MAIL_LABEL,
        REGISTER_CONFIRM_MAIL_LABEL,
        REGISTER_PASSWORD_LABEL,
        REGISTER_PASSWORD_CONFIRM_LABEL,
        REGISTER_BTN,
        REGISTER_SUCCESS_MSG,
        REGISTER_ERROR_MSG,
        REGISTER_ERROR_NAME,
        REGISTER_ERROR_MAIL,
        REGISTER_ERROR_CONFIRM_MAIL,
        REGISTER_ERROR_PASSWORD,
        REGISTER_ERROR_CONFIRM_PASSWORD,
        REGISTER_TOC,
        REGISTER_TOC_ACCEPT,
        REGISTER_PRIVACY_POLICY,
        REGISTER_NOT_POSSIBLE,
        REGISTER_USER_EXIST,
        REGISTER_USERNAME,
        REGISTER_USER_EXIST_BY_EMAIL,
        REGISTER_USER_EXIST_BY_USERNAME,
        AUTH_KEEP_ME_LOGGED_IN
    }
}