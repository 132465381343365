import React, {FormEvent} from "react";
import {Image} from "../../../../interfaces/Image";
import {Gallery} from "../../../../@types/types";

export type GalleryNameInputProps = {
    onBlur: () => void;
    onChange: () => void;
    value: string | undefined;
    language: string;
    reference: React.RefObject<HTMLInputElement>;
}

export type UploadButtonProps = {
    onClick: () => void;
    text: string;
}

export type EditableImageProps = {
    src: string | undefined
    alt: string;
    onHover: (s: boolean) => void;
    isHovered: boolean;
    onClick: () => void;
}

export type ImagesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    dbImages: Image[];
    mapDBImages: (img: Image) => JSX.Element;
    areMoreImagesToFetch: boolean;
    fetchDBImages: (page: number, size: number) => void;
    pageFetched: number;
    IMG_REQUEST_SIZE: number;
    animateOnClose: boolean;
};

export type GalleryModificationModalProps = {
    isModalOpen: boolean;
    context: GalleryModificationContext;
    onClose: () => void;
    onSuccessClose: () => void;
    onSubmit?: (event?: FormEvent) => void;
    gallery?: Gallery | null;
}

export enum GalleryModificationContext {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export enum ImageType {
    LOCAL = 'LOCAL',
    DB = 'DB',
}

export type MainImageUploadState = {
    hasMainImgChanged: boolean;
    type: ImageType | null;
}