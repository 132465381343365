import {useTranslation} from "react-i18next";

export default function useLeaveReviewLocale() {
    const {t} = useTranslation();

    const LEAVE_REVIEW_TITLE = t('LEAVE_REVIEW_TITLE');
    const LEAVE_REVIEW_SENTENCE = t('LEAVE_REVIEW_SENTENCE');
    const LEAVE_REVIEW_BTN = t('LEAVE_REVIEW_BTN');
    const LEAVE_REVIEW_RATING = t('LEAVE_REVIEW_RATING');
    const LEAVE_REVIEW_TEXT_AREA = t('LEAVE_REVIEW_TEXT_AREA');
    const LEAVE_REVIEW_FIRSTNAME_LABEL = t('APPOINTMENT_FIRSTNAME_LABEL');
    const LEAVE_REVIEW_LASTNAME_LABEL = t('APPOINTMENT_LASTNAME_LABEL');
    const LEAVE_REVIEW_ERROR_NAME = t('REGISTER_ERROR_NAME');
    const LEAVE_REVIEW_TEXT_ERR_MSG = t('LEAVE_REVIEW_TEXT_ERR_MSG');
    const LEAVE_REVIEW_SUCCESS_MSG = t('LEAVE_REVIEW_SUCCESS_MSG');
    const LEAVE_REVIEW_ERROR_MSG = t('REGISTER_ERROR_MSG');

    return {
        LEAVE_REVIEW_TITLE,
        LEAVE_REVIEW_SENTENCE,
        LEAVE_REVIEW_BTN,
        LEAVE_REVIEW_RATING,
        LEAVE_REVIEW_TEXT_AREA,
        LEAVE_REVIEW_FIRSTNAME_LABEL,
        LEAVE_REVIEW_LASTNAME_LABEL,
        LEAVE_REVIEW_ERROR_NAME,
        LEAVE_REVIEW_TEXT_ERR_MSG,
        LEAVE_REVIEW_SUCCESS_MSG,
        LEAVE_REVIEW_ERROR_MSG
    };
}