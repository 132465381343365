import React, { FC } from 'react';
import styles from './HomePage.module.css';
import logo from '../../images/LogoBlagaD-blank 150x150-crop.png';

import desktopSlide1 from '../../images/slider/desktop/pc-1.png';
import desktopSlide2 from '../../images/slider/desktop/pc-2.png';
import desktopSlide3 from '../../images/slider/desktop/pc-3.png';
import desktopSlide4 from '../../images/slider/desktop/pc-4.png';
import desktopSlide6 from '../../images/slider/desktop/pc-6.png';

import mobileSlide1 from '../../images/slider/mobile/mobile-1.png';
import mobileSlide2 from '../../images/slider/mobile/mobile-2.png';
import mobileSlide3 from '../../images/slider/mobile/mobile-3.png';
import mobileSlide5 from '../../images/slider/mobile/mobile-5.png';

import useWindowDimensions from '../../hooks/screen-resolution-checker';
import PromotionSlider from '../../modules/home-page-module/components/PromotionSlider/PromotionSlider';
import ServicesMiniWrapper from '../../modules/home-page-module/components/ServicesMiniWrapper/ServicesMiniWrapper';
import HomeAboutMe from '../../modules/home-page-module/components/HomeAboutMe/HomeAboutMe';
import HomeAboutMeMobile from '../../modules/home-page-module/components/HomeAboutMeMobile/HomeAboutMeMobile';
import HomeMyWork from '../../modules/home-page-module/components/HomeMyWork/HomeMyWork';
import HomeMyWorkMobile from '../../modules/home-page-module/components/HomeMyWorkMobile/HomeMyWorkMobile';
import HomeReview from '../../modules/home-page-module/components/HomeReview/HomeReview';
import HomeReviewMobile from '../../modules/home-page-module/components/HomeReviewMobile/HomeReviewMobile';
import AppointmentAgitation from '../../modules/home-page-module/components/AppointmentAgitation/AppointmentAgitation';

interface HomePageProps {}

const containerStyle: string = `${styles['header-container']}`;

const slidesDesktop: Array<string> = [desktopSlide1, desktopSlide2, desktopSlide3, desktopSlide4, desktopSlide6];

const slidesMobile: Array<string> = [mobileSlide1, mobileSlide2, mobileSlide3, mobileSlide5];

const HomePage: FC<HomePageProps> = () => {
  return (
    <React.Fragment>
      <div className={containerStyle}>
        {useWindowDimensions().width < 769 && <img className={styles.logo} src={logo} alt={'logo'} />}
        <PromotionSlider slides={slidesDesktop} slidesMobile={slidesMobile} />
      </div>

      <ServicesMiniWrapper />
      {useWindowDimensions().width > 965 ? (
        <React.Fragment>
          <HomeAboutMe />
          <HomeMyWork />
          <HomeReview />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <HomeAboutMeMobile />
          <HomeMyWorkMobile />
          <HomeReviewMobile />
        </React.Fragment>
      )}
      <AppointmentAgitation />
    </React.Fragment>
  );
};

export default HomePage;
