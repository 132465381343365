import React, {FC} from 'react';
import styles from './ReviewMessageBox.module.css';
import Avatar from "../../../shared/components/Avatar/Avatar";
import Rating from "../../../shared/components/Rating/Rating";

interface ReviewMessageBoxProps {
    userFullName: string;
    reviewMessage: string;
    userImg: string;
    rating: number;
}

const ReviewMessageBox: FC<ReviewMessageBoxProps> = (props) => (
    <React.Fragment>
        <div className={styles["user-row"]}>
            <div className={styles["user-info"]}>
                <Avatar src={props.userImg}/>
                <p className={`${styles["user-name"]} montserrat-medium-black-14px`}>{props.userFullName}</p>
            </div>
            <Rating readonly={true} stars={props.rating}/>
        </div>
        <p className={`${styles["review-message"]} montserrat-normal-black-15px`}>{props.reviewMessage}</p>
        {/*<img src={arrow} alt={""} className={styles.arrow}/>*/}
    </React.Fragment>
);

export default ReviewMessageBox;
