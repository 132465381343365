import {FetchUserAct, LoginAct, AuthState, LogoutAct} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";

const initialState: AuthState = {
    xxfh: null,
    loading: false,
    fetched: false,
    error: null,
}

const loginReq: CaseReducer<AuthState, LoginAct> = (state, action: LoginAct) => {
    state.xxfh = null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const loginSuccess: CaseReducer<AuthState, LoginAct> = (state, action: LoginAct) => {
    state.xxfh = {
        authProvider: action.payload.authProvider,
        authData: action.payload.authData,
        user: state.xxfh?.user || null
    };
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const loginFailure: CaseReducer<AuthState, LoginAct> = (state, action: LoginAct) => {
    state.xxfh = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error;
}

const fetchUserReq: CaseReducer<AuthState, FetchUserAct> = (state, action: FetchUserAct) => {
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchUserSuccess: CaseReducer<AuthState, FetchUserAct> = (state, action: FetchUserAct) => {
    state.xxfh = {
        authProvider: state.xxfh?.authProvider,
        authData: state.xxfh?.authData,
        user: action.payload.user
    }
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchUserFailure: CaseReducer<AuthState, FetchUserAct> = (state, action: FetchUserAct) => {
    state.xxfh = {
        authProvider: state.xxfh?.authProvider,
        authData: state.xxfh?.authData,
        user: null
    }
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error;
}

const logoutReq: CaseReducer<AuthState, LogoutAct> = (state, action: LogoutAct) => {
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const logoutSuccess: CaseReducer<AuthState, LogoutAct> = (state, action: LogoutAct) => {
    state.xxfh = null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

export const UserStateSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginReq,
        loginSuccess,
        loginFailure,
        fetchUserReq,
        fetchUserSuccess,
        fetchUserFailure,
        logoutReq,
        logoutSuccess
    }
});

export const {
    loginReq: loginReqAction,
    loginSuccess: loginSuccessAction,
    loginFailure: loginFailureAction,
    fetchUserReq: fetchUserReqAction,
    fetchUserSuccess: fetchUserSuccessAction,
    fetchUserFailure: fetchUserFailureAction,
    logoutReq: logoutReqAction,
    logoutSuccess: logoutSuccessAction
} = UserStateSlice.actions;


