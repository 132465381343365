import {UploadButtonProps} from "./types";
import styles from "./GalleryModificationModal.module.css";

export function UploadBtn({onClick, text}: UploadButtonProps) {
    return (
        <button
            type="button"
            className={`${styles['upload-btn']} montserrat-normal-outer-space-17px`}
            onClick={onClick}
        >
            {text}
        </button>
    );
}
