import React, {FC} from 'react';
import styles from './HomeMyWork.module.css';
import grid from '../../../../images/green-line-galery-home-page@1x.png';
import myWorkFirst from '../../../../images/my-work-1.jpg';
import myWorkSecond from '../../../../images/my-work-3.jpg';
import myWorkThird from '../../../../images/my-work-2.jpg';
import myWorkFourth from '../../../../images/my-work-6.jpg';
import myWorkFifth from '../../../../images/my-work-4.jpg';
import myWorkSixth from '../../../../images/my-work-5.jpg';
import flower from "../../../../images/group-1-1@2x.svg";
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import useHomePageLocale from "../../locales/HomePageLocales";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface HomeMyWorkProps {
}

const HomeMyWork: FC<HomeMyWorkProps> = () => {
    const locales = useHomePageLocale();

    return (
        <React.Fragment>
            <img src={grid} alt={"grid"} className={styles.grid}/>
            <section id="home-my-work" className={styles.HomeMyWork} data-testid="HomeMyWork">
                <div className={styles["info-wrapper"]}>
                    <SectionHeading title={locales.MY_WORK_TITLE} sentence={locales.MY_WORK_SHORT_SENTENCE}
                                    flowerSrc={flower}/>
                </div>
                <div className={styles["images-wrapper"]}>
                    <div className={styles["image-box-first-row"]}>
                        <AnimationOnScroll
                            animateIn="animate__fadeInTopLeft"
                            duration={1.2}
                            animateOnce={true}
                        >
                            <img id={styles["first-img"]} src={myWorkFirst} alt={""} className={styles.portrait}/>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animate__fadeInDown"
                            duration={1.2}>
                            <img id={styles["second-img"]} src={myWorkSecond} alt={""} className={styles.landscape}/>
                        </AnimationOnScroll>

                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animate__fadeInTopRight"
                            duration={1.2}>
                            <img id={styles["third-img"]} src={myWorkThird} alt={""} className={styles.landscape}/>
                        </AnimationOnScroll>
                    </div>
                    <div className={styles["image-box-second-row"]}>
                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animate__fadeInBottomLeft"
                            duration={1.2}>
                            <img id={styles["fourth-img"]} src={myWorkFourth} alt={""} className={styles.landscape}/>
                        </AnimationOnScroll>

                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animate__fadeInUp"
                            duration={1.2}>

                            <img id={styles["fifth-img"]} src={myWorkFifth} alt={""} className={styles.portrait}/>
                        </AnimationOnScroll>

                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animate__fadeInBottomRight"
                            duration={1.2}>
                            <img id={styles["sixth-img"]} src={myWorkSixth} alt={""} className={styles.portrait}/>
                        </AnimationOnScroll>
                    </div>
                </div>
                <div id={styles["show-more-wrapper"]}>
                    <ShowMoreBtn location={"/galleries"} label={locales.ABOUT_ME_BTN_LABEL}/>
                </div>
            </section>
        </React.Fragment>
    );
}

export default HomeMyWork;
