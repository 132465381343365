import React, {FC} from 'react';
import styles from './Loader.module.css';

interface LoaderProps {
}

const MiniLoader: FC<LoaderProps> = () => (
        <React.Fragment>
            <div className={styles.Loader} style={{position: 'relative'}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </React.Fragment>
    )
;

export default MiniLoader;
