import React, { FC, useCallback, useEffect, useRef } from 'react';
import styles from './UpdateImagesModal.module.css';
import ReactModal from 'react-modal';
import confirmIcon from '../../../../images/confirm.svg';
import cancelIcon from '../../../../images/cancel.svg';
import { Image } from '../../../../interfaces/Image';
import ImageService from '../../../shared/services/ImageService';
import { Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { toast, ToastContainer } from 'react-toastify';
import { toastObj } from '../../../../utils/utils';
import { useMakeUpActions } from '../../../../store/redux/make-ups/useMakeUps';
import MakeUpService from '../../../shared/services/MakeUpService';
import { AxiosResponse } from 'axios';
import 'animate.css';
import Loader from '../../../shared/components/Loader/Loader';
import { MakeUpType } from '../../../../enums/enums';
import GalleryService from '../../../gallery-page-module/services/galleryService';
import { AddGalleryImagesData } from '../../../../@types/types';
import useGalleryImagesActions from '../../../../store/redux/gallery-images/useGalleryImages';
import useGalleryImages from "../../../../store/redux/gallery-images/useGalleryImages";

export enum AddImagesCtx {
  MAKE_UP_SELECTED_IMAGES = 'MAKE_UP_SELECTED_IMAGES',
  MAKE_UP_MAIN_IMAGE = 'MAKE_UP_MAIN_IMAGE',
  GALLERY_IMAGES = 'GALLERY_IMAGES',
}

interface AddSelectedImagesModalProps {
  context: AddImagesCtx;
  isModalOpen: boolean;
  onModalClose: () => void;
  makeUpId?: string;
  makeUpType?: MakeUpType;
  galleryId?: string;
  imagesToFetch?: number;
  imageUrlsToExclude?: string[];
}

const IMG_REQUEST_SIZE: number = 10;

const UpdateImagesModal: FC<AddSelectedImagesModalProps> = (props) => {
  const localImagesInputRef = useRef<HTMLInputElement>(null);

  const [selectedLocalImages, setSelectedLocalImages] = React.useState<File[]>([]);
  const [isSelectDBImagesModalOpen, setIsSelectDBImagesModalOpen] = React.useState(false);
  const [dbImages, setDBImages] = React.useState<Image[]>([]);
  const [selectedDBImages, setSelectedDbImages] = React.useState<Image[]>([]);
  const [pageFetched, setPageFetched] = React.useState<number>(0);
  const [areMoreImagesToFetch, setAreMoreImagesToFetch] = React.useState<boolean>(true);
  const [animateOnDBImagesModalClose, setAnimateOnDBImagesModalClose] = React.useState<boolean>(true);
  const [animateOnMainModalClose, setAnimateOnMainModalClose] = React.useState<boolean>(false);
  const [isActionLoading, setIsActionLoading] = React.useState<boolean>(false);
  const [isUpdateMainImageCtx, setIsUpdateMainImageCtx] = React.useState<boolean>(props.context === AddImagesCtx.MAKE_UP_MAIN_IMAGE);

  const { fetchGalleryImages, clearImageState } = useGalleryImagesActions();

  const { addSelectedImagesToMakeUpService, updateMakeUpMainImageUrlInVisitedMakeUpService, updateMakeUpMainImageUrl } =
    useMakeUpActions();

  const destroy$: Subject<void> = new Subject<void>();

  useEffect(() => {
    return (): void => {
      destroy$.next();
      destroy$.complete();
    };
  }, []);

  useEffect(() => {
    setIsUpdateMainImageCtx(props.context === AddImagesCtx.MAKE_UP_MAIN_IMAGE);
  }, [props.context]);

  const fetchDBImages = (page: number, size: number) => {
    setIsActionLoading(true);

    (pageFetched !== page || dbImages.length === 0) &&
      ImageService.getAllImages({ page, size, imageUrlsToExclude: props.imageUrlsToExclude || [] })
        .pipe(
          tap((res) => {
            if (res.status !== 200) throw new Error('Error fetching images from DB');

            setDBImages([...dbImages, ...res.data]);
            setPageFetched(page);
            setIsActionLoading(false);

            setAreMoreImagesToFetch(res.data.length === size);
          }),
          catchError((err) => {
            toast.error(err.message, toastObj);
            setIsActionLoading(false);
            return of(err);
          }),
          takeUntil(destroy$)
        )
        .subscribe();
  };

  const handleLocalImagesSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = event.target.files;

      if (!files) return;

      if (isUpdateMainImageCtx) {
        // if in 'Update Main Image' context, clear the selected DB images state and set it to an empty array
        setSelectedDbImages([]);
        setDBImages((prevState) => prevState.concat(selectedDBImages));
      }

      // append new images to selectedLocalImages state
      setSelectedLocalImages((prevState) => (isUpdateMainImageCtx ? Array.from(files) : [...prevState, ...Array.from(files)]));
    },
    [isUpdateMainImageCtx, selectedDBImages]
  );

  const mapDBImages = useCallback(
    (image: Image): JSX.Element => {
      const callback = (): void => {
        const prevSelectedDBImages: Image[] = selectedDBImages;

        setSelectedDbImages(isUpdateMainImageCtx ? [image] : [...selectedDBImages, image]);

        const newDBImagesState = isUpdateMainImageCtx
          ? [...prevSelectedDBImages, ...dbImages.filter((img: Image): boolean => img.id !== image.id)]
          : dbImages.filter((img: Image): boolean => img.id !== image.id);

        isUpdateMainImageCtx && setSelectedLocalImages([]);

        setDBImages(newDBImagesState);
      };

      return createImageComponent(image.id, image.url, callback, true);
    },
    [selectedDBImages, dbImages]
  );

  const mapLocalImages = useCallback(
    (file: File, index: number): JSX.Element => {
      const imageStateDispatcher = (): void => {
        const images: File[] = selectedLocalImages.reduce(
          (acc: File[], curr: File): File[] => (curr.name !== file.name ? [...acc, curr] : acc),
          []
        );

        return setSelectedLocalImages(images);
      };

      return createImageComponent(file.name + index, URL.createObjectURL(file), imageStateDispatcher, false);
    },
    [selectedLocalImages]
  );

  const mapSelectedDBImages = useCallback(
    (image: Image): JSX.Element => {
      const imageStateDispatcher = (): void => {
        const images: Image[] = selectedDBImages.reduce(
          (acc: Image[], curr: Image): Image[] => (curr.id !== image.id ? [...acc, curr] : acc),
          []
        );

        /**
         * If the image is removed from the selected images, it should be added back to the db images
         */
        setDBImages([...dbImages, image]);

        return setSelectedDbImages(images);
      };

      return createImageComponent(image.id, image.url, imageStateDispatcher, false);
    },
    [selectedDBImages, dbImages]
  );

  const createImageComponent = (key: string, url: string, clickCallback: () => void, isCheckMarkSymbol: boolean): JSX.Element => {
    return (
      <div key={key} className={styles['img-container']} onClick={clickCallback}>
        <img className={styles['selected-image']} src={url} alt={'db-selection'} />
        <div className={styles['image-overlay']}>
          <div className={styles['remove']} style={{ color: isCheckMarkSymbol ? '#008000' : '#be2929' }}>
            {isCheckMarkSymbol ? <span>&#x2714;</span> : <span>&#x2718;</span>}
          </div>
        </div>
      </div>
    );
  };

  const addSelectedImages = () => {
    if (selectedLocalImages.length === 0 && selectedDBImages.length === 0) {
      toast.error('Не сте избрали снимки за добавяне', toastObj);
      return;
    }

    const addMakeUpSelectedImages = (urls: string[]): Observable<AxiosResponse<Image[]>> => {
      urls = urls.map(url => url.replace(/^[\t\n]+/, ''));
      console.log(urls)

      return MakeUpService.addSelectedImages(props.makeUpId!, urls).pipe(
        tap((res) => {
          if (res.status !== 200) throw new Error('Error adding images to makeup');

          toast.success('Снимките бяха добавени успешно', toastObj);
          props.onModalClose();
          setIsActionLoading(false);

          addSelectedImagesToMakeUpService(res.data, props.makeUpId!);
        }),
        takeUntil(destroy$)
      );
    };

    setIsActionLoading(true);

    if (selectedLocalImages.length > 0) {
      ImageService.uploadMultipleImages(selectedLocalImages)
        .pipe(
          switchMap((res) => {
            if (res.status !== 201) throw new Error('Error uploading images');

            const urls: string[] = selectedDBImages.length > 0 ? selectedDBImages.map((img) => img.url).concat(res.data) : res.data;

            return addMakeUpSelectedImages(urls);
          }),
          takeUntil(destroy$),
          catchError(ajaxErrorHandler)
        )
        .subscribe();
    } else if (selectedDBImages.length > 0 && selectedLocalImages.length === 0) {
      addMakeUpSelectedImages(selectedDBImages.map((img) => img.url)).subscribe();
    }
  };

  const updateMainImage = () => {
    if (selectedLocalImages.length === 0 && selectedDBImages.length === 0) {
      toast.error('Не сте избрали снимка за добавяне', toastObj);
      return;
    }

    const updateMakeUpMainImage = (url: string): Observable<AxiosResponse<Image>> => {
      return MakeUpService.replaceMainImg(props.makeUpId!, url).pipe(
        tap((res) => {
          if (res.status !== 200) throw new Error('Error updating main image');

          toast.success('Главната снимка беше променена успешно', toastObj);
          props.onModalClose();
          setIsActionLoading(false);

          updateMakeUpMainImageUrlInVisitedMakeUpService(props.makeUpId!, url);
          updateMakeUpMainImageUrl(props.makeUpId!, url, props.makeUpType!);
        }),
        takeUntil(destroy$)
      );
    };

    setIsActionLoading(true);

    if (selectedLocalImages.length > 0) {
      ImageService.uploadImage(selectedLocalImages[0])
        .pipe(
          switchMap((res) => {
            if (res.status !== 201) throw new Error('Error uploading images');

            return updateMakeUpMainImage(res.data);
          }),
          catchError(ajaxErrorHandler),
          takeUntil(destroy$)
        )
        .subscribe();
    } else if (selectedDBImages.length > 0 && selectedLocalImages.length === 0) {
      updateMakeUpMainImage(selectedDBImages[0].url).subscribe();
    }
  };

  const addGalleryImages = (): void => {
    if (selectedLocalImages.length === 0 && selectedDBImages.length === 0) {
      toast.error('Не сте избрали снимки за добавяне', toastObj);
      return;
    }

    const addGalleryImages = (urls: string[]): Observable<AxiosResponse<number> | Error> => {
      const data: AddGalleryImagesData = {
        galleryIds: [props.galleryId!],
        imageUrls: urls,
      };

      return GalleryService.addImages(data).pipe(
        tap((res) => {
          if (res.status !== 201) throw new Error('Error adding images to gallery');
          clearImageState()
          props.onModalClose();
          toast.success('Снимките бяха добавени успешно', toastObj);
          setIsActionLoading(false);
        }),
        finalize(() => fetchGalleryImages(props.galleryId!, true, 1, props.imagesToFetch!)),
        takeUntil(destroy$),
        catchError(ajaxErrorHandler)
      );
    };

    setIsActionLoading(true);

    if (selectedLocalImages.length > 0) {
      ImageService.uploadMultipleImages(selectedLocalImages)
        .pipe(
          switchMap((res) => {
            if (res.status !== 201) throw new Error('Error uploading images');

            const urls: string[] = selectedDBImages.length > 0 ? selectedDBImages.map((img) => img.url).concat(res.data) : res.data;

            return addGalleryImages(urls);
          }),
          takeUntil(destroy$),
          catchError(ajaxErrorHandler)
        )
        .subscribe();
    } else {
      addGalleryImages(selectedDBImages.map((img) => img.url)).subscribe();
    }
  };

  const ajaxErrorHandler = (err: Error) => {
    toast.error(err.message, toastObj);
    setIsActionLoading(false);
    props.onModalClose();
    return of(err);
  };

  return (
    <React.Fragment>
      {isActionLoading && <Loader />}


      <ReactModal
        isOpen={props.isModalOpen}
        className={`animate__animated ${styles.modal} animate__backInDown ${animateOnMainModalClose && 'animate__backOutUp'}`}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={300}
        preventScroll={false}
        parentSelector={() => document.body}
      >
        <ToastContainer />

        <p className={`${styles['action-sentence']} montserrat-normal-outer-space-17px`}>
          Моля избере снимки за добавяне. Изберете снимки от базата от данни или качете снимки от локалната файлова система. Също така
          можете да комбинирате и двете варианта.
        </p>

        <div className={styles['btn-wrapper']}>
          <button
            className={`${styles['action-btn']} montserrat-normal-outer-space-17px`}
            onClick={() => {
              setAnimateOnDBImagesModalClose(false);
              setIsSelectDBImagesModalOpen(true);
              dbImages.length === 0 && fetchDBImages(pageFetched + 1, IMG_REQUEST_SIZE);
            }}
          >
            Избери от базата от данни
          </button>
          <button
            className={`${styles['action-btn']} montserrat-normal-outer-space-17px`}
            onClick={() => localImagesInputRef.current?.click()}
          >
            Избери от локалната файлова система
          </button>

          <input
            ref={localImagesInputRef}
            type={'file'}
            multiple={props.context !== AddImagesCtx.MAKE_UP_MAIN_IMAGE}
            accept={'image/*'}
            onChange={handleLocalImagesSelection}
            style={{ opacity: '0', position: 'absolute', top: '-200px' }}
          />
        </div>

        <div className={styles['selected-images-wrapper']}>
          {selectedLocalImages && selectedLocalImages.map(mapLocalImages)}
          {selectedDBImages && selectedDBImages.map(mapSelectedDBImages)}
        </div>

        <div className={styles['actions-wrapper']}>
          <img
            onClick={() => {
              if (props.context === AddImagesCtx.MAKE_UP_MAIN_IMAGE) updateMainImage();
              else if (props.context === AddImagesCtx.MAKE_UP_SELECTED_IMAGES) addSelectedImages();
              else if (props.context === AddImagesCtx.GALLERY_IMAGES) addGalleryImages();
            }}
            className={styles['action-icon']}
            src={confirmIcon}
            alt={'confirm'}
          />

          <img
            onClick={() => {
              setAnimateOnMainModalClose(true);
              setTimeout(() => {
                props.onModalClose();
                setAnimateOnMainModalClose(false);
              }, 400);
            }}
            className={styles['action-icon']}
            src={cancelIcon}
            alt={'close'}
          />
        </div>

        {isSelectDBImagesModalOpen && (
          <React.Fragment>
            <div className={styles['background-overlay']} />

            <ReactModal
              className={`${styles.modal} animate__animated animate__bounceInDown ${
                animateOnDBImagesModalClose && 'animate__fadeOutDownBig'
              }`}
              overlayClassName={`${styles.overlay}`}
              isOpen={isSelectDBImagesModalOpen}
            >
              <div className={styles['db-images-modal']}>
                <img
                  onClick={() => {
                    setAnimateOnDBImagesModalClose(true);
                    setTimeout(() => {
                      setIsSelectDBImagesModalOpen(false);
                    }, 400);
                  }}
                  className={`${styles['action-icon']} ${styles['close-icon']}`}
                  src={cancelIcon}
                  alt={'close'}
                />
                <div className={styles['selected-images-wrapper']}>{dbImages && dbImages.map(mapDBImages)}</div>
              </div>

              {areMoreImagesToFetch && (
                <button
                  className={`${styles['action-btn']} montserrat-normal-outer-space-17px`}
                  onClick={() => areMoreImagesToFetch && fetchDBImages(pageFetched + 1, IMG_REQUEST_SIZE)}
                >
                  Зареди още
                </button>
              )}
            </ReactModal>
          </React.Fragment>
        )}
      </ReactModal>
    </React.Fragment>
  );
};

export default UpdateImagesModal;
