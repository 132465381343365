import React, {FC, useEffect, useState} from 'react';
import styles from './RelatedGalleriesSection.module.css';
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import flower from "../../../../images/group-1-1@2x.svg";
import GalleryBox from "../../../shared/components/GalleryBox/GalleryBox";
import useServiceDetailsLocale from "../../locales/ServiceDetailsLocale";
import {MakeUpFullData} from "../../../../interfaces/MakeUp";
import {Lang, useLang} from "../../../../store/context/lang-context";
import {Gallery} from "../../../../@types/types";
import deletePermanent from "../../../../images/delete-red.png";
import ConfirmModal from "../../../shared/components/ConfirmModal/ConfirmModal";
import MakeUpService from "../../../shared/services/MakeUpService";
import {finalize, of, Subject, takeUntil, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {toast} from "react-toastify";
import {toastObj} from "../../../../utils/utils";
import {useMakeUpActions} from "../../../../store/redux/make-ups/useMakeUps";
import {GalleriesState} from "../../../../store/redux/galleries/interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/redux/store-config/globalStoreConfig";
import useGalleriesActions from "../../../../store/redux/galleries/useGalleries";

interface RelatedGalleriesSectionProps {
    makeUp: MakeUpFullData | null;
    isUsrAdmin: boolean | null | undefined;
}

interface ModalState {
    isOpen: boolean;
    onConfirm: (() => void);
    message: string;
}

const RelatedGalleriesSection: FC<RelatedGalleriesSectionProps> = (props) => {
    const [modalState, setModalState] = useState<ModalState>({
        isOpen: false,
        onConfirm: () => {
        },
        message: "",
    });

    const [selectedGalleries, setSelectedGalleries] = useState<Gallery[]>([]);
    const [isGalleryMenuOpen, setIsGalleryMenuOpen] = useState(false);
    const [galleries, setGalleries] = useState<Gallery[] | null>(null);

    const {makeUp, isUsrAdmin} = props;
    const {lang} = useLang();
    const {replaceVisitedMakeUpService, removeGalleryFromVisitedMakeUpService} = useMakeUpActions();
    const {fetchGalleries} = useGalleriesActions();

    const galleriesData: GalleriesState = useSelector((state: RootState) => state.galleriesData);
    const locales: any = useServiceDetailsLocale();
    const destroy$ = new Subject<void>();

    useEffect(() => {
        return () => {
            destroy$.next();
            destroy$.complete();
        }
    });

    useEffect(() => {
        if (isUsrAdmin && !galleriesData?.galleries && galleriesData?.loading === false) {
            fetchGalleries();
            return;
        }

        setGalleries(galleriesData.galleries);
    }, [galleriesData]);

    const removeGallery = (id: string): void => {
        MakeUpService.removeGallery(makeUp!.id, id).pipe(
            tap(res => {
                if (res.status !== 200) throw new Error('error while removing gallery');

                toast.success("Успешно премахнахте галерията", toastObj);
                removeGalleryFromVisitedMakeUpService(makeUp!.id, id);
            }),
            takeUntil(destroy$),
            catchError(err => {
                toast.error(err.message, toastObj);
                return of(err);
            }),
            finalize(() => clearModalState())
        ).subscribe();

    }

    const addGalleries = (selectedGalleries: Gallery[]): void => {
        const galleryIds: string[] = selectedGalleries.map((gallery) => gallery.id);

        selectedGalleries.length >= 0 && MakeUpService.addGalleries(makeUp!.id, galleryIds).pipe(
            tap(res => {
                if (res.status !== 200) throw new Error('error while adding galleries');

                toast.success("Успешно добавихте галерии", toastObj);
                replaceVisitedMakeUpService(res.data);
            }),
            catchError(err => {
                toast.error(err.message, toastObj);
                return of(err);
            }),
            finalize(() => {
                clearModalState();
                setSelectedGalleries([])
                setIsGalleryMenuOpen(false);
            }),
            takeUntil(destroy$)
        ).subscribe();
    }

    const clearModalState = (): void => {
        setModalState({
            isOpen: false,
            onConfirm: () => {
            },
            message: "",
        });
    }

    const galleryOptions: JSX.Element[] | undefined =
        galleries?.filter((gallery) => {
            const existingMakeUp: Gallery | undefined = makeUp?.galleries?.find((g) => g.id === gallery.id)

            return !(!!existingMakeUp);
        }).map((gallery) => {
            const isGallerySelected = (galleryId: string) => {
                return selectedGalleries.includes(galleries?.find(g => g.id === galleryId)!);
            }

            return (
                <p key={gallery.id}
                   className={`${styles["menu-item"]} ${isGallerySelected(gallery.id) && styles["menu-item-selected"]}`}
                   onClick={() => {
                       isGallerySelected(gallery.id)
                           ? setSelectedGalleries(selectedGalleries.filter((g) => g.id !== gallery.id))
                           : setSelectedGalleries([...selectedGalleries, gallery]);
                   }}
                >
                    {lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
                </p>
            )
        });

    return (
        <React.Fragment>
            {
                isUsrAdmin &&
                <div className={styles["add-galleries-wrapper"]}>

                    <p className={`${styles["add-galleries-text"]} montserrat-normal-outer-space-17px`}>
                        Добавете галерии към услугата
                    </p>

                    <div className={`${styles.label} montserrat-normal-outer-space-17px`}>
                        <label>{locales.SERVICES_FORM_GALLERY_LABEL}</label>

                        <div onClick={() => setIsGalleryMenuOpen(!isGalleryMenuOpen)}
                             className={`${styles.select} ${styles["galleries-toggle"]} montserrat-normal-outer-space-17px`}>
                            Галерии:
                        </div>
                        <div style={{
                            display: isGalleryMenuOpen ? "block" : "none",
                        }}
                             className={`${styles["services-menu"]} montserrat-normal-outer-space-17px`}>
                                     <span
                                         className={`${styles.remove} ${styles["float-right"]}`}
                                         onClick={() => setIsGalleryMenuOpen(false)}
                                     >&#x274C;</span>
                            {galleryOptions}
                        </div>
                    </div>
                    {
                        selectedGalleries.length > 0 &&
                        <div className={styles["add-galleries-btn-wrapper"]}>
                            <div className={styles["selected-galleries-wrapper"]}>
                                {
                                    selectedGalleries.map((gallery) => {
                                        return (
                                            <div key={gallery.id} className={styles["selected-gallery-wrapper"]}>
                                                <p className={`${styles["selected-gallery-name"]} montserrat-normal-outer-space-17px`}>
                                                    {lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
                                                </p>
                                            </div>)
                                    })
                                }
                            </div>
                            <button
                                className={`${styles["add-galleries-btn"]} montserrat-normal-outer-space-17px`}
                                onClick={() => {
                                    setModalState({
                                        isOpen: true,
                                        onConfirm: () => addGalleries(selectedGalleries),
                                        message: "Сигурни ли сте, че искате да добавите избраните галерии?",
                                    });
                                }}
                            >
                                Добави
                            </button>
                        </div>
                    }
                </div>
            }

            {
                makeUp && makeUp.galleries.length > 0 &&
                <section className={styles.RelatedGalleriesSection} data-testid="RelatedGalleriesSection">
                    <div className={styles["section-heading-wrapper"]}>
                        <SectionHeading title={locales.SELECTED_REL_GALLERIES}
                                        sentence={locales.SELECTED_REL_GAL_DESC}
                                        flowerSrc={flower}/>
                    </div>

                    <div className={styles["galleries-wrapper"]}>
                        {
                            makeUp.galleries.map((gallery: Gallery) => {
                                    return (
                                        <div key={gallery.id} className={styles["box-wrapper"]}>
                                            {
                                                isUsrAdmin &&
                                                <img
                                                    src={deletePermanent}
                                                    alt={"delete"}
                                                    className={styles["delete-img-icon"]}
                                                    onClick={() => {
                                                        setModalState({
                                                            isOpen: true,
                                                            onConfirm: () => removeGallery(gallery.id),
                                                            message: `Сигурни ли сте, че искате да премахнете галерията ${lang === Lang.bg ? gallery.nameBG : gallery.nameEN}?`,
                                                        });
                                                    }}
                                                />
                                            }
                                            <GalleryBox
                                                id={gallery.id}
                                                title={lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
                                                mainImage={gallery.mainImageUrl}
                                                imageCount={gallery.imagesCount}/>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </section>
            }
            {
                isUsrAdmin &&
                <ConfirmModal
                    isOpen={modalState.isOpen}
                    onConfirm={() => {
                        modalState.onConfirm();
                        setModalState({
                            isOpen: false,
                            onConfirm: () => {
                            },
                            message: "",
                        });
                    }}
                    confirmMessage={modalState.message}
                    onModalClose={clearModalState}
                />
            }
        </React.Fragment>
    );
}

export default RelatedGalleriesSection;
