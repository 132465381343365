import styles from "./GalleryModificationModal.module.css";
import ReactModal from "react-modal";
import cancelIcon from "../../../../images/cancel.svg";
import React from "react";
import {ImagesModalProps} from "./types";

export const ImagesModal = (props: ImagesModalProps): JSX.Element | null => {
    const {
        isOpen,
        onClose,
        dbImages,
        mapDBImages,
        areMoreImagesToFetch,
        fetchDBImages,
        pageFetched,
        IMG_REQUEST_SIZE,
        animateOnClose
    }: ImagesModalProps = props;

    return isOpen ? (
        <div className={styles.overlay}>
            <ReactModal
                className={`${styles.modal} animate__animated ${animateOnClose ? 'animate__fadeOutDownBig' : 'animate__bounceInDown'}`}
                overlayClassName={`${styles.overlay} ${styles.absolute}`}
                isOpen={isOpen}
            >
                <div className={styles['db-images-modal']}>
                    <img
                        onClick={onClose}
                        className={`${styles['action-icon']} ${styles['float-right']}`}
                        src={cancelIcon}
                        alt={'close'}
                    />
                    <div className={styles['db-images-wrapper']}>
                        {dbImages && dbImages.map(mapDBImages)}
                    </div>
                </div>
                {areMoreImagesToFetch && (
                    <button
                        className={`${styles['action-btn']} montserrat-normal-outer-space-17px`}
                        onClick={() => fetchDBImages(pageFetched + 1, IMG_REQUEST_SIZE)}
                    >
                        Зареди още
                    </button>
                )}
            </ReactModal>
        </div>
    ) : null;
};

