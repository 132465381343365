import {
  AddSelectedImagesToVisitedMakeUpServiceAct,
  AddVisitedMakeUpServiceAct,
  ClearMakeUpFromStateAct,
  FetchMakeUpCoursesFailAct,
  FetchMakeUpCoursesReqAct,
  FetchMakeUpCoursesScssAct,
  FetchMakeUpServicesFailAct,
  FetchMakeUpServicesReqAct,
  FetchMakeUpServicesScssAct,
  MakeUpState,
  RemoveGalleryFromVisitedMakeUpServiceAct,
  ReplaceSelectedImagesInVisitedMakeUpServiceAct,
  ReplaceVisitedMakeUpServiceAct,
  UpdateMainImageInVisitedMakeUpServiceAct,
  UpdateMakeUpMainImageAct,
} from './interfaces';
import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { MakeUp, MakeUpFullData } from '../../../interfaces/MakeUp';
import { MakeUpType } from '../../../enums/enums';

const initialState: MakeUpState = {
  makeUpServices: null,
  makeUpCourses: null,
  visitedMakeUpServices: [],
  loading: false,
  fetched: false,
  error: null,
};

const fetchMakeUpServicesReq: CaseReducer<MakeUpState, FetchMakeUpServicesReqAct> = (
  state: MakeUpState,
  action: FetchMakeUpServicesReqAct
) => {
  state.makeUpServices = state.makeUpCourses || null;
  state.loading = true;
  state.fetched = false;
  state.error = null;
};

const fetchMakeUpServicesSuccess: CaseReducer<MakeUpState, FetchMakeUpServicesScssAct> = (
  state: MakeUpState,
  action: FetchMakeUpServicesScssAct
) => {
  state.makeUpServices = action.payload?.makeUpServices || null;
  state.loading = false;
  state.fetched = true;
  state.error = null;
};

const fetchMakeUpServicesFailure: CaseReducer<MakeUpState, FetchMakeUpServicesFailAct> = (
  state: MakeUpState,
  action: FetchMakeUpServicesFailAct
) => {
  state.makeUpServices = null;
  state.loading = false;
  state.fetched = true;
  state.error = action.payload?.error || null;
};

const fetchMakeUpCoursesReq: CaseReducer<MakeUpState, FetchMakeUpCoursesReqAct> = (
  state: MakeUpState,
  action: FetchMakeUpCoursesReqAct
) => {
  state.makeUpCourses = state.makeUpCourses || null;
  state.loading = true;
  state.fetched = false;
  state.error = null;
};

const fetchMakeUpCoursesSuccess: CaseReducer<MakeUpState, FetchMakeUpCoursesScssAct> = (
  state: MakeUpState,
  action: FetchMakeUpCoursesScssAct
) => {
  state.makeUpCourses = action.payload?.makeUpCourses || null;
  state.loading = false;
  state.fetched = true;
  state.error = null;
};

const fetchMakeUpCoursesFailure: CaseReducer<MakeUpState, FetchMakeUpCoursesFailAct> = (
  state: MakeUpState,
  action: FetchMakeUpCoursesFailAct
) => {
  state.makeUpCourses = null;
  state.loading = false;
  state.fetched = true;
  state.error = action.payload?.error || null;
};

const addVisitedMakeUpService: CaseReducer<MakeUpState, AddVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: AddVisitedMakeUpServiceAct
) => {
  state.visitedMakeUpServices.push(action.payload?.makeUpService);
};

const replaceVisitedMakeUpService: CaseReducer<MakeUpState, ReplaceVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: ReplaceVisitedMakeUpServiceAct
) => {
  state.visitedMakeUpServices = state.visitedMakeUpServices.map((makeUp) => {
    if (makeUp.id === action.payload.makeUpService.id) return action.payload?.makeUpService;

    return makeUp;
  });
};

const addSelectedImagesToMakeUpService: CaseReducer<MakeUpState, AddSelectedImagesToVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: AddSelectedImagesToVisitedMakeUpServiceAct
) => {
  const makeUpService: MakeUpFullData | undefined = state.visitedMakeUpServices.find(
    (makeUpService) => makeUpService.id === action.payload?.makeUpId
  );

  if (makeUpService) {
    makeUpService.selectedImages = makeUpService.selectedImages.concat(action.payload.images);

    state.visitedMakeUpServices.filter((makeUpService) => makeUpService.id !== action.payload?.makeUpId).push(makeUpService);
  }
};

const replaceSelectedImagesInMakeUpService: CaseReducer<MakeUpState, ReplaceSelectedImagesInVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: ReplaceSelectedImagesInVisitedMakeUpServiceAct
) => {
  const makeUpService: MakeUpFullData | undefined = state.visitedMakeUpServices.find(
    (makeUpService) => makeUpService.id === action.payload?.makeUpId
  );

  if (makeUpService) {
    makeUpService.selectedImages = action.payload.images;

    state.visitedMakeUpServices.filter((makeUpService) => makeUpService.id !== action.payload?.makeUpId).push(makeUpService);
  }
};

const updateMainImageInVisitedMakeUpService: CaseReducer<MakeUpState, UpdateMainImageInVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: UpdateMainImageInVisitedMakeUpServiceAct
) => {
  const makeUpService: MakeUpFullData | undefined = state.visitedMakeUpServices.find(
    (makeUpService) => makeUpService.id === action.payload?.makeUpId
  );

  if (makeUpService) {
    makeUpService.mainImage.url = action.payload.imageUrl;

    state.visitedMakeUpServices.filter((makeUpService) => makeUpService.id !== action.payload?.makeUpId).push(makeUpService);
  }
};

const updateMakeUpMainImage: CaseReducer<MakeUpState, UpdateMakeUpMainImageAct> = (
  state: MakeUpState,
  action: UpdateMakeUpMainImageAct
) => {
  const makeUpType: MakeUpType = action.payload.makeUpType;

  const makeUp: MakeUp | undefined =
    makeUpType === MakeUpType.SERVICE
      ? state.makeUpServices?.find((makeUp) => makeUp.id === action.payload.makeUpId)
      : state.makeUpCourses?.find((makeUp) => makeUp.id === action.payload.makeUpId);

  if (makeUp) {
    makeUp.mainImageUrl = action.payload.imageUrl;

    makeUpType === MakeUpType.SERVICE
      ? state.makeUpServices?.filter((makeUp) => makeUp.id !== action.payload.makeUpId).push(makeUp)
      : state.makeUpCourses?.filter((makeUp) => makeUp.id !== action.payload.makeUpId).push(makeUp);
  }
};

const removeGalleryFromVisitedMakeUpService: CaseReducer<MakeUpState, RemoveGalleryFromVisitedMakeUpServiceAct> = (
  state: MakeUpState,
  action: RemoveGalleryFromVisitedMakeUpServiceAct
) => {
  const makeUpService: MakeUpFullData | undefined = state.visitedMakeUpServices.find(
    (makeUpService) => makeUpService.id === action.payload?.makeUpId
  );

  if (makeUpService) {
    makeUpService.galleries = makeUpService.galleries.filter((gallery) => gallery.id !== action.payload?.galleryId);

    state.visitedMakeUpServices.filter((makeUpService) => makeUpService.id !== action.payload?.makeUpId).push(makeUpService);
  }
};

const clearMakeUpFromState: CaseReducer<MakeUpState, ClearMakeUpFromStateAct> = (
  state: MakeUpState,
  action: ClearMakeUpFromStateAct
) => {
  const { makeUpId, makeUpType } = action.payload;

  state.visitedMakeUpServices = state.visitedMakeUpServices.filter((makeUpService) => makeUpService.id !== makeUpId);

  makeUpType === MakeUpType.SERVICE
    ? (state.makeUpServices = state.makeUpServices!.filter((makeUpService) => makeUpService.id !== makeUpId))
    : (state.makeUpCourses = state.makeUpCourses!.filter((makeUpService) => makeUpService.id !== makeUpId));
};

export const MakeUpsSlice = createSlice({
  name: 'makeUps',
  initialState,
  reducers: {
    fetchMakeUpServicesReq,
    fetchMakeUpServicesSuccess,
    fetchMakeUpServicesFailure,
    fetchMakeUpCoursesReq,
    fetchMakeUpCoursesSuccess,
    fetchMakeUpCoursesFailure,
    addVisitedMakeUpService,
    replaceVisitedMakeUpService,
    addSelectedImagesToMakeUpService,
    replaceSelectedImagesInMakeUpService,
    updateMainImageInVisitedMakeUpService,
    updateMakeUpMainImage,
    removeGalleryFromVisitedMakeUpService,
    clearMakeUpFromState,
  },
});

export const {
  fetchMakeUpServicesReq: fetchMakeUpServicesReqAction,
  fetchMakeUpServicesSuccess: fetchMakeUpServicesSuccessAction,
  fetchMakeUpServicesFailure: fetchMakeUpServicesFailureAction,
  fetchMakeUpCoursesReq: fetchMakeUpCoursesReqAction,
  fetchMakeUpCoursesSuccess: fetchMakeUpCoursesSuccessAction,
  fetchMakeUpCoursesFailure: fetchMakeUpCoursesFailureAction,
  addVisitedMakeUpService: addVisitedMakeUpServiceAction,
  replaceVisitedMakeUpService: replaceVisitedMakeUpServiceAction,
  addSelectedImagesToMakeUpService: addSelectedImagesToVisitedMakeUpServiceAction,
  replaceSelectedImagesInMakeUpService: replaceSelectedImagesInVisitedMakeUpServiceAction,
  updateMainImageInVisitedMakeUpService: updateMainImageInVisitedMakeUpServiceAction,
  updateMakeUpMainImage: updateMakeUpMainImageAction,
  removeGalleryFromVisitedMakeUpService: removeGalleryFromVisitedMakeUpServiceAction,
  clearMakeUpFromState: clearMakeUpFromStateAction,
} = MakeUpsSlice.actions;
