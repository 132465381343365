import React, {FC, FormEvent, useContext, useEffect, useState} from 'react';
import styles from './Login.module.css';
import showIcon from '../../../images/show-icon.png';
import hideIcon from '../../../images/hide-icon.png';
import useAuthLocale from '../../../pages/AuthPage/AuthLocale';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useLang} from '../../../store/context/lang-context';
import {toast, ToastContainer} from 'react-toastify';
import {AuthProvider, ValidationContext} from '../../../enums/enums';
import {mapAuthData, toastObj, validateEmail, validatePassword} from '../../../utils/utils';
import useAuthActions from '../../../store/redux/auth/useAuthActions';
import {LoginReqBody} from '../interfaces/LoginReqBody';
import {CLIENT_ID, GRANT_TYPE} from '../constants/auth-constants';
import {AuthService} from '../services/authService';
import {catchError} from 'rxjs/operators';
import {finalize, of, Subject, takeUntil, tap} from 'rxjs';
import jwtDecode from 'jwt-decode';
import {KeycloakLoginResBody} from '../interfaces/KeycloakLoginResBody';
import {TokenContext} from '../../../store/redux/auth/enums';
import {useCookies} from 'react-cookie';
import {cookieRememberMe} from '../../../cookies/constants';
import {SESSION_ID} from '../../../common/constants';
import {RememberMeContext} from '../../../store/context/remember-me-context';
import {AuthData} from '../interfaces/AuthData';
import {useAuthLoader} from '../../../store/context/auth-loader';
import useGalleryImages from "../../../store/redux/gallery-images/useGalleryImages";

interface LoginProps {
}

const Login: FC<LoginProps> = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookie, setCookie] = useCookies([cookieRememberMe]);
    const [shouldShowPassword, setShouldShowPassword] = useState(false);

    const {rememberMe} = useContext(RememberMeContext);
    const {lang} = useLang();
    const {setIsLoading} = useAuthLoader();
    const {storeAuthData} = useAuthActions();
    const {clearImageState} = useGalleryImages();

    const locales = useAuthLocale();
    const emailRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const destroy$ = new Subject<void>();
    const location = useLocation();

    useEffect(() => {
        return () => {
            destroy$.next();
            destroy$.complete();
        };
    }, []);

    const onSubmit = (e: FormEvent): void => {
        e.preventDefault();

        const isEmailValid = validateEmail(emailRef, styles, ValidationContext.ON_SUBMIT);
        const isPasswordValid = validatePassword(ValidationContext.ON_SUBMIT, passwordRef, styles);

        if (!isEmailValid || !isPasswordValid) {
            return;
        }

        const loginObj: LoginReqBody = {
            client_id: CLIENT_ID,
            username: emailRef.current!.value,
            password: passwordRef.current!.value,
            grant_type: GRANT_TYPE,
        };

        if (isEmailValid && isPasswordValid) {
            authProviderLogin(loginObj);
        }
    };

    const authProviderLogin = (loginObj: LoginReqBody) => {
        setIsLoading(true);

        AuthService.login(loginObj)
            .pipe(
                tap(({data}) => {
                    const authData: AuthData = mapAuthData(data, AuthProvider.KEYCLOAK, TokenContext.OBTAIN);
                    sessionStorage.setItem(SESSION_ID, jwtDecode<KeycloakLoginResBody>(data.access_token).session_state);

                    const expiresInDays = 10;
                    const expirationDate = new Date();

                    expirationDate.setDate(expirationDate.getDate() + expiresInDays);

                    setCookie(cookieRememberMe, rememberMe, {
                        path: '/',
                        sameSite: 'strict',
                        expires: expirationDate,
                    });

                    storeAuthData(authData, AuthProvider.KEYCLOAK, TokenContext.OBTAIN);

                    const from = location.state?.from.pathname === '/register' || location.state?.from.pathname === '/login'
                        ? {pathname: '/'}
                        : (location.state?.from || {pathname: '/'});

                    navigate(from.pathname, {replace: true});
                }),
                takeUntil(destroy$),
                catchError((error: Error) => {
                    toast.error(locales.LOGIN_ERROR_MSG, toastObj);
                    return of({error: error.message});
                }),
                finalize(() => {
                    clearImageState();
                    setIsLoading(false)
                })
            )
            .subscribe();
    };

    return (
        <div className={styles["login-container"]} data-testid={'Login'}>
            <ToastContainer/>
            <div className={styles['register-sentence']}>
                <p className={'montserrat-medium-outer-space-15px'}>{locales.LOGIN_REGISTRATION_SENTENCE}&nbsp;</p>
                <Link to={`/${lang}/register`}
                      className={`${styles['sign-up-link']} montserrat-medium-outer-space-15px`}>
                    {locales.LOGIN_REGISTRATION_BTN}
                </Link>
            </div>
            <div className={styles['login-form-container']}>
                <form onSubmit={onSubmit} className={styles['login-form']} autoComplete={'true'}>
                    <input
                        ref={emailRef}
                        type="email"
                        name="email"
                        placeholder={`${locales.LOGIN_MAIL}*`}
                        className={`${styles['login-input']} montserrat-medium-outer-space-15px`}
                        onBlur={() => validateEmail(emailRef, styles, ValidationContext.ON_BLUR, locales.LOGIN_EMAIL_ERR_MSG)}
                        onChange={() => validateEmail(emailRef, styles, ValidationContext.ON_SUBMIT)}
                    />
                    <div className={styles['password-wrapper']}>
                        <input
                            ref={passwordRef}
                            type={shouldShowPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={`${locales.LOGIN_PASSWORD}*`}
                            className={`${styles['login-input']} montserrat-medium-outer-space-15px`}
                            onBlur={() => validatePassword(ValidationContext.ON_BLUR, passwordRef, styles)}
                            onChange={() => validatePassword(ValidationContext.ON_CHANGE, passwordRef, styles)}
                        />

                        <img
                            onClick={() => setShouldShowPassword(!shouldShowPassword)}
                            src={shouldShowPassword ? hideIcon : showIcon}
                            alt="show-hide"
                            className={styles['show-hide-icon']}
                        />
                    </div>

                    <button
                        type={'submit'}
                        placeholder={locales.LOGIN_TITLE}
                        className={`${styles['login-submit']} montserrat-medium-outer-space-17px`}
                    >
                        {locales.LOGIN_TITLE}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
