import {Location, NavigateFunction} from 'react-router';
import React from 'react';
import {AuthProvider, ValidationContext} from '../enums/enums';
import {toast} from 'react-toastify';
import validator from 'validator';
import {KeycloakLoginResBody} from '../modules/auth/interfaces/KeycloakLoginResBody';
import {GoogleLoginResBody} from '../modules/auth/interfaces/GoogleLoginResBody';
import {AuthData} from '../modules/auth/interfaces/AuthData';
import jwtDecode from 'jwt-decode';
import {TokenContext} from '../store/redux/auth/enums';
import {WorkingHour} from '../@types/types';
import {DefaultTFuncReturn, t} from "i18next";

export const toastObj: any = {
    position: 'top-center',
    autoClose: 3000,
    theme: 'colored',
};

export function scrollToElement(id: string) {
    const el: HTMLElement | null = document.querySelector(id);
    el && el.scrollIntoView({behavior: 'smooth', block: 'center'});
}

export function debounce(this: any, func: Function, wait: number, immediate: boolean) {
    let timeout: any;

    return function (this: any) {
        let context = this;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context);
        };

        const callNow: boolean = immediate && !timeout;
        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context);
        }
    };
}

export const navigationClickHandler = {
    aboutMeClickHandler: (location: Location, navigator: NavigateFunction, lang: string) => {
        location.pathname.match(/^\/(bg|en)(\/)?$/)
            ? scrollToElement('#about-me')
            : navigator(`/${lang}/about-me`, {state: {from: location}});
    },
    servicesClickHandler: (location: Location, navigator: NavigateFunction, lang: string) => {
        location.pathname.match(/^\/(bg|en)(\/)?$/)
            ? scrollToElement('#home-services')
            : navigator(`/${lang}/services`, {state: {from: location}});
    },
    myWorkClickHandler: (location: Location, navigator: NavigateFunction, lang: string) => {
        location.pathname.match(/^\/(bg|en)(\/)?$/)
            ? scrollToElement('#home-my-work')
            : navigator(`/${lang}/galleries`, {state: {from: location}});
    },
    blogClickHandler: (location: Location, navigator: NavigateFunction, lang: string) => {
        location.pathname.match(/^\/(bg|en)(\/)?$/)
            ? scrollToElement('#home-blog')
            : navigator(`/${lang}/blog`, {state: {from: location}});
    },
};

export function getBgDate(timeStamp: number): string {
    return new Intl.DateTimeFormat('bg-BG', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(timeStamp * 1000);
}

export function getDate(timeStamp: number): Date {
    return new Date(timeStamp * 1000);
}

export const validateDefInput = (
    ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
    styles: React.CSSProperties,
    validationCtx?: ValidationContext,
    msg?: string | null,
    minInputValLength: number = 2
): boolean => {
    let value = ref.current?.value.trim() || '';

    const isValid: boolean = ref.current?.name !== 'postal-code' ? value.length >= minInputValLength : value.length === 4;

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? generalValidatorResolver(isValid, ref, validationCtx, msg!, styles)
        : onChangeValidatorResolver(isValid, ref, styles);

    return isValid;
};

export const validateEmail = (
    emailRef: React.RefObject<HTMLInputElement>,
    styles: React.CSSProperties,
    validationCtx?: ValidationContext,
    msg?: string | null
): boolean => {
    const isValid = validator.isEmail(emailRef.current?.value.trim() || '');

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? generalValidatorResolver(isValid, emailRef, validationCtx, msg!, styles)
        : onChangeValidatorResolver(isValid, emailRef, styles);

    return isValid;
};

export const validatePhone = (phone: string, validationCtx?: ValidationContext, msg?: string): boolean => {
    const isValid = phone.trim().length >= 8;
    const phoneValidatorResolver = {
        phoneValid: () => {
            let inputEl = document.querySelector('#appointment-form .react-tel-input .form-control');
            let countrySelectEl = document.querySelector('#appointment-form .react-tel-input .flag-dropdown');
            // @ts-ignore
            inputEl.style.border = '1px solid var(--bio-green)';
            // @ts-ignore
            countrySelectEl.style.border = '1px solid var(--bio-green)';
        },
        // @ts-ignore
        phoneInvalid: () => {
            let inputEl = document.querySelector('#appointment-form .react-tel-input .form-control');
            let countrySelectEl = document.querySelector('#appointment-form .react-tel-input .flag-dropdown');
            // @ts-ignore
            inputEl.style.border = '1px solid red';
            // @ts-ignore
            countrySelectEl.style.border = '1px solid red';

            validationCtx === ValidationContext.ON_BLUR && toast.error(msg!, toastObj);
        },
    };

    isValid ? phoneValidatorResolver.phoneValid() : phoneValidatorResolver.phoneInvalid();

    return isValid;
};

export const validateStreetNmr = (
    streetNmrRef: React.RefObject<HTMLInputElement>,
    styles: React.CSSProperties,
    validationCtx?: ValidationContext,
    msg?: string | null
): boolean => {
    const isValid = streetNmrRef.current!.value.trim().length >= 1 && streetNmrRef.current!.value.trim() !== '';

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? generalValidatorResolver(isValid, streetNmrRef, validationCtx, msg!, styles)
        : onChangeValidatorResolver(isValid, streetNmrRef, styles);

    return isValid;
};

export const onChangeValidatorResolver = (
    isValid: boolean,
    elementRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
    styles: React.CSSProperties
) => {
    // @ts-ignore
    const hasErrorClass = elementRef.current?.classList.contains(styles['input-error']);
    // @ts-ignore
    hasErrorClass && isValid && elementRef.current?.classList.remove(styles['input-error']);
};

export const generalValidatorResolver = (
    isValid: boolean,
    elementRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
    validationCtx: ValidationContext,
    msg: string,
    styles: React.CSSProperties
) => {
    isValid
        ? // @ts-ignore
        elementRef.current?.classList.remove(styles['input-error'])
        : // @ts-ignore
        elementRef.current?.classList.add(styles['input-error']);

    !isValid && validationCtx === ValidationContext.ON_BLUR && toast.error(msg, toastObj);
};

export const validatePassword = (
    validationCtx: ValidationContext,
    passwordRef: React.RefObject<HTMLInputElement>,
    styles: React.CSSProperties
): boolean => {
    let value = passwordRef.current?.value.trim() || '';
    const isValid = /^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*\W)+)(?!.*\s).{8,}$/.test(value);

    if((validationCtx === ValidationContext.ON_BLUR ||validationCtx === ValidationContext.ON_SUBMIT) && !isValid){
        toast.error(t('PASSWORD_NOT_VALID'), toastObj);
    }

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? isValid
            ? // @ts-ignore
            passwordRef.current?.classList.remove(styles['input-error'])
            : // @ts-ignore
            passwordRef.current?.classList.add(styles['input-error'])
        : onChangeValidatorResolver(isValid, passwordRef, styles);

    return isValid;
};

export const arePasswdsEqual = (
    passwordRef: React.RefObject<HTMLInputElement>,
    confirmPasswordRef: React.RefObject<HTMLInputElement>,
    styles: React.CSSProperties,
    validationCtx: ValidationContext,
    msg?: string
): boolean => {
    const passwd = passwordRef.current!.value.trim();
    const repPasswd = confirmPasswordRef.current!.value.trim();

    const isValid = passwd !== '' && repPasswd !== '' && passwd === repPasswd;

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? generalValidatorResolver(isValid, confirmPasswordRef, validationCtx, msg!, styles)
        : onChangeValidatorResolver(isValid, confirmPasswordRef, styles);

    return isValid;
};

export const validatePriceInput = (
    ref: React.RefObject<HTMLInputElement>,
    styles: React.CSSProperties,
    validationCtx?: ValidationContext,
    msg?: string | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputLength: number = 2
): boolean => {
    let value: string = ref.current?.value || '0';

    const isValid: boolean = parseInt(value) > 0;

    validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
        ? generalValidatorResolver(isValid, ref, validationCtx, msg!, styles)
        : onChangeValidatorResolver(isValid, ref, styles);

    return isValid;
};

export const isValidImageUpload = (
    file: File | null,
    ref: React.RefObject<HTMLButtonElement>,
    styles: React.CSSProperties,
    msg?: string | DefaultTFuncReturn
): boolean => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const allowedSize = 5 * Math.pow(1024, 2); // 5MB

    const isValid = file != null && allowedTypes.includes(file.type) && file.size <= allowedSize;
    if (isValid) {
        // @ts-ignore
        ref.current?.classList.remove(styles['input-error']);
    } else {
        // @ts-ignore
        ref.current?.classList.add(styles['input-error']);
        toast.error(msg!, toastObj);
    }

    return isValid;
};

export const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
};

export const mapAuthData = (
    body: KeycloakLoginResBody | GoogleLoginResBody,
    authProvider: AuthProvider,
    tokenCtx: TokenContext,
    googleRefreshToken?: string
): AuthData => {
    let accessToken = body.access_token;

    const refreshToken: string =
        tokenCtx === TokenContext.REFRESH && authProvider === AuthProvider.GOOGLE ? googleRefreshToken! : body.refresh_token!;

    if ('id_token' in body) {
        accessToken = body.id_token;
    }

    const decodedToken = accessToken && jwtDecode<any>(accessToken);
    let isAdmin: boolean = false;
    const acsExpAt = decodedToken && decodedToken.exp;

    if (authProvider === AuthProvider.KEYCLOAK) {
        isAdmin = decodedToken.realm_access.roles.includes('ADMIN');
    }

    return {
        accessToken,
        refreshToken,
        isAdmin,
        acsExpAt,
    };
};

export const generateWorkingHours = (): WorkingHour[] => {
    const workingHours: WorkingHour[] = [];

    for (let i = 8; i <= 20; i++) {
        const hour = i.toString().padStart(2, '0');
        const minute = '00';
        const minuteHalf = '30';

        workingHours.push(
            {
                id: (i - 8) * 2 + i + 1,
                time: `${hour}:${minute}`,
            },
            {
                id: (i - 9) * 2 + i + 1,
                time: `${hour}:${minuteHalf}`,
            }
        );
    }

    return workingHours;
};

export const calculateAppointmentEndTime = (selectedHour: string, duration: number): string => {
    const [startHour, startMinute] = selectedHour.split(':').map((n) => parseInt(n));

    const durationInMinutes = duration * 60;
    const endMinute = (startMinute + durationInMinutes) % 60;
    const endHour = (startHour + Math.floor((startMinute + durationInMinutes) / 60)) % 24;

    return `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
};

export const formatNameUrlParam = (name: string): string => {
    return name.trim().toLowerCase().replace(/ /g, '-');
};

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
