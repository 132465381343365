import React, { FC } from 'react';
import styles from './Avatar.module.css';

interface AvatarProps {
    src: string;
}

const Avatar: FC<AvatarProps> = (props) => (
  <div className={styles.Avatar} data-testid="Avatar">
      <img referrerPolicy={"no-referrer"} src={props.src} alt={""} className={styles["user-image"]}/>
  </div>
);

export default Avatar;
