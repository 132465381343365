import {useTranslation} from "react-i18next";

export default function TuseServicesPageLocale() {
    const {t} = useTranslation();

    const SERVICES_TITLE = t('SERVICES_TITLE');
    const COURSES_TITLE = t('COURSES_TITLE');
    const SERVICES_SHORT_SENTENCE = t('SERVICES_SHORT_SENTENCE');
    const COURSES_SHORT_SENTENCE = t('COURSES_SHORT_SENTENCE');
    const SERVICES_FORM_TYPE_LABEL = t('SERVICES_FORM_TYPE_LABEL');
    const SERVICES_FORM_TITLE_BG_LABEL = t('SERVICES_FORM_TITLE_BG_LABEL');
    const SERVICES_FORM_TITLE_EN_LABEL = t('SERVICES_FORM_TITLE_EN_LABEL');
    const SERVICES_FORM_SHORT_DESC_BG_LABEL = t('SERVICES_FORM_SHORT_DESC_BG_LABEL');
    const SERVICES_FORM_SHORT_DESC_EN_LABEL = t('SERVICES_FORM_SHORT_DESC_EN_LABEL');
    const SERVICES_FORM_DESC_BG_LABEL = t('SERVICES_FORM_DESC_BG_LABEL');
    const SERVICES_FORM_DESC_EN_LABEL = t('SERVICES_FORM_DESC_EN_LABEL');
    const SERVICES_FORM_PRICE_LABEL = t('SERVICES_FORM_PRICE_LABEL');
    const SERVICES_FORM_IMG_LABEL = t('SERVICES_FORM_IMG_LABEL');
    const SERVICES_FORM_GALLERY_LABEL = t('SERVICES_FORM_GALLERY_LABEL');
    const SERVICES_MAKEUP_LABEL = t('SERVICES_MAKEUP_LABEL');
    const SERVICES_COURSE_LABEL = t('SERVICES_COURSE_LABEL');
    const PRICE_INPUT_VLD_MSG_ERR = t('PRICE_INPUT_VLD_MSG_ERR');
    const ADD_SERVICE_BTN_LABEL = t('ADD_SERVICE_BTN_LABEL');
    const SELECT_VALID_MAIN_IMG = t('SELECT_VALID_MAIN_IMG');
    const SERVICES_STEP_ONE_BG_LABEL = t("SERVICES_STEP_ONE_BG_LABEL");
    const SERVICES_STEP_ONE_EN_LABEL = t("SERVICES_STEP_ONE_EN_LABEL");
    const SERVICES_STEP_TWO_BG_LABEL = t("SERVICES_STEP_TWO_BG_LABEL");
    const SERVICES_STEP_TWO_EN_LABEL = t("SERVICES_STEP_TWO_EN_LABEL");
    const SERVICES_STEP_THREE_BG_LABEL = t("SERVICES_STEP_THREE_BG_LABEL");
    const SERVICES_STEP_THREE_EN_LABEL = t("SERVICES_STEP_THREE_EN_LABEL");
    const SERVICES_REMOVAL_BG_LABEL = t("SERVICES_REMOVAL_BG_LABEL");
    const SERVICES_REMOVAL_EN_LABEL = t("SERVICES_REMOVAL_EN_LABEL");
    const SERVICES_REMOVAL_STEP_ONE_BG_LABEL = t("SERVICES_REMOVAL_STEP_ONE_BG_LABEL");
    const SERVICES_REMOVAL_STEP_ONE_EN_LABEL = t("SERVICES_REMOVAL_STEP_ONE_EN_LABEL");
    const SERVICES_REMOVAL_STEP_TWO_BG_LABEL = t("SERVICES_REMOVAL_STEP_TWO_BG_LABEL");
    const SERVICES_REMOVAL_STEP_TWO_EN_LABEL = t("SERVICES_REMOVAL_STEP_TWO_EN_LABEL");
    const SERVICES_REMOVAL_STEP_THREE_BG_LABEL = t("SERVICES_REMOVAL_STEP_THREE_BG_LABEL");
    const SERVICES_REMOVAL_STEP_THREE_EN_LABEL = t("SERVICES_REMOVAL_STEP_THREE_EN_LABEL");

    return {
        SERVICES_TITLE,
        COURSES_TITLE,
        SERVICES_SHORT_SENTENCE,
        COURSES_SHORT_SENTENCE,
        SERVICES_FORM_TYPE_LABEL,
        SERVICES_FORM_TITLE_BG_LABEL,
        SERVICES_FORM_TITLE_EN_LABEL,
        SERVICES_FORM_SHORT_DESC_BG_LABEL,
        SERVICES_FORM_SHORT_DESC_EN_LABEL,
        SERVICES_FORM_DESC_BG_LABEL,
        SERVICES_FORM_DESC_EN_LABEL,
        SERVICES_FORM_PRICE_LABEL,
        SERVICES_FORM_IMG_LABEL,
        SERVICES_FORM_GALLERY_LABEL,
        SERVICES_MAKEUP_LABEL,
        SERVICES_COURSE_LABEL,
        PRICE_INPUT_VLD_MSG_ERR,
        ADD_SERVICE_BTN_LABEL,
        SELECT_VALID_MAIN_IMG,
        SERVICES_STEP_ONE_BG_LABEL,
        SERVICES_STEP_ONE_EN_LABEL,
        SERVICES_STEP_TWO_BG_LABEL,
        SERVICES_STEP_TWO_EN_LABEL,
        SERVICES_STEP_THREE_BG_LABEL,
        SERVICES_STEP_THREE_EN_LABEL,
        SERVICES_REMOVAL_BG_LABEL,
        SERVICES_REMOVAL_EN_LABEL,
        SERVICES_REMOVAL_STEP_ONE_BG_LABEL,
        SERVICES_REMOVAL_STEP_ONE_EN_LABEL,
        SERVICES_REMOVAL_STEP_TWO_BG_LABEL,
        SERVICES_REMOVAL_STEP_TWO_EN_LABEL,
        SERVICES_REMOVAL_STEP_THREE_BG_LABEL,
        SERVICES_REMOVAL_STEP_THREE_EN_LABEL
    }
}