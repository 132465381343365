import {useDispatch} from "react-redux";
import {addGalleryAction, fetchGalleriesReqAction, updateGalleryAction} from "./reducers";
import {Gallery} from "../../../@types/types";
import {Dispatch} from "@reduxjs/toolkit";

const useGalleriesActions = () => {
    const dispatch: Dispatch = useDispatch();

    const fetchGalleries = () => {
        dispatch(fetchGalleriesReqAction({}));
    }

    const addGallery = (gallery: Gallery) => {
        dispatch(addGalleryAction({
            gallery
        }));
    }

    const updateGallery = (gallery: Gallery) => {
        dispatch(updateGalleryAction({
            gallery
        }));
    }

    return {
        fetchGalleries,
        addGallery,
        updateGallery,
    }
}


export default useGalleriesActions;