import React, {FC, MutableRefObject, useRef, useState} from 'react';
import styles from './ZoomImage.module.css';

interface ZoomImageProps {
    src: string;
}

const ZoomImage: FC<ZoomImageProps> = (props) => {
    const sourceRef = useRef() as MutableRefObject<any>;
    const targetRef = useRef() as MutableRefObject<any>;
    const containerRef = useRef() as MutableRefObject<any>;

    const [opacity, setOpacity] = useState<number>(0);
    const [offset, setOffset] = useState<{ left: number, top: number }>({left: 0, top: 0});

    const handleMouseEnter = (): void => {
        setOpacity(1);
    };

    const handleMouseLeave = (): void => {
        setOpacity(0);
    };

    const handleMouseMove = (e: React.MouseEvent): void => {
        const targetRect: DOMRect = targetRef.current.getBoundingClientRect();
        const sourceRect: DOMRect = sourceRef.current.getBoundingClientRect();
        const containerRect: DOMRect = containerRef.current.getBoundingClientRect();

        const xRatio: number = (targetRect.width - containerRect.width) / sourceRect.width;
        const yRatio: number = (targetRect.height - containerRect.height) / sourceRect.height;

        const minX: number = Math.min(e.clientX - sourceRect.left, sourceRect.width);
        const minY: number = Math.min(e.clientY - sourceRect.top, sourceRect.height);

        const left: number = Math.max(minX, 0);
        const top: number = Math.max(minY, 0);

        const leftPos: number = left * -xRatio;
        const topPos: number = (top * -yRatio);

        setOffset({
            left: leftPos,
            top: topPos
        });
    };

    return (
        <div className={styles["zoom-wrapper"]}
             ref={containerRef}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onMouseMove={handleMouseMove}>
            <img ref={sourceRef}
                 src={props.src}
                 className={styles["main-img"]}
                 alt="test"/>
            <img style={{
                position: 'absolute',
                opacity: opacity,
                left: `${offset.left}px`,
                top: `${offset.top}px`,
                width: '210%',
            }}
                 className={styles["zoom-img"]}
                 src={props.src}
                 ref={targetRef}
                 alt="target"/>
        </div>
    );
}

export default ZoomImage;
