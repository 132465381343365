import React, {FC, useState} from "react";
import {Image} from "../../../../interfaces/Image";
import redHeart from "../../../../images/heart-red.png";
import whiteHeart from "../../../../images/heart-white.png";
import {toast} from "react-toastify";
import {t} from "i18next";
import deleteIcon from '../../../../images/delete-red.png';
import MiniLoader from "../../../shared/components/Loader/MiniLoader";

type ImageWithLoaderProps = {
    image: Image;
    onClick: (event: any) => void;
    isUserAuthenticated: boolean;
    likeImage: (id: string) => void;
    isUserAdmin: boolean | undefined
    onDelete: (imageId: string) => void;
    styles: any;
}

const ImageWithLoader: FC<ImageWithLoaderProps> = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const {image, onClick, isUserAuthenticated, likeImage, isUserAdmin, onDelete, styles} = props;

    return (
        <div className={styles["image-wrapper"]}>
            {!isLoaded && <MiniLoader/>}
            <img
                style={{display: isLoaded ? 'block' : 'none'}}
                onClick={onClick}
                className={styles.img}
                src={image.url}
                alt=""
                onLoad={() => setIsLoaded(true)}
            />

            <div
                style={{display: isLoaded ? 'block' : 'none'}}
                className={styles["like-wrapper"]}>
                <img
                    className={styles.heart}
                    src={image.hasCurrentUserLiked ? redHeart : whiteHeart}
                    alt="heart"
                    onClick={() => {
                        if (isUserAuthenticated) {
                            likeImage(image.id);
                            return;
                        }

                        toast.error(t('IMG_LIKE_NOT_LOGGED_IN_ERR'));
                    }}
                />
                <p className={styles["likes-count"]}>{image.likesCount}</p>
            </div>

            {
                isUserAdmin &&
                <img
                    className={styles["delete-icon"]}
                    src={deleteIcon}
                    alt={"delete"}
                    onClick={() => onDelete(image.id)}
                />
            }
        </div>
    );
}

export default ImageWithLoader;


