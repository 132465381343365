import { FC } from 'react';
import logo from '../../images/LogoBlagaD-blank 150x150-crop.png';
import useWindowDimensions from '../../hooks/screen-resolution-checker';
import { t } from 'i18next';

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        padding: '50px',
      }}
      className="montserrat-normal-mine-shaft-17px"
    >
      <img
        style={{
          width: '150px',
          height: '150px',
        }}
        src={logo}
        alt="logo"
      />
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p
          style={{
            padding: '20px',
          }}
          className="montserrat-normal-outer-space-17px"
        >
          {t('ERROR_PAGE_CONTENT')}
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
