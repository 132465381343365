import {createContext, useContext} from 'react';

export type RememberMeContextType = {
    rememberMe: boolean;
    setRememberMe: (rememberMe: boolean) => void;
}

export const RememberMeContext = createContext<RememberMeContextType>({
    rememberMe:false, setRememberMe: rememberMe => console.warn('no theme provider')
});

export const useRememberMe = () => useContext(RememberMeContext);