import React, {FC} from 'react';
import styles from './SectionHeading.module.css';
import leftQmark from '../../../../images/path-88@2x.svg';
import rightQmark from '../../../../images/path-89@2x.svg';

interface SectionHeadingProps {
    title: string | undefined;
    sentence: string | undefined;
    flowerSrc: string | undefined;
}

const SectionHeading: FC<SectionHeadingProps> = (props) => (
    <div className={styles.SectionHeading} data-testid="SectionHeading">
        <h2 className={"valign-text-middle monotypecorsiva-regular-normal-outer-space-40px"}>{props.title}</h2>
        <img className={styles.flower} src={props.flowerSrc} alt={"flower"}/>
        {
            props.sentence &&
            <React.Fragment>
                <img className={styles["left-q-mark"]} src={leftQmark} alt={"leftQmark"}/>
                <p className={`${styles.sentence} montserrat-normal-outer-space-17px`}>
                    {props.sentence}
                </p>
                <img className={styles["right-q-mark"]} src={rightQmark} alt={"rightQmark"}/>
            </React.Fragment>
        }
    </div>
);

export default SectionHeading;
