import {combineEpics, createEpicMiddleware} from 'redux-observable';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import {encryptTransform} from 'redux-persist-transform-encrypt';
//@ts-ignore
import {CookieStorage} from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import {Persistor} from 'redux-persist/es/types';
import {UserStateSlice} from '../auth/authReducers';
import {fetchUserEpic, loginEpic, logoutEpic} from '../auth/epics';
import {ToolkitStore} from '@reduxjs/toolkit/dist/configureStore';
import {UserAppointmentsSlice} from '../appointments/appointmentReducers';
import {appointmentsEpic, clearAppointmentsEpic} from '../appointments/epics';
import {AdminAppointmentsSlice} from '../admin-appointments/reducers';
import {
    clearAdminAppointmentsEpic,
    fetchAdminAppointmentsHistoryEpic,
    fetchAppointmentsInquiriesEpic,
    fetchIncomingAppointmentsEpic,
} from '../admin-appointments/epics';
import {MakeUpsSlice} from '../make-ups/reducers';
import {fetchMakeUpCoursesEpic, fetchMakeUpServicesEpic} from '../make-ups/epics';
import {fetchGalleriesEpic} from '../galleries/epics';
import {GalleriesSlice} from '../galleries/reducers';
import {GalleryImagesSlice} from "../gallery-images/reducers";
import {fetchGalleryImagesEpic} from "../gallery-images/epics";
import {
    approveReviewEpic,
    deleteReviewEpic,
    fetchApprovedReviewsEpic,
    fetchUnapprovedReviewsEpic
} from "../reviews/epics";
import {ReviewsSlice} from "../reviews/reducers";

const storeKey = process.env.REACT_APP_STORE_KEY;

const persistConfig = {
    key: 'root',
    whitelist: ['xxfh'],
    storage: new CookieStorage(Cookies, {
        sameSite: 'strict',
        keyPrefix: 'xxfh',
        maxAge: 86400 * 10, // 10 days in seconds,
        expiration: {
            default: 86400 * 10, // 10 days in seconds,
        },
    }),
    transforms: [
        encryptTransform({
            secretKey: storeKey!,
            onError: function (error) {
                console.error(error);
            },
        }),
    ],
};

const authData = UserStateSlice.reducer;
const appointmentsData = UserAppointmentsSlice.reducer;
const adminAppointments = AdminAppointmentsSlice.reducer;
const makeUps = MakeUpsSlice.reducer;
const galleries = GalleriesSlice.reducer;
const galleryImages = GalleryImagesSlice.reducer;
const reviews = ReviewsSlice.reducer;

const persistedAuthData = persistReducer(persistConfig, authData);

const rootReducer = combineReducers({
    authData,
    appointmentsData,
    adminAppointments,
    makeUps,
    galleriesData: galleries,
    galleryImages,
    persistedAuthData,
    reviews
});

const rootEpic = combineEpics(
    loginEpic,
    fetchUserEpic,
    logoutEpic,
    appointmentsEpic,
    clearAppointmentsEpic,
    fetchIncomingAppointmentsEpic,
    fetchAppointmentsInquiriesEpic,
    fetchAdminAppointmentsHistoryEpic,
    clearAdminAppointmentsEpic,
    fetchMakeUpServicesEpic,
    fetchMakeUpCoursesEpic,
    fetchGalleriesEpic,
    fetchGalleryImagesEpic,
    fetchApprovedReviewsEpic,
    fetchUnapprovedReviewsEpic,
    approveReviewEpic,
    deleteReviewEpic
);

export const ConfigureStore = () => {
    const epicMiddleware = createEpicMiddleware();
    const store: ToolkitStore = configureStore({
        reducer: rootReducer,
        middleware: [epicMiddleware],
        devTools: process.env.NODE_ENV !== 'production',
    });

    let persistor: Persistor = persistStore(store);

    epicMiddleware.run(rootEpic);

    return {
        store,
        persistor,
    };
};
