import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './ServiceDetails.module.css';
import ServicePriceSection from '../../modules/service-details-module/components/ServicePriceSection/ServicePriceSection';
import ServiceInfoSection from '../../modules/service-details-module/components/ServiceInfoSection/ServiceInfoSection';
import MakeUpSelectedImages from '../../modules/service-details-module/components/MakeUpSelectedImages/MakeUpSelectedImages';
import RelatedGalleriesSection from '../../modules/service-details-module/components/RelatedGalleriesSection/RelatedGalleriesSection';
import AppointmentSection from '../../modules/service-details-module/components/AppointmentSection/AppointmentSection';
import useWindowDimensions from '../../hooks/screen-resolution-checker';
import RelatedGalleriesSectionMobile from '../../modules/service-details-module/components/RelatedGalleriesSectionMobile/RelatedGalleriesSectionMobile';
import pencil from '../../images/pencil.png';
import deleteInRecycleBin from '../../images/delete-orange.png';
import deletePermanent from '../../images/delete-red.png';
import ConfirmModal from '../../modules/shared/components/ConfirmModal/ConfirmModal';
import { MakeUpFullData } from '../../interfaces/MakeUp';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MakeUpService from '../../modules/shared/services/MakeUpService';
import { of, Subject, takeUntil, tap } from 'rxjs';
import { useMakeUpActions } from '../../store/redux/make-ups/useMakeUps';
import { catchError, finalize } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { toastObj } from '../../utils/utils';
import { RootState } from '../../store/redux/store-config/globalStoreConfig';
import MakeUpModificationModal, {
  MakeUpModificationContext,
} from '../../modules/shared/components/MakeUpModificationModal/MakeUpModificationModal';

interface ServiceDetailsProps {}

interface ConfirmModalState {
  isModalOpen: boolean;
  modalMessage: string;
  permanent: boolean;
}

enum ClickAction {
  EDIT = 'edit',
  DELETE = 'delete',
  DELETE_PERMANENT = 'delete-permanent',
}

const ServiceDetails: FC<ServiceDetailsProps> = () => {
  const [makeUp, setMakeUp] = useState<MakeUpFullData | null>(null);
  const [isMakeUpModificationModalOpen, setIsMakeUpModificationModalOpen] = React.useState(false);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({
    isModalOpen: false,
    modalMessage: '',
    permanent: false,
  });

  const { id } = useParams();
  const { addVisitedMakeUpService, clearMakeUpFromState } = useMakeUpActions();

  const visitedMakeUps: MakeUpFullData[] = useSelector((state: any) => state.makeUps.visitedMakeUpServices);
  const isUsrAdmin: boolean | null | undefined = useSelector((state: RootState) => state.persistedAuthData?.xxfh?.authData.isAdmin);

  const destroy$ = new Subject<void>();

  const fetchMakeUp = useCallback(() => {
    !!id &&
      MakeUpService.getMakeUpById(id)
        .pipe(
          tap((res) => {
            if (res.status !== 200) {
              throw new Error('error while fetching makeUp');
            }

            setMakeUp(res.data);
            addVisitedMakeUpService(res.data);
          }),
          catchError((err) => {
            toast.error(err.message, toastObj);

            return of(err);
          }),
          takeUntil(destroy$)
        )
        .subscribe();
  }, [id]);

  useEffect(() => {
    const currentMakeUp = visitedMakeUps?.find((makeUp: MakeUpFullData) => makeUp.id === id);

    if (currentMakeUp) {
      setMakeUp(currentMakeUp);
    } else {
      fetchMakeUp();
    }

    return () => {
      destroy$.next();
      destroy$.complete();
    };
  }, [visitedMakeUps]);

  const actionClickHandler = (e: React.MouseEvent<HTMLImageElement>, modalMessage: string, action: ClickAction) => {
    e.preventDefault();

    const actionDeterminator: any = {
      [ClickAction.EDIT]: () => editMakeUp(modalMessage),
      [ClickAction.DELETE]: () => deleteMakeUp(modalMessage),
      [ClickAction.DELETE_PERMANENT]: () => deleteMakeUpPermanent(modalMessage),
    };

    actionDeterminator[action]();
  };

  const editMakeUp = (modalMsg: string) => {
    if (!isUsrAdmin) {
      return;
    }

    setIsMakeUpModificationModalOpen(true);
  };

  const deleteMakeUp = (modalMsg: string) => {
    setConfirmModalState({
      isModalOpen: true,
      modalMessage: modalMsg,
      permanent: false,
    });
  };

  const deleteMakeUpPermanent = (modalMsg: string) => {
    setConfirmModalState({
      isModalOpen: true,
      modalMessage: modalMsg,
      permanent: true,
    });
  };

  const deleteMakeUpService = (permanent: boolean) => {
    !!id &&
      MakeUpService.deleteMakeUp(id, permanent)
        .pipe(
          tap((res) => {
            if (res.status !== 200) throw new Error('error while deleting makeUp');
            clearMakeUpFromState(id, makeUp!.type);
          }),
          takeUntil(destroy$),
          finalize(() => {
            setConfirmModalState({ isModalOpen: false, modalMessage: '', permanent: false });
            toast.success('Услугата беше изтрита успешно', toastObj);
          })
        )
        .subscribe();
  };

  return (
    <section className={styles.ServiceDetails} data-testid="ServiceDetails">
      {isUsrAdmin && (
        <div onClick={(e) => e.preventDefault()} className={styles['edit-delete-wrapper']}>
          <img onClick={(e) => actionClickHandler(e, 'Edit', ClickAction.EDIT)} src={pencil} alt="pencil" />
          <img
            onClick={(e) => actionClickHandler(e, 'Сигурнили сте че искате да преместите услугата в кошчето?', ClickAction.DELETE)}
            src={deleteInRecycleBin}
            alt="deleteInRecycleBin"
          />
          <img
            onClick={(e) =>
              actionClickHandler(e, 'Сигурнили сте че искате да изтриете услугата завинаги?', ClickAction.DELETE_PERMANENT)
            }
            src={deletePermanent}
            alt="deletePermanent"
          />
          <ConfirmModal
            isOpen={confirmModalState.isModalOpen}
            onConfirm={() => {
              deleteMakeUpService(confirmModalState.permanent);
            }}
            confirmMessage={confirmModalState.modalMessage}
            onModalClose={() => setConfirmModalState({ isModalOpen: false, modalMessage: '', permanent: false })}
          />
          {isMakeUpModificationModalOpen && (
            <MakeUpModificationModal
              isModalOpen={isMakeUpModificationModalOpen}
              context={MakeUpModificationContext.EDIT}
              onClose={() => setIsMakeUpModificationModalOpen(false)}
              onSuccessClose={() => {
                setIsMakeUpModificationModalOpen(false);
                toast.success('Успешно редактирахте услугата', toastObj);
              }}
              makeUpFullData={makeUp}
            />
          )}
        </div>
      )}

      <ServiceInfoSection makeUp={makeUp} isUsrAdmin={isUsrAdmin} />
      <ServicePriceSection price={makeUp?.price} />
      <MakeUpSelectedImages selectedImages={makeUp?.selectedImages} isUsrAdmin={isUsrAdmin} makeUpId={makeUp?.id} />
      {useWindowDimensions().width > 1024 ? (
        <RelatedGalleriesSection makeUp={makeUp} isUsrAdmin={isUsrAdmin} />
      ) : (
        <RelatedGalleriesSectionMobile makeUp={makeUp} isUsrAdmin={isUsrAdmin} />
      )}
      <AppointmentSection />
    </section>
  );
};

export default ServiceDetails;
