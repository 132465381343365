import React, { FC, useEffect, useState } from 'react';
import styles from './Services.module.css';
import flower from '../../images/group-1-1@2x.svg';
import SectionHeading from '../../modules/shared/components/SectionHeading/SectionHeading';
import ServiceBox from '../../modules/shared/components/ServiceBox/ServiceBox';
import { useLocation } from 'react-router-dom';
import useServicesPageLocale from './ServicesPageLocale';
import { useLang } from '../../store/context/lang-context';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/redux/store-config/globalStoreConfig';
import { AuthData } from '../../modules/auth/interfaces/AuthData';
import { Gallery } from '../../@types/types';
import { toast, ToastContainer } from 'react-toastify';
import MakeUpModificationModal, {
  MakeUpModificationContext,
} from '../../modules/shared/components/MakeUpModificationModal/MakeUpModificationModal';
import useGalleriesActions from '../../store/redux/galleries/useGalleries';
import { GalleriesState } from '../../store/redux/galleries/interfaces';
import { MakeUp } from '../../interfaces/MakeUp';
import { MakeUpState } from '../../store/redux/make-ups/interfaces';
import { useMakeUpActions } from '../../store/redux/make-ups/useMakeUps';
import { toastObj } from '../../utils/utils';
import {t} from "i18next";

interface ServicesProps {}

enum PageContext {
  SERVICES = 'SERVICES',
  COURSES = 'COURSES',
}

const Services: FC<ServicesProps> = () => {
  const [urlPath, setUrlPath] = useState('');
  const [pageContext, setPageContext] = useState<PageContext | null>(null);
  const [galleries, setGalleries] = useState<Gallery[] | null>(null);
  const [makeUps, setMakeUps] = useState<MakeUp[] | null>(null);
  const [courses, setCourses] = useState<MakeUp[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { lang } = useLang();
  const location = useLocation();
  const locales = useServicesPageLocale();
  const { fetchGalleries } = useGalleriesActions();
  const { fetchMakeUpCourses, fetchMakeUpServices } = useMakeUpActions();

  const authData: AuthData = useSelector((state: RootState) => state.persistedAuthData?.xxfh?.authData);
  const galleriesData: GalleriesState = useSelector((state: RootState) => state.galleriesData);
  const makeUpsData: MakeUpState = useSelector((state: RootState) => state.makeUps);

  useEffect(() => {
    if (pageContext === PageContext.SERVICES) {
      if (!makeUpsData?.makeUpServices && makeUpsData?.loading === false && makeUpsData?.error === null) {
        fetchMakeUpServices(50, 1);
      }

      setMakeUps(makeUpsData.makeUpServices);
    }

    if (pageContext === PageContext.COURSES) {
      if (!makeUpsData?.makeUpCourses && makeUpsData?.loading === false && makeUpsData?.error === null) {
        fetchMakeUpCourses(50, 1);
      }

      setCourses(makeUpsData.makeUpCourses);
    }
  }, [makeUpsData, pageContext]);

  useEffect(() => {
    const getPageContext = (): PageContext | null => {
      return urlPath === `/${lang}/services` ? PageContext.SERVICES : urlPath === `/${lang}/courses` ? PageContext.COURSES : null;
    };

    setPageContext(getPageContext());
  }, [lang, urlPath]);

  useEffect(() => {
    return setUrlPath(location.pathname);
  }, [location.pathname, urlPath]);

  useEffect(() => {
    if (authData?.isAdmin && !galleriesData?.galleries && galleriesData?.loading === false) {
      fetchGalleries();
      return;
    }

    setGalleries(galleriesData.galleries);
  }, [galleriesData]);

  const mapMakeUpElements = (makeUps: MakeUp[] | null) => {
    return (
      makeUps &&
      makeUps.map((makeUp, index) => (
        <div className={styles.item} key={makeUp.id}>
          <ServiceBox makeUp={makeUp} isAdmin={authData?.isAdmin} />
        </div>
      ))
    );
  };

  return (
    <section className={styles.Services} data-testid="Services">
      <ToastContainer />
      <div className={styles['info-wrapper']}>
        <SectionHeading
          title={pageContext === PageContext.SERVICES ? locales.SERVICES_TITLE : locales.COURSES_TITLE}
          sentence={pageContext === PageContext.SERVICES ? locales.SERVICES_SHORT_SENTENCE : locales.COURSES_SHORT_SENTENCE}
          flowerSrc={flower}
        />
      </div>
      {
          courses && !courses.length && pageContext === PageContext.COURSES &&
          ( <p style={{
            marginTop: '70px',
            textAlign: 'center'
          }} className={'montserrat-normal-outer-space-17px'}>{t('NO_COURSES')}</p>)
      }

      <div className={styles['services-wrapper']}>
        {pageContext === PageContext.SERVICES ? mapMakeUpElements(makeUps) : mapMakeUpElements(courses)}
      </div>

      {authData?.isAdmin && (
        <div className={styles['add-service-wrapper']}>
          <button
            onClick={() => {
              setIsModalOpen(true);
              window.scrollTo(0, 0);
            }}
            className={`${styles['add-service-button']} montserrat-normal-outer-space-17px`}
          >
            {locales.ADD_SERVICE_BTN_LABEL}
          </button>

          <MakeUpModificationModal
            isModalOpen={isModalOpen}
            context={MakeUpModificationContext.CREATE}
            galleries={galleries}
            onClose={() => {
              setIsModalOpen(false);
            }}
            onSuccessClose={() => {
              setIsModalOpen(false);
              toast.success('Успешно добавихте услуга!', toastObj);
            }}
          />
        </div>
      )}
    </section>
  );
};

export default Services;
