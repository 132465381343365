import {
    AdminAppointmentsState, ClearAdminAppointmentsAct,
    FetchAppointmentHistoryFailAct,
    FetchAppointmentHistoryReqAct,
    FetchAppointmentHistoryScssAct,
    FetchAppointmentInquiriesFailAct,
    FetchAppointmentInquiriesReqAct,
    FetchAppointmentInquiriesScssAct,
    FetchIncomingAppointmentsFailAct,
    FetchIncomingAppointmentsReqAct,
    FetchIncomingAppointmentsScssAct
} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";

const initialState: AdminAppointmentsState = {
    incomingAppointments: null,
    appointmentInquiries: null,
    appointmentHistory: null,
    loading: false,
    fetched: false,
    error: null,
}

const fetchIncomingAppointmentsReq: CaseReducer<AdminAppointmentsState, FetchIncomingAppointmentsReqAct> = (state: AdminAppointmentsState, action: FetchIncomingAppointmentsReqAct) => {
    state.incomingAppointments = state.incomingAppointments || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchIncomingAppointmentsSuccess: CaseReducer<AdminAppointmentsState, FetchIncomingAppointmentsScssAct> = (state: AdminAppointmentsState, action: FetchIncomingAppointmentsScssAct) => {
    state.incomingAppointments = action.payload?.incomingAppointments || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchIncomingAppointmentsFailure: CaseReducer<AdminAppointmentsState, FetchIncomingAppointmentsFailAct> = (state: AdminAppointmentsState, action: FetchIncomingAppointmentsFailAct) => {
    state.incomingAppointments = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload?.error || null;
}

const fetchAppointmentInquiriesReq: CaseReducer<AdminAppointmentsState, FetchAppointmentInquiriesReqAct> = (state: AdminAppointmentsState, action: FetchAppointmentInquiriesReqAct) => {
    state.appointmentInquiries = state.appointmentInquiries || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchAppointmentInquiriesSuccess: CaseReducer<AdminAppointmentsState, FetchAppointmentInquiriesScssAct> = (state: AdminAppointmentsState, action: FetchAppointmentInquiriesScssAct) => {
    state.appointmentInquiries = action.payload?.appointmentInquiries || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchAppointmentInquiriesFailure: CaseReducer<AdminAppointmentsState, FetchAppointmentInquiriesFailAct> = (state: AdminAppointmentsState, action: FetchAppointmentInquiriesFailAct) => {
    state.appointmentInquiries = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload?.error || null;
}

const fetchAppointmentHistoryReq: CaseReducer<AdminAppointmentsState, FetchAppointmentHistoryReqAct> = (state: AdminAppointmentsState, action: FetchAppointmentHistoryReqAct) => {
    state.appointmentHistory = state.appointmentHistory || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchAppointmentHistorySuccess: CaseReducer<AdminAppointmentsState, FetchAppointmentHistoryScssAct> = (state: AdminAppointmentsState, action: FetchAppointmentHistoryScssAct) => {
    state.appointmentHistory = action.payload?.appointmentHistory || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchAppointmentHistoryFailure: CaseReducer<AdminAppointmentsState, FetchAppointmentHistoryFailAct> = (state: AdminAppointmentsState, action: FetchAppointmentHistoryFailAct) => {
    state.appointmentHistory = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload?.error || null;
}

const clearAdminAppointmentsState: CaseReducer<AdminAppointmentsState, ClearAdminAppointmentsAct> = (state: AdminAppointmentsState, action) => {
    state.incomingAppointments = null;
    state.appointmentInquiries = null;
    state.appointmentHistory = null;
    state.loading = false;
    state.fetched = false;
    state.error = null;
}


export const AdminAppointmentsSlice = createSlice({
    name: "adminAppointments",
    initialState,
    reducers: {
        fetchIncomingAppointmentsReq,
        fetchIncomingAppointmentsSuccess,
        fetchIncomingAppointmentsFailure,
        fetchAppointmentInquiriesReq,
        fetchAppointmentInquiriesSuccess,
        fetchAppointmentInquiriesFailure,
        fetchAppointmentHistoryReq,
        fetchAppointmentHistorySuccess,
        fetchAppointmentHistoryFailure,
        clearAdminAppointmentsState
    }
});

export const {
    fetchIncomingAppointmentsReq: incomingAppointmentsReqAction,
    fetchIncomingAppointmentsSuccess: incomingAppointmentsSuccessAction,
    fetchIncomingAppointmentsFailure: incomingAppointmentsFailureAction,
    fetchAppointmentInquiriesReq: appointmentInquiriesReqAction,
    fetchAppointmentInquiriesSuccess: appointmentInquiriesSuccessAction,
    fetchAppointmentInquiriesFailure: appointmentInquiriesFailureAction,
    fetchAppointmentHistoryReq: appointmentHistoryReqAction,
    fetchAppointmentHistorySuccess: appointmentHistorySuccessAction,
    fetchAppointmentHistoryFailure: appointmentHistoryFailureAction,
    clearAdminAppointmentsState: clearAdminAppointmentsStateAction
} = AdminAppointmentsSlice.actions;
