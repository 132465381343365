import React, {FC} from 'react';
import styles from './Footer.module.css';
import phoneIcon from '../../../../images/phone-icon-1@2x.png';
import instagramIcon from '../../../../images/instagram-icon-2@2x.png';
import facebookIcon from '../../../../images/facebook-icon@2x.png';
import {Link} from "react-router-dom";
import useFooterLocale from "./FooterLocale";
import {useLang} from "../../../../store/context/lang-context";

interface FooterProps {
}


const Footer: FC<FooterProps> = () => {
    const locales = useFooterLocale();
    const {lang} = useLang();
    const phone = process.env.REACT_APP_CONTACT_PHONE_NUMBER;
    function redirect(to: string) {
        let url = to === 'instagram'
            ?
            locales.INSTAGRAM_LINK ? locales.INSTAGRAM_LINK : 'https://instagram.com'
            :
            locales.FACEBOOK_LINK ? locales.FACEBOOK_LINK : 'https://facebook.com';

        window.open(url);
    }

    return (
        <footer className={styles.Footer} data-testid="Footer">
            <div className={styles.wrapper}>
                <div className={styles["first-section"]}>
                    <h2 className={`${styles["first-section_title"]} montserrat-normal-midnight-20px`}>{locales.FIRST_SECTION_TITLE}</h2>
                    <p className={`${styles["first-section_sentence"]} montserrat-normal-midnight-17px`}>{locales.FIRST_SECTION_SENTENCE}</p>
                    <form className={styles["input-wrapper"]}>
                        <input placeholder={locales.FIRST_SECTION_EMAIL_SENTENCE} className={styles.input}/>
                        <button onClick={event => event.preventDefault()}
                                className={`${styles["send"]} montserrat-normal-midnight-17px`}>{locales.SENT_BTN_LABEL}</button>
                    </form>
                </div>
                <div className={styles["second-section"]}>
                    <h2 className={`${styles["second-section_title"]} montserrat-normal-midnight-20px`}>{locales.SECOND_SECTION_TITLE}</h2>
                    <p className={`${styles["second-section_sentence"]} montserrat-normal-midnight-17px`}>{locales.SECOND_SECTION_SENTENCE}</p>
                    <div className={styles["phone-wrapper"]}>
                        <img className={styles["phone-img"]} src={phoneIcon} alt="phone-icon"/>
                        <p className={"montserrat-normal-midnight-20px"}>{phone}</p>
                    </div>
                </div>
                <div className={styles["third-section"]}>
                    <h2 className={`${styles["third-section_title"]} montserrat-normal-midnight-20px`}>{locales.SOCIAL_NETWORKS_SENTENCE}</h2>
                    <div className={styles["social-networks"]}>

                        <img onClick={() => redirect('instagram')}
                             className={styles["social-networks_img"]}
                             src={instagramIcon}
                             alt="instagram-icon"/>

                        <img onClick={() => redirect('facebook')}
                             className={styles["social-networks_img"]}
                             src={facebookIcon}
                             alt="facebook-icon"/>
                    </div>
                </div>
            </div>
            <hr className={styles.hr}/>
            <div className={styles["bottom-section"]}>
                <div className={styles["rights-wrapper"]}>
                    <p className={`${styles["bottom-section_sentence"]} montserrat-normal-midnight-17px`}>{locales.DOMAIN}&copy;</p>
                    <p className={`${styles["bottom-section_sentence"]} montserrat-normal-midnight-17px`}>{locales.ALL_RIGHTS_RESERVED}</p>
                </div>
                <div className={styles["bottom-section_terms"]}>
                    <Link to={`/${lang}/terms-and-conditions`}
                          className={`${styles["bottom-section_terms_sentence"]} montserrat-normal-midnight-17px`}>{locales.PRIVACY_POLICY}</Link>
                    <p className={`${styles["bottom-section_terms_sentence"]} ${styles.pipe} montserrat-normal-midnight-17px`}>|</p>
                    <Link to={`/${lang}/privacy-policy`}
                          className={`${styles["bottom-section_terms_sentence"]} montserrat-normal-midnight-17px`}>{locales.GENERAL_TERMS}</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
