import {
    ApproveReviewFailAct,
    ApproveReviewReqAct, ApproveReviewScssAct, DeleteReviewFailAct, DeleteReviewReqAct, DeleteReviewScssAct,
    FetchApprovedReviewsFailAct,
    FetchApprovedReviewsReqAct,
    FetchApprovedReviewsScssAct,
    FetchUnapprovedReviewsFailAct,
    FetchUnapprovedReviewsReqAct,
    FetchUnapprovedReviewsScssAct,
    ReviewsState
} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";

const initialState: ReviewsState = {
    approvedReviews: null,
    unapprovedReviews: null,
    loading: false,
    fetched: false,
    error: null,
}

const fetchApprovedReviewsReq: CaseReducer<ReviewsState, FetchApprovedReviewsReqAct> = (state: ReviewsState, action: FetchApprovedReviewsReqAct) => {
    state.approvedReviews = state.approvedReviews || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchApprovedReviewsScss: CaseReducer<ReviewsState, FetchApprovedReviewsScssAct> = (state: ReviewsState, action: FetchApprovedReviewsScssAct) => {
    state.approvedReviews = action.payload.approvedReviews || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchApprovedReviewsFail: CaseReducer<ReviewsState, FetchApprovedReviewsFailAct> = (state: ReviewsState, action: FetchApprovedReviewsFailAct) => {
    state.approvedReviews = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error || null;
}

const fetchUnapprovedReviewsReq: CaseReducer<ReviewsState, FetchUnapprovedReviewsReqAct> = (state: ReviewsState, action: FetchUnapprovedReviewsReqAct) => {
    state.unapprovedReviews = state.unapprovedReviews || null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchUnapprovedReviewsScss: CaseReducer<ReviewsState, FetchUnapprovedReviewsScssAct> = (state: ReviewsState, action: FetchUnapprovedReviewsScssAct) => {
    state.unapprovedReviews = action.payload.unapprovedReviews || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchUnapprovedReviewsFail: CaseReducer<ReviewsState, FetchUnapprovedReviewsFailAct> = (state: ReviewsState, action: FetchUnapprovedReviewsFailAct) => {
    state.unapprovedReviews = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error || null;
}

const approveReviewReq: CaseReducer<ReviewsState, ApproveReviewReqAct> = (state: ReviewsState, action: ApproveReviewReqAct) => {
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const approveReviewScss: CaseReducer<ReviewsState, ApproveReviewScssAct> = (state: ReviewsState, action: ApproveReviewScssAct) => {
    state.approvedReviews = [...state.approvedReviews || [], action.payload.review];
    state.unapprovedReviews = state.unapprovedReviews?.filter(review => review.id !== action.payload.review.id) || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const approveReviewFail: CaseReducer<ReviewsState, ApproveReviewFailAct> = (state: ReviewsState, action: ApproveReviewFailAct) => {
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error || null;
}

const deleteReviewReq: CaseReducer<ReviewsState, DeleteReviewReqAct> = (state: ReviewsState, action: DeleteReviewReqAct) => {
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const deleteReviewScss: CaseReducer<ReviewsState, DeleteReviewScssAct> = (state: ReviewsState, action: DeleteReviewScssAct) => {
    state.unapprovedReviews = state.unapprovedReviews?.filter(review => review.id !== action.payload.review.id) || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const deleteReviewFail: CaseReducer<ReviewsState, DeleteReviewFailAct> = (state: ReviewsState, action: DeleteReviewFailAct) => {
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error || null;
}

export const ReviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        fetchApprovedReviewsReq,
        fetchApprovedReviewsScss,
        fetchApprovedReviewsFail,
        fetchUnapprovedReviewsReq,
        fetchUnapprovedReviewsScss,
        fetchUnapprovedReviewsFail,
        approveReviewReq,
        approveReviewScss,
        approveReviewFail,
        deleteReviewReq,
        deleteReviewScss,
        deleteReviewFail,
    }
});

export const {
    fetchApprovedReviewsReq: fetchApprovedReviewsReqAct,
    fetchApprovedReviewsScss: fetchApprovedReviewsScssAct,
    fetchApprovedReviewsFail: fetchApprovedReviewsFailAct,
    fetchUnapprovedReviewsReq: fetchUnapprovedReviewsReqAct,
    fetchUnapprovedReviewsScss: fetchUnapprovedReviewsScssAct,
    fetchUnapprovedReviewsFail: fetchUnapprovedReviewsFailAct,
    approveReviewReq: approveReviewReqAct,
    approveReviewScss: approveReviewScssAct,
    approveReviewFail: approveReviewFailAct,
    deleteReviewReq: deleteReviewReqAct,
    deleteReviewScss: deleteReviewScssAct,
    deleteReviewFail: deleteReviewFailAct,
} = ReviewsSlice.actions;
