import React, { CSSProperties, FC, useEffect, useState } from 'react';
import leftArrow from '../../../../images/arrow-left.png';
import rightArrow from '../../../../images/arrow-right.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Image } from '../../../../interfaces/Image';
import deletePermanent from '../../../../images/delete-red.png';
import MakeUpService from '../../../shared/services/MakeUpService';
import { of, Subject, takeUntil, tap } from 'rxjs';
import { toast } from 'react-toastify';
import { toastObj } from '../../../../utils/utils';
import { catchError } from 'rxjs/operators';
import { useMakeUpActions } from '../../../../store/redux/make-ups/useMakeUps';
import ConfirmModal from '../../../shared/components/ConfirmModal/ConfirmModal';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

interface MakeUpSelectedImagesSliderProps {
  styles: CSSProperties;
  images: Image[];
  isUsrAdmin: boolean | null | undefined;
  makeUpId?: string;
}

const MakeUpSelectedImagesSlider: FC<MakeUpSelectedImagesSliderProps> = (props) => {
  const styles: any = props.styles;

  const [images, setImages] = useState<Image[]>();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [gallerySwiper, setGallerySwiper] = useState<any>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [shouldAnimateOnGalleryClose, setShouldAnimateOnGalleryClose] = useState(false);
  const [imgToDelete, setImgToDelete] = useState<Image | null>(null);
  const [isCnfmImgDelModalOpen, setIsCnfmImgDelModalOpen] = useState(false);
  const [imagesToDelete, setImagesToDelete] = useState<Set<Image>>(new Set());

  const overlayRef = React.useRef<HTMLDivElement>(null);
  const { replaceSelectedImagesInMakeUpService } = useMakeUpActions();

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  const destroy$ = new Subject();

  useEffect(() => {
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, []);

  useEffect(() => {
    const swiperGalleryContainer: HTMLElement | null = document.getElementById('selected-images-gallery');
    const navbar: HTMLElement | null = document.getElementById('navbar');

    const handleSlideClick = () => {
      setIsGalleryOpen(true);
      overlayRef.current?.classList.remove('animate__zoomOut');
      overlayRef.current?.classList.add(styles['overlay-visible'], 'animate__zoomIn');

      swiperGalleryContainer?.classList.add(styles['gallery-hover']);
      navbar?.classList.add(styles['display-hidden']);
    };

    const handleSlideMouseOut = () => {
      if (!isGalleryOpen) return;

      overlayRef.current?.classList.remove('animate__zoomIn');
      overlayRef.current?.classList.add('animate__zoomOut');

      swiperGalleryContainer?.classList.remove(styles['gallery-hover']);
      navbar?.classList.remove(styles['display-hidden']);

      setIsGalleryOpen(false);
      setShouldAnimateOnGalleryClose(true);

      setTimeout(() => {
        overlayRef.current?.classList.remove(styles['overlay-visible'], 'animate__zoomOut');
        setShouldAnimateOnGalleryClose(false);
      }, 300);
    };

    swiperGalleryContainer?.addEventListener('click', handleSlideClick);
    swiperGalleryContainer?.addEventListener('mouseleave', handleSlideMouseOut);

    return () => {
      swiperGalleryContainer?.removeEventListener('click', handleSlideClick);
      swiperGalleryContainer?.removeEventListener('mouseleave', handleSlideMouseOut);
    };
  });

  const removeSelectedImages = () => {
    if (!imagesToDelete) return;

    const imgIds: string[] = Array.from(imagesToDelete).map((img) => img.id!);

    MakeUpService.removeSelectedImages(props.makeUpId!, imgIds)
      .pipe(
        tap((res) => {
          if (res.status !== 200) throw new Error('Error deleting images');

          toast.success('Снимките бяха изтрити успешно.', toastObj);
          setImagesToDelete(new Set());
          setImages(res.data);
          replaceSelectedImagesInMakeUpService(res.data, props.makeUpId!);
        }),
        catchError((err: Error) => {
          toast.error(err.message, toastObj);
          return of(err);
        }),
        takeUntil(destroy$)
      )
      .subscribe();
  };

  function mapSlides(img: Image, isMainSlide: boolean) {
    return (
      <SwiperSlide
        key={img.id}
        id={styles.slide}
        className={`${styles['swiper-slide']} ${isGalleryOpen && styles['slide-hover']} animate__animated ${
          isGalleryOpen && 'animate__zoomIn'
        } ${shouldAnimateOnGalleryClose && 'animate__zoomOut'}`}
      >
        <div>
          <img src={img.url} alt={'slide'} className={`gallery-selected-image ${isGalleryOpen && styles['img-hover']}`} />
          {props.isUsrAdmin && !isMainSlide && (
            <img
              src={deletePermanent}
              alt={'delete'}
              className={styles['delete-img-icon']}
              onClick={() => {
                setImgToDelete(img);
                setIsCnfmImgDelModalOpen(true);
              }}
            />
          )}
        </div>
      </SwiperSlide>
    );
  }

  return (
    <React.Fragment>
      <div className={`${styles['gallery-left-arrow']} ${isGalleryOpen && styles['display-none']}`}>
        <img onClick={() => gallerySwiper.slidePrev()} src={leftArrow} alt={'left-arrow'} className={styles['left-arrow']} />
      </div>
      <div className={`${styles['gallery-right-arrow']} ${isGalleryOpen && styles['display-none']}`}>
        <img onClick={() => gallerySwiper.slideNext()} src={rightArrow} alt={'right-arrow'} className={styles['right-arrow']} />
      </div>

      <div ref={overlayRef} className={`${styles.overlay} animate__animated`} />

      <div className={`${styles['swiper-container']} ${isGalleryOpen && styles['swiper-container-gallery-open']}`}>
        <Swiper
          onSwiper={(swiper) => setGallerySwiper(swiper)}
          loop={true}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[FreeMode, Navigation, Thumbs]}
          id={'selected-images-gallery'}
          className={`${styles['swiper-gallery']}`}
        >
          {images && images.map((img, index) => mapSlides(img, true))}
        </Swiper>
        <Swiper
          onSwiper={(swiper) => setThumbsSwiper(swiper)}
          loop={true}
          spaceBetween={10}
          slidesPerView={3}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={`${styles['swiper-thumbs']} ${isGalleryOpen && styles['thumbs-gallery-open']}`}
        >
          {images && images.map((img) => mapSlides(img, false))}
        </Swiper>
      </div>

      <div className={`${styles['thumb-left-arrow']} ${isGalleryOpen && styles['display-none']}`}>
        <img onClick={() => thumbsSwiper.slidePrev()} src={leftArrow} alt={'left-arrow'} className={styles['left-arrow']} />
      </div>

      <div className={`${styles['thumb-right-arrow']} ${isGalleryOpen && styles['display-none']}`}>
        <img onClick={() => thumbsSwiper.slideNext()} src={rightArrow} alt={'right-arrow'} className={styles['right-arrow']} />
      </div>

      {props.isUsrAdmin && imagesToDelete.size > 0 && (
        <div className={styles['delete-selected-images-action-wrapper']}>
          <p className={`${styles['delete-info-sentence']} montserrat-normal-outer-space-17px`}>
            Избрани снимки за премахване: {imagesToDelete.size}
          </p>

          <div className={styles['delete-btns-wrapper']}>
            <button
              className={`${styles['undo-action-btn']} montserrat-normal-outer-space-17px`}
              onClick={() => setImagesToDelete(new Set())}
            >
              Oтмени изтриването
            </button>

            <button className={`${styles['delete-action-btn']} montserrat-normal-outer-space-17px`} onClick={removeSelectedImages}>
              Премахни избраните
            </button>
          </div>
        </div>
      )}

      <ConfirmModal
        isOpen={isCnfmImgDelModalOpen}
        onConfirm={() => {
          setImagesToDelete((prevState: Set<Image>) => prevState.add(imgToDelete!));
          setIsCnfmImgDelModalOpen(false);
        }}
        onModalClose={() => {
          setImgToDelete(null);
          setIsCnfmImgDelModalOpen(false);
        }}
        confirmMessage={'Сигурни ли сте, че искате да премахнете избраната снимка от услугате?'}
      />
    </React.Fragment>
  );
};
export default MakeUpSelectedImagesSlider;
