import React, { FC } from 'react';
import styles from './HomeAboutMe.module.css';
import grid from '../../../../images/line-about-me@1x.svg';
import blagaLeft from '../../../../images/blaga-left.jpg';
import blagaCenter from '../../../../images/blaga-center.jpg';
import blagaRight from '../../../../images/blaga-right.jpg';
import flowerGreen from '../../../../images/flowers-2@2x.png';
import flowerWhite from '../../../../images/flowers-about-me-home-page-3@2x.png';
import ShowMoreBtn from '../../../shared/components/ShowMoreBtn/ShowMoreBtn';
import flower from '../../../../images/group-1-52x.png';
import SectionHeading from '../../../shared/components/SectionHeading/SectionHeading';
import useHomePageLocale from '../../locales/HomePageLocales';
import { AnimationOnScroll } from 'react-animation-on-scroll';

interface HomeAboutMeProps {}

const HomeAboutMe: FC<HomeAboutMeProps> = () => {
  const locales: any = useHomePageLocale();

  return (
    <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn" duration={1.2}>
      <section style={{ marginTop: '110px' }}>
        <div id="about-me" className={styles['about-me-info-wrapper']}>
          <img className={styles['flower-green-first']} src={flowerGreen} alt={'green'} />
          <img className={styles['flower-green-second']} src={flowerGreen} alt={'green'} />
          <div className={styles['section-heading-wrapper']}>
            <SectionHeading title={locales.ABOUT_ME_TITLE} sentence={locales.ABOUT_ME_SHORT_SENTENCE} flowerSrc={flower} />
          </div>
        </div>
        <section className={styles.HomeAboutMe} data-testid="HomeAboutMe">
          <img src={grid} alt={grid} className={styles.grid} />
          <div className={styles['about-me-wrapper']}>
            <div className={styles['show-more']}>
              <ShowMoreBtn location={'/about-me'} label={locales.ABOUT_ME_BTN_LABEL} />
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" duration={0.6} style={{ zIndex: 0, position: 'relative' }}>
              <img src={blagaLeft} alt={blagaLeft} className={styles['blaga-left']} />
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInDown"
              duration={0.6}
              delay={250}
              offset={200}
              style={{ zIndex: 1, position: 'relative' }}
              animateOnce={true}
            >
              <img src={blagaCenter} alt={blagaCenter} className={styles['blaga-center']} />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" duration={0.6} style={{ zIndex: 0, position: 'relative' }}>
              <img src={blagaRight} alt={blagaRight} className={styles['blaga-right']} />
            </AnimationOnScroll>
            <img src={flowerWhite} alt={flowerWhite} className={styles['flower-white']} />
          </div>
        </section>
      </section>
    </AnimationOnScroll>
  );
};

export default HomeAboutMe;
