import React, {FC, FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import styles from './GalleryModificationModal.module.css';
import {Gallery, GalleryData} from '../../../../@types/types';
import ReactModal from 'react-modal';
import confirmIcon from '../../../../images/confirm.svg';
import cancelIcon from '../../../../images/cancel.svg';
import {t} from 'i18next';
import {ValidationContext} from '../../../../enums/enums';
import {isValidImageUpload, toastObj, validateDefInput} from '../../../../utils/utils';
import {toast} from 'react-toastify';
import {Image} from "../../../../interfaces/Image";
import ImageService from "../../../shared/services/ImageService";
import {Observable, of, Subject, takeUntil, tap} from "rxjs";
import {catchError, finalize, switchMap} from "rxjs/operators";
import Loader from "../../../shared/components/Loader/Loader";
import GalleryService from "../../services/galleryService";
import {AxiosResponse} from "axios";
import useGalleriesActions from "../../../../store/redux/galleries/useGalleries";
import {EditableImage} from "./EditableImage";
import {GalleryNameInput} from "./GalleryNameInput";
import {ImagesModal} from "./ImagesModal";
import {UploadBtn} from "./UploadBtn";
import {GalleryModificationContext, GalleryModificationModalProps, ImageType, MainImageUploadState} from "./types";

const IMG_REQUEST_SIZE: number = 10;

const GalleryModificationModal: FC<GalleryModificationModalProps> = (props) => {
        const [shouldAnimateOnClose, setShouldAnimateOnClose] = useState(false);
        const [imageUpload, setImageUpload] = useState<File | null>(null);
        const [galleryMainIMage, setGalleryMainIMage] = useState<string | undefined>(undefined);
        const [animateOnDBImagesModalClose, setAnimateOnDBImagesModalClose] = React.useState<boolean>(true);
        const [isSelectDBImagesModalOpen, setIsSelectDBImagesModalOpen] = React.useState(false);
        const [dbImages, setDBImages] = React.useState<Image[]>([]);
        const [areMoreImagesToFetch, setAreMoreImagesToFetch] = React.useState<boolean>(true);
        const [pageFetched, setPageFetched] = React.useState<number>(0);
        const [isActionLoading, setIsActionLoading] = React.useState<boolean>(false);
        const [selectedDBImage, setSelectedDbImage] = React.useState<Image | null>(null);
        const [isMainImgHovered, setIsMainImgHovered] = React.useState<boolean>(false);
        const [shouldShowChangeImgBtns, setShouldShowChangeImgBtns] = React.useState<boolean>(false);
        const [hasMainImgChanged, setHasMainImgChanged] = React.useState<MainImageUploadState>({
            hasMainImgChanged: false,
            type: null,
        });

        const nameBGRef = useRef<HTMLInputElement>(null);
        const nameENRef = useRef<HTMLInputElement>(null);
        const mainImageLocalRef = useRef<HTMLInputElement>(null);
        const uploadImgBtn = useRef<HTMLButtonElement>(null);

        const {addGallery, updateGallery} = useGalleriesActions();

        const destroy$ = new Subject<void>();

        useEffect(() => {
            return (): void => {
                destroy$.next();
                destroy$.complete();
            };
        }, []);

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files[0]) {
                let file = event.target.files[0];

                if (isValidImageUpload(file, uploadImgBtn, styles, t('UPLOAD_IMG_ERR'))) {
                    setSelectedDbImage(null);
                    setImageUpload(file);
                    setHasMainImgChanged({hasMainImgChanged: true, type: ImageType.LOCAL});
                    const reader = new FileReader();
                    reader.onload = () => {
                        setGalleryMainIMage(reader.result as string);
                    };

                    reader.readAsDataURL(file);
                } else {
                    event.target.value = '';
                }
            }
        };

        const onModalCloseRequest = (callback: Function | null | undefined) => {
            setShouldAnimateOnClose(true);

            setTimeout(() => {
                callback && callback();
                setTimeout(() => setShouldAnimateOnClose(false), 800);
            }, 500);
        };

        const fetchDBImages = (page: number, size: number) => {
            setIsActionLoading(true);

            (pageFetched !== page || dbImages.length === 0) &&
            ImageService.getAllImages({page, size, imageUrlsToExclude: []})
                .pipe(
                    tap((res) => {
                        if (res.status !== 200) throw new Error('Error fetching images from DB');

                        setDBImages([...dbImages, ...res.data]);
                        setPageFetched(page);
                        setIsActionLoading(false);

                        setAreMoreImagesToFetch(res.data.length === size);
                    }),
                    catchError((err) => {
                        toast.error(err.message, toastObj);
                        setIsActionLoading(false);
                        return of(err);
                    }),
                    takeUntil(destroy$)
                )
                .subscribe();
        };

        const mapDBImages = useCallback((image: Image): JSX.Element => {
                const callback = (): void => {
                    const prevSelectedDBImages: Image | null = selectedDBImage;

                    setImageUpload(null);
                    setSelectedDbImage(image);
                    setHasMainImgChanged({hasMainImgChanged: true, type: ImageType.DB});
                    setGalleryMainIMage(image.url);

                    const newDBImagesState = !!prevSelectedDBImages
                        ? [prevSelectedDBImages, ...dbImages.filter((img: Image): boolean => img.id !== image.id)]
                        : [...dbImages.filter((img: Image): boolean => img.id !== image.id)];

                    setDBImages(newDBImagesState);
                };

                return createImageComponent(image.id, image.url, callback, true);
            },
            [selectedDBImage, dbImages]
        );

        const createImageComponent = (key: string, url: string, clickCallback: () => void, isCheckMarkSymbol: boolean): JSX.Element => {
            return (
                <div key={key} className={styles['img-container']} onClick={clickCallback}>
                    <img className={styles['selected-image']} src={url} alt={'db-selection'}/>
                    <div className={styles['image-overlay']}>
                        <div className={styles['remove']} style={{color: isCheckMarkSymbol ? '#008000' : '#be2929'}}>
                            {isCheckMarkSymbol ? <span>&#x2714;</span> : <span>&#x2718;</span>}
                        </div>
                    </div>
                </div>
            );
        };

        const handleSubmit = (event?: FormEvent) => {
            if (!validateInput()) return;

            const isCreateCtx = props.context === GalleryModificationContext.CREATE;
            const isUpdateWithChangedMainImg = !isCreateCtx && hasMainImgChanged.hasMainImgChanged;
            const isLocalImage = isCreateCtx ? !!imageUpload : hasMainImgChanged.type === ImageType.LOCAL;

            if (isLocalImage) {
                handleImageUpload(isCreateCtx);
            } else {

                const url: string | null = isCreateCtx
                    ? selectedDBImage!.url
                    : (isUpdateWithChangedMainImg
                        ? selectedDBImage!.url
                        : null);

                modifyGallery(url, isCreateCtx).subscribe();
            }
        }

        const clearState = (): void => {
            setImageUpload(null);
            setSelectedDbImage(null);
            setHasMainImgChanged({hasMainImgChanged: false, type: null});
            setIsActionLoading(false);
        }

        const validateInput = (): boolean => {
            const isValidNameBG: boolean = validateDefInput(nameBGRef, styles, ValidationContext.ON_SUBMIT, 'Името на галерията на български е задължително');
            const isValidNameEN: boolean = validateDefInput(nameENRef, styles, ValidationContext.ON_SUBMIT, 'Името на галерията на английски е задължително');

            if (!isValidNameBG || !isValidNameEN) {
                toast.error('Моля попълнете задължителните полета', toastObj);
                return false;
            }

            return true;
        }

        const handleImageUpload = (isCreateCtx: boolean): void => {
            setIsActionLoading(true);

            ImageService.uploadImage(imageUpload!).pipe(
                takeUntil(destroy$),
                switchMap((res) => {
                    if (res.status !== 201) throw new Error('Error uploading image');
                    return modifyGallery(res.data, isCreateCtx);
                })
            ).subscribe();
        }

        const modifyGallery = (imageUrl: string | null, isCreateCtx: boolean): Observable<AxiosResponse<Gallery>> => {
            const galleryData: GalleryData = {
                nameBG: nameBGRef.current!.value as string,
                nameEN: nameENRef.current!.value as string,
                mainImageUrl: imageUrl,
                updated: !isCreateCtx,
            }

            const apiCall = isCreateCtx
                ? GalleryService.createGallery(galleryData)
                : GalleryService.updateGallery(props.gallery!.id, galleryData);

            return apiCall.pipe(
                tap((res) => {
                    if (res.status !== (isCreateCtx ? 201 : 200)) {
                        throw new Error(`Error ${isCreateCtx ? 'creating' : 'updating'} gallery`);
                    }

                    toast.success(`Галерията е ${isCreateCtx ? 'създадена' : 'обновена'} успешно`, toastObj);

                    isCreateCtx
                        ? addGallery(res.data)
                        : updateGallery(res.data);

                }),
                takeUntil(destroy$),
                finalize(() => {
                    setIsActionLoading(false);
                    props.onSubmit && props.onSubmit();
                    props.onSuccessClose();
                    clearState();
                }),
                catchError((err) => {
                    props.onClose();
                    return of(err);
                })
            );
        }

        return (
            <div data-testid={"GalleryModificationModal"}>
                {isActionLoading && <Loader/>}

                <ReactModal
                    isOpen={props.isModalOpen}
                    className={`${styles.modal} animate__animated ${!shouldAnimateOnClose && 'animate__bounceInLeft'} ${
                        shouldAnimateOnClose && 'animate__bounceOutRight'
                    }`}
                    overlayClassName={styles.overlay}
                    shouldCloseOnOverlayClick={false}
                    closeTimeoutMS={300}
                    preventScroll={false}
                    parentSelector={() => document.body}
                >
                    {
                        (galleryMainIMage || props.gallery?.mainImageUrl) &&
                        <EditableImage
                            src={galleryMainIMage || props.gallery?.mainImageUrl}
                            alt='main'
                            onHover={setIsMainImgHovered}
                            isHovered={props.context === GalleryModificationContext.EDIT && isMainImgHovered}
                            onClick={() => setShouldShowChangeImgBtns(!shouldShowChangeImgBtns)}
                        />
                    }

                    <form className={styles.form} onSubmit={props.onSubmit}>
                        <GalleryNameInput
                            onBlur={() => validateDefInput(nameBGRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', {length: 2}))}
                            onChange={() => validateDefInput(nameBGRef, styles, ValidationContext.ON_CHANGE)}
                            value={props.gallery?.nameBG}
                            language='Български'
                            reference={nameBGRef}
                        />

                        <GalleryNameInput
                            onBlur={() => validateDefInput(nameENRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', {length: 2}))}
                            onChange={() => validateDefInput(nameENRef, styles, ValidationContext.ON_CHANGE)}
                            value={props.gallery?.nameEN}
                            language='Английски'
                            reference={nameENRef}
                        />

                        {(props.context === GalleryModificationContext.CREATE || shouldShowChangeImgBtns) && (
                            <div className={styles.label}>
                                <input
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                    className={styles.input}
                                    type="file"
                                    ref={mainImageLocalRef}
                                />
                                <UploadBtn
                                    onClick={() => mainImageLocalRef.current?.click()}
                                    text='Избери главна снимка от локалното устройство'
                                />

                                <UploadBtn
                                    onClick={() => {
                                        setAnimateOnDBImagesModalClose(false);
                                        setIsSelectDBImagesModalOpen(true);
                                        dbImages.length === 0 && fetchDBImages(pageFetched + 1, IMG_REQUEST_SIZE);
                                    }}
                                    text='Избери главна снимка от базата от данни'
                                />
                            </div>
                        )}
                    </form>

                    <div className={styles['actions-wrapper']}>
                        <img onClick={() => onModalCloseRequest(handleSubmit)} className={styles['action-icon']}
                             src={confirmIcon} alt={'confirm'}/>

                        <img onClick={() => onModalCloseRequest(props.onClose)} className={styles['action-icon']}
                             src={cancelIcon} alt={'close'}/>
                    </div>

                    <ImagesModal
                        isOpen={isSelectDBImagesModalOpen}
                        onClose={() => {
                            setAnimateOnDBImagesModalClose(true);
                            setTimeout(() => {
                                setIsSelectDBImagesModalOpen(false);
                            }, 400);
                        }}
                        dbImages={dbImages}
                        mapDBImages={mapDBImages}
                        areMoreImagesToFetch={areMoreImagesToFetch}
                        fetchDBImages={fetchDBImages}
                        pageFetched={pageFetched}
                        IMG_REQUEST_SIZE={IMG_REQUEST_SIZE}
                        animateOnClose={animateOnDBImagesModalClose}
                    />

                </ReactModal>
            </div>

        );
    }
;
export default GalleryModificationModal;
