import React, { FC, useEffect, useState } from 'react';
import styles from './GalleriesPage.module.css';
import flower from '../../images/group-1-1@2x.svg';
import SectionHeading from '../../modules/shared/components/SectionHeading/SectionHeading';
import GalleryBox from '../../modules/shared/components/GalleryBox/GalleryBox';
import useGalleriesPageLocale from './GalleriesPageLocale';
import { useSelector } from 'react-redux';
import { GalleriesState } from '../../store/redux/galleries/interfaces';
import useGalleriesActions from '../../store/redux/galleries/useGalleries';
import { RootState } from '../../store/redux/store-config/globalStoreConfig';
import { Gallery } from '../../@types/types';
import { Lang, useLang } from '../../store/context/lang-context';
import GalleryModificationModal from '../../modules/gallery-page-module/components/GalleryModificationModal/GalleryModificationModal';
import { toast, ToastContainer } from 'react-toastify';
import { GalleryModificationContext } from '../../modules/gallery-page-module/components/GalleryModificationModal/types';
import { useParams } from 'react-router';
import { toastObj } from '../../utils/utils';

interface GalleriesPageProps {}

const GalleriesPage: FC<GalleriesPageProps> = () => {
  const [galleries, setGalleries] = useState<Gallery[] | null>(null);
  const [isUsrAdmin, setIsUsrAdmin] = useState<boolean>(false);
  const [isModificationModalOpen, setIsModificationModalOpen] = useState<boolean>(false);

  const galleriesData: GalleriesState = useSelector((state: RootState) => state.galleriesData);
  const authState = useSelector((state: RootState) => state.persistedAuthData.xxfh);

  const { fetchGalleries } = useGalleriesActions();
  const { lang } = useLang();
  const { deletedGallery, msg } = useParams();

  useEffect(() => {
    if (!!deletedGallery && !!msg) {
      toast.success(msg, toastObj);
    }
  }, [deletedGallery, msg]);

  useEffect(() => {
    setIsUsrAdmin(authState?.authData?.isAdmin || false);
  }, [authState?.authData?.isAdmin]);

  useEffect(() => {
    if (!galleriesData?.galleries && galleriesData?.loading === false && galleriesData?.error === null) {
      fetchGalleries();
      return;
    }

    setGalleries(galleriesData.galleries);
  }, [galleriesData]);

  const locales = useGalleriesPageLocale();

  return (
    <section className={styles.GalleriesPage} data-testid="GalleriesPage">
      <SectionHeading title={locales.GALLERIES_TITLE} sentence={locales.GALLERIES_SENTENCE} flowerSrc={flower} />

      <ToastContainer />

      {isUsrAdmin && (
        <div className={styles['add-gallery-wrapper']}>
          <button
            onClick={() => setIsModificationModalOpen(true)}
            className={`${styles['add-gallery-button']} montserrat-normal-outer-space-15px`}
          >
            Добави галерия
          </button>

          <GalleryModificationModal
            isModalOpen={isModificationModalOpen}
            context={GalleryModificationContext.CREATE}
            onClose={() => setIsModificationModalOpen(false)}
            onSuccessClose={() => setIsModificationModalOpen(false)}
          />
        </div>
      )}

      <div className={styles['galleries-wrapper']}>
        {galleries?.map((gallery, index) => (
          <div key={gallery.id} className={styles['box-wrapper']}>
            <GalleryBox
              id={gallery.id}
              title={lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
              mainImage={gallery.mainImageUrl}
              imageCount={gallery.imagesCount}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default GalleriesPage;
