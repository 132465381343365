import React, {FC, useState, useCallback} from 'react';
import styles from './Rating.module.css';

interface RatingProps {
    readonly: boolean;
    stars: number;
    onClick?: () => void;
    setRating?: (rating: number) => void;  // Add a setRating prop
}


const Rating: FC<RatingProps> = (props) => {
    const [ratingVal, setRatingVal] = useState(props.stars);
    const [hover, setHover] = useState(props.stars);
    const [rating, setLocalRating] = useState(props.stars);

    const handleRating = useCallback((event: React.MouseEvent, rating: number) => {
        setLocalRating(rating);
        setRatingVal(rating);
        props.setRating && props.setRating(rating);  // Use the setRating passed down from LeaveReview
        props.onClick && props.onClick();
    }, [props]);

    function mapElements(): JSX.Element[] {
        return [...Array(5)].map((star, index) => {
            index += 1;

            return props.readonly
                ?
                <button
                    id={styles.btn}
                    type="button"
                    style={{transition: 'none', cursor: 'default'}}
                    key={index}
                    className={index <= (hover || ratingVal) ? styles.on : styles.off}
                >
                    <span className={styles.star}>&#9733;</span>
                </button>
                :
                <button
                    id={styles.btn}
                    type="button"
                    key={index}
                    className={index <= (hover || ratingVal) ? styles.on : styles.off}
                    onClick={(event) => handleRating(event, index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(ratingVal)}>
                    <span className={styles.star}>&#9733;</span>
                </button>
        });
    }

    return (
        <div className={styles["star-ratingVal"]}>
            {mapElements()}
        </div>
    );
}

Rating.defaultProps = {
    readonly: false,
    stars: 0,
};

export default Rating;
