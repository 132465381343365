import React, {FC} from 'react';
import greenFlower from "../../images/group-1-1@2x.svg";
import styles from './AboutMePage.module.css';
import blagaSmall from '../../images/blaga-left.jpg';
import blagaNosia from '../../images/blaga-nosia.jpg';
import background from '../../images/background-hour.jpg';
import whiteFlower from '../../images/group-1-5@2x.png';
import firstIcon from '../../images/icon-01@2x.svg';
import secondIcon from '../../images/icon-02@2x.svg';
import thirdIcon from '../../images/icon-03@2x.svg';
import blagaBottom from '../../images/blaga-about-me-bottom.jpg';
import bioMakeUpFirst from '../../images/bio-make-up-1.jpg';
import bioMakeUpSecond from '../../images/bio-make-up-2.jpg';
import pipe from '../../images/line-1-4@2x.svg';
import SectionHeading from "../../modules/shared/components/SectionHeading/SectionHeading";
import useAboutMePageLocale from "./AboutMePageLocale";

interface AboutMePageProps {
}

const AboutMePage: FC<AboutMePageProps> = () => {
    const locales = useAboutMePageLocale();

    return(
        <section className={styles.AboutMePage} data-testid="AboutMePage">
            <div className={styles["section-heading-wrapper"]}>
                <SectionHeading title={locales.ABOUT_ME_TITLE} sentence={undefined} flowerSrc={greenFlower}/>
            </div>

            <section className={styles["first-info-section"]}>
                <div className={styles["first-image-section"]}>
                    <img className={styles["img-left"]} src={blagaNosia} alt={"left"}/>
                    <img className={styles["img-right"]} src={blagaSmall} alt={"right"}/>
                </div>

                <div className={styles["first-text-section"]}>
                    <p className={`${styles["first-info-paragraph"]} montserrat-normal-outer-space-17px`}>{locales.ABOUT_ME_SENTENCE_1}</p>
                    <br/>
                    <br/>
                    <br/>
                    <p className={`${styles["second-info-paragraph"]} montserrat-normal-outer-space-17px`}>{locales.ABOUT_ME_SENTENCE_2}</p>
                    <br/>
                    <br/>
                    <br/>
                    <p className={`${styles["third-info-paragraph"]} montserrat-normal-outer-space-17px`}>{locales.ABOUT_ME_SENTENCE_3}</p>
                </div>
            </section>

            <section className={styles["how-its-started-wrapper"]}>
                <h1 className={"monotypecorsiva-regular-normal-outer-space-40px"}>
                    "&nbsp;{locales.ABOUT_ME_HEADING_2}&nbsp;"
                </h1>

                <section className={styles["how-its-started"]}>
                    <img className={styles.background} src={background} alt={"background"}/>
                    <div className={styles["how-its-started-text"]}>
                        <SectionHeading title={locales.ABOUT_ME_HOW_IT_STARTED_TITLE} sentence={undefined}
                                        flowerSrc={whiteFlower}/>
                        <p className={"montserrat-normal-outer-space-17px"}>{locales.ABOUT_ME_HOW_IT_STARTED_SENTENCE}</p>
                    </div>
                </section>

            </section>

            <section className={styles["my-mission-wrapper"]}>
                <div className={styles["my-mission-left-section"]}>
                    <SectionHeading title={locales.ABOUT_ME_MY_MISSION_TITLE} sentence={undefined}
                                    flowerSrc={greenFlower}/>
                    <p className={"montserrat-normal-outer-space-17px"}>{locales.ABOUT_ME_MY_MISSION_SENTENCE_1}</p>

                    <div className={styles["steps-wrapper"]}>
                        <img className={styles["my-mission-icon"]} src={firstIcon} alt={"first icon"}/>
                        <p className={"montserrat-normal-outer-space-17px"}>{locales.ABOUT_ME_MY_MISSION_SENTENCE_2}</p>
                    </div>

                    <div className={styles["steps-wrapper"]}>
                        <img className={styles["my-mission-icon"]} src={secondIcon} alt={"first icon"}/>
                        <p className={"montserrat-normal-outer-space-17px"}>{locales.ABOUT_ME_MY_MISSION_SENTENCE_3}</p>
                    </div>

                    <div className={styles["steps-wrapper"]}>
                        <img className={styles["my-mission-icon"]} src={thirdIcon} alt={"first icon"}/>
                        <p className={"montserrat-normal-outer-space-17px"}>{locales.ABOUT_ME_MY_MISSION_SENTENCE_4}</p>
                    </div>
                </div>

                <div className={styles["my-mission-right-section"]}>
                    <div className={styles["img-left-wrapper"]}>
                        <img className={styles["img-bottom"]} src={bioMakeUpFirst} alt={"left"}/>
                        <img className={styles["img-bottom"]} src={bioMakeUpSecond} alt={"left"}/>
                    </div>

                    <img className={styles["img-bottom"]} src={blagaBottom} alt={"left"}/>
                </div>

            </section>

            <section className={styles["my-mission-bottom-section"]}>
                <p className={`montserrat-normal-outer-space-17px`}>{locales.ABOUT_ME_MY_MISSION_BOTTOM_LEFT_SENTENCE}</p>
                <img className={styles.pipe} src={pipe} alt={"pipe"} />
                <p style={{paddingLeft:'30px'}} className={`montserrat-normal-outer-space-17px`}>{locales.ABOUT_ME_MY_MISSION_BOTTOM_RIGHT_SENTENCE}</p>
            </section>
        </section>
    );
}

export default AboutMePage;
