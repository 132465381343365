import React, { lazy, Suspense, useState } from 'react';
import './App.css';
import './localization/Locale.tsx';
import 'animate.css/animate.min.css';
import HomePage from './pages/HomePage/HomePage';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Footer from './modules/shared/components/Footer/Footer';
import Navigation from './modules/navigation-module/Navigation/Navigation';
import Services from './pages/Services/Services';
import ServiceDetails from './pages/ServiceDetails/ServiceDetails';
import ScrollToTop from './modules/shared/components/ScrollToTop/ScrollToTop';
import GalleriesPage from './pages/GalleriesPage/GalleriesPage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import AboutMePage from './pages/AboutMePage/AboutMePage';
import AppointmentPage from './pages/AppointmentPage/AppointmentPage';
import Auth from './pages/AuthPage/Auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider, useCookies } from 'react-cookie';
import { LangContext } from './store/context/lang-context';
import LangDeterminator from './modules/shared/components/LangDeterminator/LangDeterminator';
import LeaveReview from './pages/LeaveReview/LeaveReview';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigureStore } from './store/redux/store-config/storeConfig';
import { configureGlobalStore } from './store/redux/store-config/globalStoreConfig';
import UserStateMapping from './modules/shared/components/UserStateMapping/UserStateMapping';
import { cookieLang } from './cookies/constants';
import BaseRouterHandler from './modules/shared/components/BaseRouterHandler/BaseRouterHandler';
import ProtectedRoute from './guards/ProtectedRoute';
import Loader from './modules/shared/components/Loader/Loader';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import PrivacyPolicyRouterHandler from './modules/shared/components/BaseRouterHandler/PrivacyPolicyRouterHandler';
import ToCRouterHandler from './modules/shared/components/BaseRouterHandler/ToCRouterHandler';
import ToC from './pages/ToC/ToC';
import ErrorPage from './pages/ErrorPage/ErrorPage';

export default App;

function App() {
  const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));

  const googleClientId = process.env.REACT_APP_GOOGLE_API_ID || '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookie, setCookie] = useCookies([cookieLang]);
  const [langCookie, setLangCookie] = useState(cookie.lang);

  ReactModal.setAppElement('#root');
  const { store, persistor } = ConfigureStore();
  configureGlobalStore(store);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CookiesProvider>
              <LangContext.Provider value={{ lang: langCookie, setLang: setLangCookie }}>
                <ScrollToTop>
                  <LangDeterminator>
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <Navigation />
                      <UserStateMapping>
                        <BaseRouterHandler />
                        <Routes>
                          <Route path="/" element={<BaseRouterHandler />} />
                          <Route path="/:lang" element={<HomePage />} />
                          <Route path="/:lang/services" element={<Services />} />
                          <Route path="/:lang/services/:id/:name" element={<ServiceDetails />} />
                          <Route path="/:lang/courses" element={<Services />} />
                          <Route path="/:lang/courses/:id/:name" element={<ServiceDetails />} />
                          <Route path="/:lang/galleries" element={<GalleriesPage />} />
                          <Route path="/:lang/galleries/:deletedGallery/:msg" element={<GalleriesPage />} />
                          <Route path="/:lang/gallery/:id" element={<GalleryPage />} />
                          {/*
                                                           <Route path="/:lang/blog" element={<BlogPage/>}/>
                                                           <Route path="/:lang/blog/:id" element={<BlogPostsPage/>}/>
                                                           <Route path="/:lang/blog/post/:id" element={<PostPage/>}/>
                                                             */}
                          <Route path="/:lang/about-me" element={<AboutMePage />} />
                          <Route path="/:lang/appointment" element={<AppointmentPage />} />
                          <Route path="/:lang/login" element={<Auth />} />
                          <Route path="/:lang/register" element={<Auth />} />
                          <Route path="/:lang/review" element={<LeaveReview />} />
                          <Route element={<ProtectedRoute />}>
                            <Route path="/:lang/profile" element={<ProfilePage />} />
                          </Route>
                          <Route path="/privacy-policy" element={<PrivacyPolicyRouterHandler />} />
                          <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />} />
                          <Route path="/terms-and-conditions" element={<ToCRouterHandler />} />
                          <Route path="/:lang/terms-and-conditions" element={<ToC />} />
                          <Route path="/:lang/*" element={<ErrorPage />} />
                          <Route path="/*" element={<ErrorPage />} />
                        </Routes>
                      </UserStateMapping>
                      <Footer />
                    </GoogleOAuthProvider>
                  </LangDeterminator>
                </ScrollToTop>
              </LangContext.Provider>
            </CookiesProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </BrowserRouter>
  );
}
