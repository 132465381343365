import { MakeUpType } from '../../../enums/enums';
import { Observable } from 'rxjs';
import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { MakeUp, MakeUpFullData } from '../../../interfaces/MakeUp';
import { CONTENT_TYPE, CONTENT_TYPE_JSON } from '../../auth/constants/auth-constants';
import { _Axios } from '../../../services-config/axios/axios-conf';
import { MakeUpData } from '../../../@types/types';
import { Image } from '../../../interfaces/Image';

const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const baseUrl = `${apiHost}/api/make-up`;
const fetchAllMakeUpsEndPoint = `${baseUrl}/all`;
const selectedImagesEndPoint: string = `${baseUrl}/selected-images`;
const mainImgEndPoint: string = `${baseUrl}/main-image`;
const galleriesEndPoint: string = `${baseUrl}/galleries`;
const updateMakeUpDataEndPoint: string = `${baseUrl}/data`;

const MakeUpService = {
  fetchAllMakeUps: (page: number, limit: number, type: MakeUpType): Observable<AxiosResponse<MakeUp[]>> => {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.DefInstance.get(`${fetchAllMakeUpsEndPoint}?page=${page}&limit=${limit}&type=${type}`, requestOptions);
  },
  createMakeUp: (makeUp: MakeUpData): Observable<AxiosResponse> => {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.AuthInstance.post(`${baseUrl}`, JSON.stringify(makeUp), requestOptions);
  },
  getMakeUpById: (id: string): Observable<AxiosResponse<MakeUpFullData>> => {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.DefInstance.get(`${baseUrl}?make-up_id=${id}`, requestOptions);
  },
  addSelectedImages: (makeUpId: string, imageUrls: string[]): Observable<AxiosResponse<Image[]>> => {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.AuthInstance.post(`${selectedImagesEndPoint}?make-up_id=${makeUpId}`, JSON.stringify(imageUrls), requestOptions);
  },
  removeSelectedImages: (makeUpId: string, imageIds: string[]): Observable<AxiosResponse<Image[]>> => {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const config: AxiosRequestConfig = {
      headers,
      data: JSON.stringify(imageIds),
    };

    return _Axios.AuthInstance.delete(`${selectedImagesEndPoint}?make-up_id=${makeUpId}`, config);
  },
  replaceMainImg: (makeUpId: string, imageUrl: string): Observable<AxiosResponse> => {
    const body = { imageUrl };

    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.AuthInstance.put(`${mainImgEndPoint}?make-up_id=${makeUpId}`, JSON.stringify(body), requestOptions);
  },
  removeGallery(makeUpId: string, galleryId: string): Observable<AxiosResponse> {
    const headers: AxiosRequestHeaders = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    return _Axios.AuthInstance.delete(`${galleriesEndPoint}?make-up_id=${makeUpId}&gallery_id=${galleryId}`, { headers });
  },
  addGalleries(makeUpId: string, galleryIds: string[]): Observable<AxiosResponse<MakeUpFullData>> {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.AuthInstance.put(`${galleriesEndPoint}?make-up_id=${makeUpId}`, JSON.stringify(galleryIds), requestOptions);
  },

  updateMakeUpData(makeUpId: string, makeUpData: MakeUpData): Observable<AxiosResponse<MakeUpFullData>> {
    const headers: AxiosRequestHeaders = {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    const requestOptions = {
      headers: headers,
    };

    return _Axios.AuthInstance.put(`${updateMakeUpDataEndPoint}?make-up_id=${makeUpId}`, JSON.stringify(makeUpData), requestOptions);
  },

  deleteMakeUp(makeUpId: string, permanent: boolean): Observable<AxiosResponse> {
    const headers: AxiosRequestHeaders = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    return _Axios.AuthInstance.delete(`${baseUrl}?make-up_id=${makeUpId}&permanent=${permanent}`, { headers });
  },
};

export default MakeUpService;

Object.freeze(MakeUpService);
