import React, { FC, useEffect, useState } from 'react';
import styles from './ServicesMiniWrapper.module.css';
import ServiceBox from '../../../shared/components/ServiceBox/ServiceBox';
import ShowMoreBtn from '../../../shared/components/ShowMoreBtn/ShowMoreBtn';
import useWindowDimensions from '../../../../hooks/screen-resolution-checker';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFlip } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import flower from '../../../../images/group-1-1@2x.svg';
import SectionHeading from '../../../shared/components/SectionHeading/SectionHeading';
import useHomePageLocale from '../../locales/HomePageLocales';
import { MakeUpState } from '../../../../store/redux/make-ups/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/redux/store-config/globalStoreConfig';
import { MakeUp } from '../../../../interfaces/MakeUp';
import { useMakeUpActions } from '../../../../store/redux/make-ups/useMakeUps';
import { AnimationOnScroll } from 'react-animation-on-scroll';

interface ServicesHomeWrapperProps {}

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFlip]);

const ServicesMiniWrapper: FC<ServicesHomeWrapperProps> = () => {
  const [makeUps, setMakeUps] = useState<MakeUp[] | null>(null);

  const locales: any = useHomePageLocale();
  const { fetchMakeUpServices } = useMakeUpActions();

  const makeUpsData: MakeUpState = useSelector((state: RootState) => state.makeUps);

  useEffect(() => {
    if (!makeUpsData?.makeUpServices && makeUpsData?.loading === false && makeUpsData.error === null) {
      fetchMakeUpServices(50, 1);
    }

    setMakeUps(makeUpsData.makeUpServices);
  }, [fetchMakeUpServices, makeUpsData?.loading, makeUpsData.makeUpServices]);

  return (
    <section id="home-services" data-testid="ServicesHomeWrapper">
      <div className={styles['service-wrapper']}>
        <AnimationOnScroll animateOnce={true} animateIn="animate__zoomInLeft" duration={1.2}>
          <div className={styles['info-wrapper']}>
            <SectionHeading title={locales.SERVICES_TITLE} sentence={locales.SERVICES_SHORT_SENTENCE} flowerSrc={flower} />
          </div>

          {useWindowDimensions().width > 926 ? (
            <div className={styles['service-box-wrapper']}>
              {makeUps?.slice(0, 6).map((makeUp) => (
                <ServiceBox key={makeUp.id} makeUp={makeUp} />
              ))}
            </div>
          ) : (
            <Swiper
              className={styles.swiper}
              effect={'flip'}
              grabCursor={true}
              loop={true}
              speed={1000}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[EffectFlip, Pagination, Navigation, Autoplay]}
            >
              {makeUps?.slice(0, 6).map((makeUp, index) => (
                <SwiperSlide className={styles['swiper-slide']} key={index}>
                  <ServiceBox key={index} makeUp={makeUp} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </AnimationOnScroll>
        <br />
        <br />
        <br />
        <div className={styles['show-more-wrapper']}>
          <ShowMoreBtn location={'/services'} label={locales.SERVICES_BTN_LABEL} />
        </div>
      </div>
    </section>
  );
};

export default ServicesMiniWrapper;
