import {Observable} from 'rxjs';
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios';
import {AddGalleryImagesData, Gallery, GalleryData} from '../../../@types/types';
import {ACCEPT_JSON, CONTENT_TYPE, CONTENT_TYPE_JSON} from '../../auth/constants/auth-constants';
import {_Axios} from '../../../services-config/axios/axios-conf';
import {Image} from "../../../interfaces/Image";

const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const baseUrl: string = `${apiHost}/api/gallery`;
const allGalleriesEndPoint: string = `${baseUrl}/all`;
const galleryImagesEndPoint: string = `${baseUrl}/images`;
const likeGalleryEndPoint: string = `${galleryImagesEndPoint}/like`;

const GalleryService = {
    getAllGalleries: (): Observable<AxiosResponse<Gallery[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
        };

        return _Axios.DefInstance.get(allGalleriesEndPoint, requestOptions);
    },

    createGallery: (gallery: GalleryData): Observable<AxiosResponse<Gallery>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.post(baseUrl, JSON.stringify(gallery), requestOptions);
    },

    getImagesForGallery: (galleryId: string, page: number, limit: number, authenticated: boolean = false): Observable<AxiosResponse<Image[]>> => {
        const headers: AxiosRequestHeaders = {
            accept: ACCEPT_JSON,
        }

        const requestOptions = {
            headers: headers,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const url = authenticated
            ? `${galleryImagesEndPoint}/authenticated?gallery_id=${galleryId}&page=${page}&limit=${limit}`
            : `${galleryImagesEndPoint}?gallery_id=${galleryId}&page=${page}&limit=${limit}`;

        return authenticated
            ? _Axios.AuthInstance.get(url, requestOptions)
            : _Axios.DefInstance.get(url, requestOptions);
    },

    addImages(data: AddGalleryImagesData): Observable<AxiosResponse<number>> {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.post(galleryImagesEndPoint, JSON.stringify(data), requestOptions);
    },

    updateGallery: (galleryId: string, gallery: GalleryData): Observable<AxiosResponse<Gallery>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
            params: {
                id: galleryId
            }
        };

        return _Axios.AuthInstance.put(`${baseUrl}`, JSON.stringify(gallery), requestOptions);
    },

    deleteGallery: (id: string, permanent: boolean): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions = {
            headers: headers,
            params: {
                id: id,
                permanent: permanent
            }
        };

        return _Axios.AuthInstance.delete(`${baseUrl}`, requestOptions);
    },

    removeImages(galleryId: string, imageIds: string[]): Observable<AxiosResponse<number>> {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            data: JSON.stringify(imageIds),
            params: {
                gallery_id: galleryId
            }
        };

        return _Axios.AuthInstance.delete(galleryImagesEndPoint, requestOptions);
    },

    likeImage(imageId: string): Observable<AxiosResponse<number>> {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };

        const requestOptions: AxiosRequestConfig = {
            headers: headers,
            params: {
                image_id: imageId
            }
        };

        return _Axios.AuthInstance.post(likeGalleryEndPoint, null, requestOptions);
    }
};

export default GalleryService;

Object.freeze(GalleryService);
