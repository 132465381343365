import {FC, useEffect, useState} from 'react';
import styles from './GalleryPage.module.css';
import flower from '../../images/group-1-1@2x.svg';
import SectionHeading from '../../modules/shared/components/SectionHeading/SectionHeading';

import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import {Image} from '../../interfaces/Image';
import ImageList from '../../modules/gallery-page-module/components/ImageList/ImageList';
import {useSelector} from 'react-redux';
import ConfirmModal from '../../modules/shared/components/ConfirmModal/ConfirmModal';
import {RootState} from '../../store/redux/store-config/globalStoreConfig';
import pencilIcon from '../../images/pencil.png';
import deleteInRecycleBinIcon from '../../images/delete-orange.png';
import deletePermanentIcon from '../../images/delete-red.png';
import addImagesIcon from '../../images/add-images.png';
import GalleryModificationModal
    from '../../modules/gallery-page-module/components/GalleryModificationModal/GalleryModificationModal';
import UpdateImagesModal, {
    AddImagesCtx
} from '../../modules/service-details-module/components/UpdateImagesModal/UpdateImagesModal';
import {useParams} from 'react-router';
import {GalleryImagesState} from '../../store/redux/gallery-images/interfaces';
import useGalleryImagesActions from '../../store/redux/gallery-images/useGalleryImages';
import {AuthState} from '../../store/redux/auth/interfaces';
import useGalleriesActions from '../../store/redux/galleries/useGalleries';
import {GalleriesState} from '../../store/redux/galleries/interfaces';
import {Gallery} from '../../@types/types';
import {Lang, useLang} from '../../store/context/lang-context';
import {toast, ToastContainer} from 'react-toastify';
import {GalleryModificationContext} from '../../modules/gallery-page-module/components/GalleryModificationModal/types';
import GalleryService from '../../modules/gallery-page-module/services/galleryService';
import {of, tap} from 'rxjs';
import {useNavigate} from 'react-router-dom';
import {catchError} from 'rxjs/operators';
import {debounce, toastObj} from '../../utils/utils';
import Loader from "../../modules/shared/components/Loader/Loader";

interface GalleryPageProps {
}

interface ConfirmModalState {
    isModalOpen: boolean;
    modalMessage: string;
    permanent: boolean;
}

const INITIAL_IMAGE_FETCH_SIZE: number = 12;
const START_PAGE: number = 1;

const GalleryPage: FC<GalleryPageProps> = () => {
    const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({
        isModalOpen: false,
        modalMessage: '',
        permanent: false,
    });
    const [isGalleryModificationModalOpen, setIsGalleryModificationModalOpen] = useState<boolean>(false);
    const [isAddImagesModalOpen, setIsAddImagesModalOpen] = useState<boolean>(false);
    const [images, setImages] = useState<Image[]>([]);
    const [gallery, setGallery] = useState<Gallery | null | undefined>(null);
    const [page, setPage] = useState(START_PAGE);
    const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);

    const {id} = useParams<{ id: string }>();
    const {fetchGalleryImages} = useGalleryImagesActions();
    const {fetchGalleries} = useGalleriesActions();
    const {lang} = useLang();
    const navigate = useNavigate();

    const userState: AuthState = useSelector((state: RootState) => state.persistedAuthData);
    const isUsrAdmin: boolean | null | undefined = userState.xxfh?.authData?.isAdmin;
    const isAnonymousUsr: boolean = !!userState.xxfh;

    const galleryImages: GalleryImagesState = useSelector((state: RootState) => state.galleryImages);
    const galleriesData: GalleriesState = useSelector((state: RootState) => state.galleriesData);

    const handleScroll = debounce(
        (): void => {
            if (!id) return;

            const {scrollTop, scrollHeight, clientHeight} = document.documentElement;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight * 0.6; // Adjust the scroll height threshold as needed

            if (isNearBottom && shouldFetchMoreImages(page, galleryImages.images[id]?.length || 0)) {
                const nextPage = page + 1;
                fetchGalleryImages(id, isAnonymousUsr, nextPage, INITIAL_IMAGE_FETCH_SIZE);
                setPage(nextPage);
            }
        },
        50,
        false
    )

    useEffect(() => {
        if (!id) return;

        const images: Image[] | undefined = galleryImages.images[id];

        if (!images && !galleryImages.loading && !galleryImages.error) {
            fetchGalleryImages(id, isAnonymousUsr, page, INITIAL_IMAGE_FETCH_SIZE);
        } else if (!!images) {
            setImages(images);
            setIsInitialLoadDone(true);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [galleryImages, id, isAnonymousUsr, page]);

    useEffect(() => {
        if (!galleriesData?.galleries && galleriesData?.loading === false && galleriesData?.error === null) {
            fetchGalleries();
            return;
        }

        const gallery: Gallery | undefined = galleriesData.galleries?.find((gallery: Gallery) => gallery.id === id);
        setGallery(gallery);
    }, [galleriesData]);

    const shouldFetchMoreImages = (currentPage: number, totalImages: number): boolean => {
        if (currentPage === 0 || totalImages === 0) return false;

        const imagesSize: number = currentPage * INITIAL_IMAGE_FETCH_SIZE;
        return imagesSize === totalImages;
    };

    const deleteGallery = (permanent: boolean) => {
        GalleryService.deleteGallery(id!, permanent)
            .pipe(
                tap((res) => {
                    if (res.status !== 200) throw new Error('Error deleting gallery');

                    toast.success('Галерията е изтрита успешно', toastObj);
                    fetchGalleries();
                    navigate(`/${lang}/galleries/${true}/Галерията е изтрита успешно`);
                    setConfirmModalState({isModalOpen: false, modalMessage: '', permanent: false});
                }),
                catchError((err) => {
                    toast.error('Грешка при изтриване на галерията', toastObj);
                    return of(err);
                })
            )
            .subscribe();
    };

    return (
        <section className={styles.GalleryPage} data-testid="GalleryPage">
            <ToastContainer/>

            {isUsrAdmin && (
                <div className={styles['edit-delete-wrapper']}>
                    <img
                        style={{
                            marginLeft: '11px',
                            width: '45px',
                        }}
                        onClick={() => {
                            setIsAddImagesModalOpen(true);
                        }}
                        src={addImagesIcon}
                        alt="add-images"
                    />

                    <img
                        onClick={() => {
                            setIsGalleryModificationModalOpen(true);
                        }}
                        src={pencilIcon}
                        alt="pencil"
                    />
                    <img
                        onClick={() => {
                            setConfirmModalState({
                                isModalOpen: true,
                                modalMessage: 'Сигурни ли сте, че преместите галерията в кошчето?',
                                permanent: false,
                            });
                        }}
                        src={deleteInRecycleBinIcon}
                        alt="deleteInRecycleBin"
                    />

                    <img
                        onClick={() => {
                            setConfirmModalState({
                                isModalOpen: true,
                                modalMessage: 'Сигурни ли сте, че искате да изтриете галерията завинаги?',
                                permanent: true,
                            });
                        }}
                        src={deletePermanentIcon}
                        alt="deletePermanent"
                    />
                    <ConfirmModal
                        isOpen={confirmModalState.isModalOpen}
                        onConfirm={() => {
                            deleteGallery(confirmModalState.permanent);
                        }}
                        confirmMessage={confirmModalState.modalMessage}
                        onModalClose={() => {
                            setConfirmModalState({isModalOpen: false, modalMessage: '', permanent: false});
                        }}
                    />
                    {isGalleryModificationModalOpen && (
                        <GalleryModificationModal
                            isModalOpen={isGalleryModificationModalOpen}
                            context={GalleryModificationContext.EDIT}
                            onClose={() => setIsGalleryModificationModalOpen(false)}
                            onSuccessClose={() => setIsGalleryModificationModalOpen(false)}
                            gallery={gallery}
                        />
                    )}

                    {isAddImagesModalOpen && (
                        <UpdateImagesModal
                            isModalOpen={isAddImagesModalOpen}
                            context={AddImagesCtx.GALLERY_IMAGES}
                            onModalClose={() => setIsAddImagesModalOpen(false)}
                            galleryId={id}
                            imagesToFetch={50}
                            imageUrlsToExclude={images.map((image) => image.url)}
                        />
                    )}
                </div>
            )}

            <div className={styles['section-heading-wrapper']}>
                <SectionHeading title={lang === Lang.bg ? gallery?.nameBG : gallery?.nameEN} sentence={undefined}
                                flowerSrc={flower}/>
            </div>

            {
                isInitialLoadDone
                    ? <ImageList images={images} isUserAdmin={isUsrAdmin} galleryId={id}/>
                    : <Loader />
            }
        </section>
    );
};

export default GalleryPage;
