import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {globalStore} from "../../../store/redux/store-config/globalStoreConfig";
import {AuthService} from "../../../modules/auth/services/authService";
import {Observable, tap} from "rxjs";
import {KeycloakLoginResBody} from "../../../modules/auth/interfaces/KeycloakLoginResBody";
import {GoogleLoginResBody} from "../../../modules/auth/interfaces/GoogleLoginResBody";
import {AuthProvider} from "../../../enums/enums";
import {AxiosInterceptor} from "../interfaces/AxiosInterceptor";
import {mapAuthData} from "../../../utils/utils";
import {AuthData} from "../../../modules/auth/interfaces/AuthData";
import {loginFailureAction, loginSuccessAction, logoutSuccessAction} from "../../../store/redux/auth/authReducers";
import {TokenContext} from "../../../store/redux/auth/enums";
import {catchError} from "rxjs/operators";

export const RequestTokenInterceptor: AxiosInterceptor = {
    onFulfilled: (config: AxiosRequestConfig) => {
        const {persistedAuthData} = globalStore.getState();

        let accessToken: string = persistedAuthData.xxfh.authData.accessToken;
        const refreshToken: string = persistedAuthData.xxfh.authData.refreshToken;
        const acsExpAt: number = persistedAuthData.xxfh.authData.acsExpAt;
        const authProvider: AuthProvider = persistedAuthData.xxfh.authProvider;

        let isExpired: boolean = acsExpAt < (Date.now() / 1000);

        if (isExpired) {
            const apiDeterminator: any = {
                [AuthProvider.KEYCLOAK]: (refreshToken: string) => refreshKeycloakAccessToken(refreshToken),
                [AuthProvider.GOOGLE]: (refreshToken: string) => refreshGoogleAccessToken(refreshToken)
            }

            apiDeterminator[authProvider](refreshToken)
                .pipe(
                    tap((res: AxiosResponse<KeycloakLoginResBody | GoogleLoginResBody>) => {
                        let authData: AuthData = mapAuthData(res.data, authProvider, TokenContext.REFRESH, refreshToken);

                        return globalStore.dispatch(loginSuccessAction({
                            authProvider,
                            authData,
                            tokenCtx: TokenContext.REFRESH,
                            error: null
                        }));
                    }),
                    catchError((error: AxiosError) => {
                            globalStore.dispatch(loginFailureAction({
                                authProvider,
                                authData: null,
                                tokenCtx: TokenContext.REFRESH,
                                error: error.message
                            }));

                            globalStore.dispatch(logoutSuccessAction({
                                authProvider: null,
                                keycloakReqBody: null,
                                googleReqBody: null,
                                error: error.message
                            }))

                            return Promise.reject(error);
                        }
                    )).subscribe();
        }

        if (accessToken) {
            config.headers!.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    onRejected: (error: AxiosError) => {
        console.error("RequestTokenInterceptor error: ", error)
        return Promise.reject(error);
    }
}

const refreshKeycloakAccessToken = (refreshToken: string): Observable<AxiosResponse<KeycloakLoginResBody>> => {
    return AuthService.refreshKeycloakAccessToken(refreshToken);
}

const refreshGoogleAccessToken = (refreshToken: string): Observable<AxiosResponse<GoogleLoginResBody>> => {
    return AuthService.refreshGoogleAccessToken(refreshToken);
}