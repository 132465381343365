import {useTranslation} from "react-i18next";

export default function useNavigationLocale () {
    const {t} = useTranslation();
    const NAV_HOMEPAGE = t('NAV_HOMEPAGE');
    const NAV_SERVICES = t('NAV_SERVICES');
    const NAV_COURSES = t('NAV_COURSES');
    const NAV_GALLERY = t('NAV_GALLERY');
    const NAV_ABOUT_ME = t('NAV_ABOUT_ME');
    const NAV_BLOG = t('NAV_BLOG');
    const APPOINTMENT_ACTION = t('APPOINTMENT_ACTION');
    const LANG_BG = t('LANG_BG');
    const LANG_EN = t('LANG_EN');
    const NAV_USER_MENU_LOGOUT = t('NAV_USER_MENU_LOGOUT');
    const NAV_USER_MENU_PROFILE = t('NAV_USER_MENU_PROFILE');
    const NAV_USER_MENU_LOGIN = t('NAV_USER_MENU_LOGIN');
    const NAV_USER_MENU_REGISTER = t('NAV_USER_MENU_REGISTER');

    return {
        NAV_HOMEPAGE,
        NAV_SERVICES,
        NAV_COURSES,
        NAV_GALLERY,
        NAV_ABOUT_ME,
        NAV_BLOG,
        APPOINTMENT_ACTION,
        LANG_BG,
        LANG_EN,
        NAV_USER_MENU_LOGOUT,
        NAV_USER_MENU_PROFILE,
        NAV_USER_MENU_LOGIN,
        NAV_USER_MENU_REGISTER
    }
}