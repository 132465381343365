import {Appointment} from "../../../interfaces/Appointment";
import {AxiosRequestHeaders, AxiosResponse} from "axios";
import {Observable} from "rxjs";
import {CONTENT_TYPE, CONTENT_TYPE_JSON} from "../../auth/constants/auth-constants";
import {_Axios} from "../../../services-config/axios/axios-conf";
import {AppointmentConfirmation} from "../../../@types/types";
import {CreateAppointmentReqData} from "../../../interfaces/CreateAppointmentReqData";

const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const baseUrl: string = `${apiHost}/api/appointment`;
const getAppointmentsEndPoint: string = `${baseUrl}/for_user`;
const getAllScheduledAppointmentsEndPoint: string = `${baseUrl}/all_scheduled`;
const getAllConfirmedAppointmentsEndPoint: string = `${baseUrl}/all_confirmed`;
const getAdminAppointmentsInquiriesEndPoint: string = `${baseUrl}/all_before`;
const cancelAppointmentEndpoint: string = `${apiHost}/api/appointment/cancel`;

const AppointmentService = {
    fetchAppointments: (limit: number, offset: number): Observable<AxiosResponse<Appointment[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.get(`${getAppointmentsEndPoint}?limit=${limit}&offset=${offset}`, requestOptions)
    },

    fetchAllIncomingAppointments: (limit: number, offset: number): Observable<AxiosResponse<Appointment[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.get(`${getAllConfirmedAppointmentsEndPoint}?limit=${limit}&offset=${offset}`, requestOptions)
    },

    fetchAdminAppointmentsInquiries: (limit: number, offset: number): Observable<AxiosResponse<Appointment[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.get(`${getAllScheduledAppointmentsEndPoint}?limit=${limit}&offset=${offset}`, requestOptions)
    },

    fetchAdminAppointmentsHistory: (date: string, limit: number, offset: number): Observable<AxiosResponse<Appointment[]>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.get(`${getAdminAppointmentsInquiriesEndPoint}?date=${date}&limit=${limit}&offset=${offset}`, requestOptions)
    },

    deleteAppointment: (id: string, isPermanentDel: boolean): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.delete(`${baseUrl}?appointment_id=${id}&permanent=${isPermanentDel}`, requestOptions)
    },

    cancelAppointment: (id: string): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.put(`${cancelAppointmentEndpoint}?appointment_id=${id}`, requestOptions)
    },

    confirmAppointment(id: string, startTime: string, endTime: string): Observable<AxiosResponse> {
        const body: AppointmentConfirmation = {
            startTime: startTime,
            endTime: endTime
        }

        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.AuthInstance.put(`${baseUrl}?appointment_id=${id}`, JSON.stringify(body), requestOptions)
    },

    createAppointment: (data: CreateAppointmentReqData, makeUpId: string): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            'Cache-Control': 'no-cache',
        }

        const requestOptions = {
            headers: headers,
        };

        return _Axios.DefInstance.post(`${baseUrl}?make-up_id=${makeUpId}`, JSON.stringify(data), requestOptions)
    }
};

export default AppointmentService;

Object.freeze(AppointmentService);