import React, {FC, FormEvent, MutableRefObject, useEffect, useRef, useState} from 'react';
import styles from './AppointmentPage.module.css';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import flower from "../../images/group-1-1@2x.svg";
import SectionHeading from "../../modules/shared/components/SectionHeading/SectionHeading";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import bgLocale from '../../localization/bg/countries-bg.json'
import useAppointmentLocale from "./AppointmentLocale";
import {
    formatDate,
    toastObj,
    validateDefInput,
    validateEmail,
    validatePhone,
    validateStreetNmr
} from "../../utils/utils";
import {ValidationContext} from "../../enums/enums";
import {useSelector} from "react-redux";
import {MakeUp} from "../../interfaces/MakeUp";
import {useMakeUpActions} from "../../store/redux/make-ups/useMakeUps";
import {MakeUpState} from "../../store/redux/make-ups/interfaces";
import {RootState} from "../../store/redux/store-config/globalStoreConfig";
import {User} from "../../modules/auth/interfaces/User";
import {Lang, useLang} from "../../store/context/lang-context";
import {Location} from '../../modules/auth/interfaces/Address';
import {AppointmentClientData} from "../../interfaces/ClientData";
import {CreateAppointmentReqData} from "../../interfaces/CreateAppointmentReqData";
import {of, Subject, takeUntil, tap} from "rxjs";
import AppointmentService from "../../modules/shared/services/AppointmentService";
import {catchError} from "rxjs/operators";
import ConfirmModal from "../../modules/shared/components/ConfirmModal/ConfirmModal";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import Loader from "../../modules/shared/components/Loader/Loader";

interface AppointmentPageProps {
}

const AppointmentPage: FC<AppointmentPageProps> = () => {
    const today = new Date();
    const [date, setDate] = useState<Date>(today)
    const [selectedDate, setSelectedDate] = useState<Date>(today)
    const [phone, setPhone] = useState<string>('');
    const [isServicesMenuOpen, setIsServicesMenuOpen] = useState<boolean>(false);
    const [areServicesVisible, setAreServicesVisible] = useState<boolean>(false);
    const [areCoursesVisible, setAreCoursesVisible] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<MakeUp | null>(null);
    const [isPhoneFocused, setIsPhoneFocused] = useState<boolean>(false);

    const makeUps: MakeUpState = useSelector((state: any) => state.makeUps);

    const [makeUpServices, setMakeUpServices] = useState<MakeUp[] | null>(makeUps.makeUpServices);
    const [makeUpCourses, setMakeUpCourses] = useState<MakeUp[] | null>(makeUps.makeUpCourses);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {lang} = useLang();
    const navigate = useNavigate();
    let {fetchMakeUpServices, fetchMakeUpCourses} = useMakeUpActions();

    const state = useSelector((state: RootState) => state.persistedAuthData);
    const user: User = state?.xxfh?.user;

    const locales = useAppointmentLocale();

    const destroy$ = new Subject();

    const APPOINTMENT_SERVICE_LABEL = locales.REACT_APP_APPOINTMENT_SERVICE_LABEL;
    const APPOINTMENT_SUCCESS_MSG = locales.APPOINTMENT_SUCCESS_MSG;
    const APPOINTMENT_ERROR_MSG = locales.APPOINTMENT_ERROR_MSG;

    const serviceRef = useRef() as MutableRefObject<HTMLInputElement>;
    const firstNameRef = useRef() as MutableRefObject<HTMLInputElement>;
    const lastNameRef = useRef() as MutableRefObject<HTMLInputElement>;
    const emailRef = useRef() as MutableRefObject<HTMLInputElement>;
    const cityRef = useRef() as MutableRefObject<HTMLInputElement>;
    const streetRef = useRef() as MutableRefObject<HTMLInputElement>;
    const streetNumberRef = useRef() as MutableRefObject<HTMLInputElement>;
    const postalCodeRef = useRef() as MutableRefObject<HTMLInputElement>;
    const buildingNumberRef = useRef() as MutableRefObject<HTMLInputElement>;
    const apartmentNumberRef = useRef() as MutableRefObject<HTMLInputElement>;
    const entranceRef = useRef() as MutableRefObject<HTMLInputElement>;
    const servicesMenuRef = useRef() as MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (user) {
            firstNameRef.current.value = user.firstname;
            lastNameRef.current.value = user.lastname;
            emailRef.current.value = user.email;
            setPhone(user.phone);

            if (user.address) {
                cityRef.current.value = user.address.city;
                streetRef.current.value = user.address.street;
                streetNumberRef.current.value = user.address.streetNumber;
                postalCodeRef.current.value = user.address.postalCode || "";
                buildingNumberRef.current.value = user.address.buildingNumber || "";
                apartmentNumberRef.current.value = user.address.apartmentNumber || "";
                entranceRef.current.value = user.address.entranceNumber || ""
            }
        }
    }, [user])

    useEffect(() => {
        if (makeUps.makeUpServices == null) {
            fetchMakeUpServices(50, 1,);
        }
    }, [makeUps.makeUpServices]);

    useEffect(() => {
        if (makeUps.makeUpCourses == null) {
            fetchMakeUpCourses(50, 1);
        }
    }, [makeUps.makeUpCourses]);

    useEffect(() => {
        setMakeUpServices(makeUps.makeUpServices);
        setMakeUpCourses(makeUps.makeUpCourses);
    }, [makeUps]);

    useEffect(() => {
        return () => {
            destroy$.next(true);
            destroy$.complete();
        }
    }, [selectedDate]);

    const validateService = (validationCtx: ValidationContext): boolean => {
        const isValid = selectedService ? selectedService?.id.trim().length > 0 : false;

        if (isValid) {
            serviceRef.current.classList.remove(styles["input-error"]);
        } else {
            validationCtx !== ValidationContext.ON_CHANGE && serviceRef.current.classList.add(styles["input-error"]);
            validationCtx === ValidationContext.ON_BLUR && toast.error(locales.APPOINTMENT_SERVICE_ERR_MSG, toastObj);
        }

        return isValid;
    };

    const selectService = (service: MakeUp): void => {
        setSelectedService(service);
        setIsServicesMenuOpen(false);
        setAreCoursesVisible(false);
        setAreServicesVisible(false);

        const classList: DOMTokenList = serviceRef.current.classList;

        classList.contains(styles["input-error"]) && classList.remove(styles["input-error"]);
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValidService: boolean = validateService(ValidationContext.ON_SUBMIT);
        const isValidFirstName: boolean = validateDefInput(firstNameRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isValidLastName: boolean = validateDefInput(lastNameRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isValidEmail: boolean = validateEmail(emailRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_MAIL);
        const isValidPhone: boolean = validatePhone(phone, ValidationContext.ON_SUBMIT);
        const isValidCity: boolean = validateDefInput(cityRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isValidStreet: boolean = validateDefInput(streetRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isValidStreetNumber: boolean = validateStreetNmr(streetNumberRef, styles, ValidationContext.ON_SUBMIT, locales.APPOINTMENT_PHONE_ERR_MSG);

        (isValidService &&
            isValidFirstName &&
            isValidLastName &&
            isValidEmail &&
            isValidPhone &&
            isValidCity &&
            isValidStreet &&
            isValidStreetNumber)
            ? createAppointment()
            : toast.error(APPOINTMENT_ERROR_MSG, toastObj);
    };

    const createAppointment = (): void => {
        setIsLoading(true);

        const appointmentLocation: Location = {
            city: cityRef.current.value,
            street: streetRef.current.value,
            streetNumber: streetNumberRef.current.value,
            postalCode: postalCodeRef.current.value,
            buildingNumber: buildingNumberRef.current.value,
            apartmentNumber: apartmentNumberRef.current.value,
            entranceNumber: entranceRef.current.value
        };

        const clientData: AppointmentClientData = {
            firstname: firstNameRef.current.value,
            lastname: lastNameRef.current.value,
            email: emailRef.current.value,
            phone: phone
        }

        const reqData: CreateAppointmentReqData = {
            date: formatDate(selectedDate),
            appointmentLocation,
            clientData,
        }

        AppointmentService.createAppointment(reqData, selectedService!.id).pipe(
            takeUntil(destroy$),
            tap((res) => {
                if (res.status !== 201) {
                    throw new Error("Error creating appointment");
                }

                setIsModalOpen(true);
                clearForm();
            }),
            catchError((err: Error) => {
                toast.error(APPOINTMENT_ERROR_MSG, toastObj);
                return of(err);
            })
        ).subscribe(() => setIsLoading(false));
    }

    const clearForm = () => {
        setPhone("");
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        cityRef.current.value = "";
        streetRef.current.value = "";
        streetNumberRef.current.value = "";
        postalCodeRef.current.value = "";
        buildingNumberRef.current.value = "";
        apartmentNumberRef.current.value = "";
        entranceRef.current.value = "";
    }

    return (
        <section className={styles.AppointmentPage} data-testid="AppointmentPage">
            <ToastContainer/>

            {isLoading && <Loader/>}
            <div className={styles["section-heading-wrapper"]}>
                <SectionHeading title={locales.PAGE_TITLE} flowerSrc={flower}
                                sentence={locales.APPOINTMENT_SENTENCE}/>
            </div>

            <Calendar
                locale={'bg-BG'}
                minDate={today}
                onChange={setSelectedDate}
                value={date}
                onClickDay={setDate}/>

            <div className={styles["form-container"]}>
                <p style={{textAlign: "center", width: "100%", padding: "10px", marginBottom: "20px"}}
                   className={"montserrat-normal-outer-space-17px"}>
                    {locales.APPOINTMENT_REQUESTED_LABEL}
                </p>

                <form onSubmit={onSubmit} autoComplete={'true'} id={"appointment-form"} className={styles.form}>
                    <div tabIndex={0} className={styles["select-service-wrapper"]}
                         onBlur={() => validateService(ValidationContext.ON_BLUR)}>
                        <p ref={serviceRef}
                           onClick={() => {
                               setIsServicesMenuOpen(!isServicesMenuOpen);
                               areServicesVisible && setAreServicesVisible(false);
                               areCoursesVisible && setAreCoursesVisible(false);
                           }}
                           className={`${styles["service-select-title"]} montserrat-normal-outer-space-15px`}>
                            {selectedService
                                ? lang === Lang.bg
                                    ? selectedService.titleBG
                                    : selectedService.titleEN
                                : APPOINTMENT_SERVICE_LABEL + " *"}
                            <span>&#10148;</span>
                        </p>

                        <div ref={servicesMenuRef}
                             style={{
                                 display: isServicesMenuOpen ? "block" : "none",
                             }}
                             className={styles["first-step-menu-wrapper"]}>
                            <div className={`${styles.entry} montserrat-normal-outer-space-15px`}>
                                 <span className={styles.close}
                                       onClick={() => {
                                           setIsServicesMenuOpen(false);
                                           areServicesVisible && setAreServicesVisible(false);
                                           areCoursesVisible && setAreCoursesVisible(false);
                                       }}
                                 >
                            &#10005;
                        </span>
                                <p className={styles["entry-first-child"]}
                                   onClick={() => {
                                       setAreServicesVisible(!areServicesVisible)
                                       areCoursesVisible && setAreCoursesVisible(false);
                                   }}
                                >
                                    &nbsp;Мейкъп &nbsp;&nbsp;&#10148;&nbsp;
                                </p>
                                <div className={styles["services-wrapper"]}
                                     style={{
                                         display: areServicesVisible ? "block" : "none",
                                     }}>
                                    {
                                        makeUpServices && makeUpServices.map((makeUp) => {
                                            return (
                                                <p onClick={() => selectService(makeUp)}
                                                   key={makeUp.id}
                                                   className={styles.children}>&nbsp; &#8627; &nbsp; {lang === Lang.bg ? makeUp.titleBG : makeUp.titleEN}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`${styles.entry} montserrat-normal-outer-space-15px`}>
                                <p className={styles["entry-first-child"]}
                                   onClick={() => {
                                       setAreCoursesVisible(!areCoursesVisible);
                                       areServicesVisible && setAreServicesVisible(false);
                                   }}
                                >
                                    &nbsp;Курсове &nbsp;&nbsp;&#10148;&nbsp;
                                </p>
                                <div className={styles["courses-wrapper"]}
                                     style={{
                                         display: areCoursesVisible ? "block" : "none",
                                     }}>
                                    {
                                        makeUpCourses && makeUpCourses.map((makeUp) => {
                                            return (
                                                <p onClick={() => selectService(makeUp)}
                                                   key={makeUp.id}
                                                   className={styles.children}>&nbsp; &#8627; &nbsp; {lang === Lang.bg ? makeUp.titleBG : makeUp.titleEN}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <input ref={firstNameRef}
                           name={"firstname"}
                           id={"firstname_input"}
                           onBlur={() => validateDefInput(firstNameRef, styles, ValidationContext.ON_BLUR, t("DEF_INPUT_VLD_MSG_ERR", {length: 2}))}
                           onChange={() => validateDefInput(firstNameRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type={"text"}
                           placeholder={locales.APPOINTMENT_FIRSTNAME_LABEL + "*"}/>

                    <input ref={lastNameRef}
                           onBlur={() => validateDefInput(lastNameRef, styles, ValidationContext.ON_BLUR, t("DEF_INPUT_VLD_MSG_ERR", {length: 2}))}
                           onChange={() => validateDefInput(lastNameRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_LASTNAME_LABEL + "*"}/>

                    <ReactPhoneInput
                        inputClass={`${styles.input}  montserrat-normal-pumice-15px`}
                        placeholder={locales.REACT_APP_APPOINTMENT_PHONE_LABEL + "*"}
                        inputProps={{
                            name: "phone",
                            required: true,
                        }}
                        onBlur={() => validatePhone(phone, ValidationContext.ON_BLUR, locales.PROFILE_PHONE_INVALID_ERR_MSG)}
                        localization={bgLocale}
                        onFocus={() => setIsPhoneFocused(true)}
                        country={'bg'}
                        value={phone}
                        onChange={setPhone}
                    />

                    <input ref={emailRef}
                           onBlur={() => validateEmail(emailRef, styles, ValidationContext.ON_BLUR, locales.LOGIN_EMAIL_ERR_MSG)}
                           onChange={() => validateEmail(emailRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="email"
                           placeholder={locales.REACT_APP_APPOINTMENT_MAIL_LABEL + "*"}/>

                    <input ref={cityRef}
                           onBlur={() => validateDefInput(cityRef, styles, ValidationContext.ON_BLUR, t("DEF_INPUT_VLD_MSG_ERR", {length: 2}))}
                           onChange={() => validateDefInput(cityRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_CITY_LABEL + "*"}/>

                    <input ref={streetRef}
                           onBlur={() => validateDefInput(streetRef, styles, ValidationContext.ON_BLUR, t("DEF_INPUT_VLD_MSG_ERR", {length: 2}))}
                           onChange={() => validateDefInput(streetRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_STREET_LABEL + "*"}/>

                    <input ref={streetNumberRef}
                           onBlur={() => validateStreetNmr(streetNumberRef, styles, ValidationContext.ON_BLUR, t("DEF_INPUT_VLD_MSG_ERR", {length: 4}))}
                           onChange={() => validateStreetNmr(streetNumberRef, styles, ValidationContext.ON_CHANGE)}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_STREET_NUMBER_LABEL + "*"}/>

                    <input ref={postalCodeRef}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_POSTAL_CODE_LABEL}/>

                    <input ref={buildingNumberRef}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_BUILDING_NUMBER_LABEL}/>

                    <input ref={apartmentNumberRef}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_APARTMENT_NUMBER_LABEL}/>

                    <input ref={entranceRef}
                           className={`${styles.input}  montserrat-normal-pumice-15px`}
                           type="text"
                           placeholder={locales.REACT_APP_APPOINTMENT_ENTRANCE_LABEL}/>

                    <button
                        type={'submit'}
                        className={`${styles.submit} item12 montserrat-normal-white-15px`}
                    >
                        {locales.PAGE_TITLE}
                    </button>
                </form>
            </div>

            <ConfirmModal
                isOpen={isModalOpen}
                onConfirm={() => {
                    setIsModalOpen(false)
                    navigate("/")
                }}
                onModalClose={() => setIsModalOpen(false)}
                confirmMessage={APPOINTMENT_SUCCESS_MSG}
            />
        </section>
    );
}

export default AppointmentPage;
