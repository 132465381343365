import React, {FC, FormEvent, useEffect, useState} from 'react';
import styles from './Register.module.css';
import {Link, useNavigate} from "react-router-dom";
import hideIcon from "../../../images/hide-icon.png";
import showIcon from "../../../images/show-icon.png";
import {useLang} from "../../../store/context/lang-context";
import useAuthLocale from "../../../pages/AuthPage/AuthLocale";
import {toast, ToastContainer} from 'react-toastify';
import {Gender, ValidationContext} from "../../../enums/enums";
import {
    arePasswdsEqual,
    generalValidatorResolver,
    onChangeValidatorResolver,
    toastObj,
    validateDefInput,
    validateEmail,
    validatePassword
} from "../../../utils/utils";
import {Trans} from "react-i18next";
import {RegisterReqBody} from "../interfaces/RegisterReqBody";
import {AuthService} from "../services/authService";
import {of, Subject, takeUntil, tap, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import ReactModal from "react-modal";
import Loader from "../../shared/components/Loader/Loader";

interface RegisterProps {
}

const Register: FC<RegisterProps> = () => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false);
    const [shouldShowRepeatPassword, setShouldShowRepeatPassword] = useState(false);
    const [isTocAccepted, setIsTocAccepted] = useState(true);
    const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const locales = useAuthLocale();
    const firstNameRef = React.useRef<HTMLInputElement>(null);
    const lastNameRef = React.useRef<HTMLInputElement>(null);
    const usernameRef = React.useRef<HTMLInputElement>(null);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const confirmEmailRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const showPasswdRef = React.useRef<HTMLImageElement>(null);
    const confirmPasswordRef = React.useRef<HTMLInputElement>(null);
    const toc = React.useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const destroy$ = new Subject<void>();

    const {lang} = useLang();

    useEffect(() => {
        return () => {
            destroy$.next();
            destroy$.complete();
        };
    }, []);

    const areEmailsEqual = (validationCtx?: ValidationContext): boolean => {
        const crrConfEmailVal = confirmEmailRef.current?.value.trim();
        const crrEmailVal = emailRef.current?.value.trim();

        const isValid = crrConfEmailVal !== '' && crrEmailVal === crrConfEmailVal;

        validationCtx === ValidationContext.ON_BLUR || validationCtx === ValidationContext.ON_SUBMIT
            ? generalValidatorResolver(isValid, confirmEmailRef, validationCtx, locales.REGISTER_ERROR_CONFIRM_MAIL, styles)
            : onChangeValidatorResolver(isValid, confirmEmailRef, styles);

        return isValid;
    }

    const onSubmit = (e: FormEvent): void => {
        e.preventDefault();

        const isFirstNameValid = validateDefInput(firstNameRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isLastNameValid = validateDefInput(lastNameRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isUsernameValid = validateDefInput(usernameRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_NAME);
        const isEmailValid = validateEmail(emailRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_MAIL);
        const isConfirmEmailValid = areEmailsEqual(ValidationContext.ON_SUBMIT);
        const isPasswordValid = validatePassword(ValidationContext.ON_SUBMIT, passwordRef, styles);
        const isConfirmPasswordValid = arePasswdsEqual(passwordRef, confirmPasswordRef, styles, ValidationContext.ON_SUBMIT, locales.REGISTER_ERROR_CONFIRM_PASSWORD);
        const isTocAccepted = toc.current?.checked;

        if (!isTocAccepted) {
            setIsTocAccepted(false);
            return;
        }

        isFirstNameValid
        && isLastNameValid
        && isUsernameValid
        && isEmailValid
        && isConfirmEmailValid
        && isPasswordValid
        && isConfirmPasswordValid
            ? doRegister()
            : toast.error(locales.REGISTER_ERROR_MSG, toastObj);

    }

    const regConlfMsgDeterminator: any = {
        "User with email and username already exists": () => {
            toast.error(locales.REGISTER_USER_EXIST, toastObj);
            emailRef.current?.classList.add(styles["input-error"]);
            confirmEmailRef.current?.classList.add(styles["input-error"]);
            usernameRef.current?.classList.add(styles["input-error"]);
        },
        "User exists with same email": () => {
            toast.error(locales.REGISTER_USER_EXIST_BY_EMAIL, toastObj);
            emailRef.current?.classList.add(styles["input-error"]);
            confirmEmailRef.current?.classList.add(styles["input-error"]);
        },
        "User exists with same username": () => {
            toast.error(locales.REGISTER_USER_EXIST_BY_USERNAME, toastObj);
            usernameRef.current?.classList.add(styles["input-error"]);
        },
    }

    const doRegister = (): void => {
        setIsLoading(true);

        const body: RegisterReqBody = {
            username: usernameRef.current!.value.trim(),
            credentials: [{
                type: "password",
                value: passwordRef.current!.value.trim(),
                temporary: false
            }],
            enabled: false,
            totp: false,
            emailVerified: false,
            firstName: firstNameRef.current!.value.trim(),
            lastName: lastNameRef.current!.value.trim(),
            email: emailRef.current!.value.trim(),
            requiredActions: [],
            notBefore: 0,
            access: {
                manageGroupMembership: false,
                view: true,
                mapRoles: true,
                impersonate: false,
                manage: true
            },
            realmRoles: ["user"],
            attributes: {
                gender: [Gender.OTHER]
            },
        }


        AuthService.register(body).pipe(
            tap(res => {
                if (res.status === 201) {
                    firstNameRef.current!.value = '';
                    lastNameRef.current!.value = '';
                    usernameRef.current!.value = '';
                    emailRef.current!.value = '';
                    confirmEmailRef.current!.value = '';
                    passwordRef.current!.value = '';
                    confirmPasswordRef.current!.value = '';
                    toc.current!.checked = false;

                    toast.success(locales.REGISTER_SUCCESS_MSG, toastObj);
                    setIsLoading(false);
                    setIsRegistrationSuccessful(true);
                } else {
                    throwError(() => new Error('Registration failed'));
                }
            }),
            takeUntil(destroy$),
            catchError(err => {
                setIsLoading(false);

                if (err.response.status === 409) {
                    regConlfMsgDeterminator[err.response.data.message]();
                    return of({})
                }

                passwordRef.current!.value = '';
                confirmPasswordRef.current!.value = '';

                toast.error(locales.REGISTER_NOT_POSSIBLE, toastObj);
                return of({})
            })
        ).subscribe();
    }

    return (
        <div data-testid={'Register'}>
            <ToastContainer/>
            {isLoading && <Loader/>}
            <div className={styles["login-sentence"]}>
                <p className={"montserrat-medium-outer-space-15px"}>{locales.REGISTER_SENTENCE}&nbsp;</p>
                <Link to={`/${lang}/login`}
                      className={`${styles["sign-in-link"]} montserrat-medium-outer-space-15px`}>{locales.REGISTER_LOGIN_BTN}</Link>
            </div>
            <div className={styles["register-form-container"]}>

                <form autoComplete={"true"} onSubmit={onSubmit}>
                    <div className={styles["register-form"]}>
                        <input ref={firstNameRef}
                               autoComplete={"first-name"}
                               type="text"
                               placeholder={`${locales.REGISTER_FIRSTNAME_LABEL}*`}
                               className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                               onBlur={() => validateDefInput(firstNameRef, styles, ValidationContext.ON_BLUR, locales.REGISTER_ERROR_NAME)}
                               onChange={() => validateDefInput(firstNameRef, styles, ValidationContext.ON_CHANGE)}/>

                        <input ref={lastNameRef}
                               autoComplete={"last-name"}
                               type="text"
                               placeholder={`${locales.REGISTER_LASTNAME_LABEL}*`}
                               className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                               onBlur={() => validateDefInput(lastNameRef, styles, ValidationContext.ON_BLUR, locales.REGISTER_ERROR_NAME)}
                               onChange={() => validateDefInput(lastNameRef, styles, ValidationContext.ON_CHANGE)}/>

                        <input ref={emailRef}
                               autoComplete={"email"}
                               type={"email"}
                               placeholder={`${locales.REGISTER_MAIL_LABEL}*`}
                               className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                               onBlur={() => validateEmail(emailRef, styles, ValidationContext.ON_BLUR, locales.REGISTER_ERROR_MAIL)}
                               onChange={() => validateEmail(emailRef, styles, ValidationContext.ON_CHANGE)}/>

                        <input ref={confirmEmailRef}
                               type={"text"}
                               placeholder={`${locales.REGISTER_CONFIRM_MAIL_LABEL}*`}
                               className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                               onBlur={() => areEmailsEqual(ValidationContext.ON_BLUR)}
                               onChange={() => areEmailsEqual(ValidationContext.ON_CHANGE)}/>

                        <div className={styles["password-wrapper"]}>
                            <input ref={passwordRef}
                                   name={"password"}
                                   type={shouldShowPassword ? "text" : "password"}
                                   placeholder={`${locales.REGISTER_PASSWORD_LABEL}*`}
                                   className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                                   onBlur={() => validatePassword(ValidationContext.ON_BLUR, passwordRef, styles)}
                                   onChange={() => validatePassword(ValidationContext.ON_CHANGE, passwordRef, styles)}/>

                            <img onClick={() => {
                                setShouldShowPassword(!shouldShowPassword)
                            }}
                                 ref={showPasswdRef}
                                 src={shouldShowPassword ? hideIcon : showIcon}
                                 alt="show-hide"
                                 className={styles["show-hide-icon"]}/>
                        </div>

                        <div className={styles["repeat-password-wrapper"]}>
                            <input ref={confirmPasswordRef}
                                   type={shouldShowRepeatPassword ? "text" : "password"}
                                   placeholder={`${locales.REGISTER_PASSWORD_CONFIRM_LABEL}*`}
                                   className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                                   onBlur={() => arePasswdsEqual(passwordRef, confirmPasswordRef, styles, ValidationContext.ON_BLUR, locales.REGISTER_ERROR_CONFIRM_PASSWORD)}
                                   onChange={() => arePasswdsEqual(passwordRef, confirmPasswordRef, styles, ValidationContext.ON_CHANGE)}/>

                            <img onClick={() => setShouldShowRepeatPassword(!shouldShowRepeatPassword)}
                                 src={shouldShowRepeatPassword ? hideIcon : showIcon}
                                 alt="show-hide"
                                 className={styles["show-hide-icon"]}/>
                        </div>

                        <input ref={usernameRef}
                               type={"text"}
                               placeholder={`${locales.REGISTER_USERNAME}*`}
                               className={`${styles["register-input"]} montserrat-medium-outer-space-15px`}
                               onBlur={() => validateDefInput(usernameRef, styles, ValidationContext.ON_BLUR, locales.REGISTER_ERROR_NAME)}
                               onChange={() => validateDefInput(usernameRef, styles, ValidationContext.ON_CHANGE)}/>
                        <div
                            className={`${styles["toc-wrapper"]} ${!isTocAccepted && styles["toc-error"]} montserrat-normal-midnight-10px`}>
                            <input ref={toc} type={"checkbox"}
                                   className={styles["toc-checkbox"]}
                                   onChange={() => setIsTocAccepted(true)}
                            />
                            <Trans
                                i18nKey={'REGISTER_TOC_ACCEPT'}
                                className={styles["toc-text"]}
                                components={{
                                    toc: <Link to={`/${lang}/terms-and-conditions`} className={styles["toc-link"]}></Link>,
                                    policy: <Link to={`/${lang}/privacy-policy`} className={styles["toc-link"]}></Link>
                                }}
                            >
                            </Trans>
                        </div>
                    </div>


                    <button type={"submit"}
                            className={`${styles["register-submit"]} montserrat-medium-outer-space-17px`}>
                        {locales.REGISTER_BTN}
                    </button>
                </form>
            </div>

            <ReactModal
                isOpen={isRegistrationSuccessful}
                className={styles.modal}
                overlayClassName={styles.overlay}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={300}
                preventScroll={false}
                parentSelector={() => document.body}
            >
                <p className={`${styles["confirm-sentence"]} montserrat-normal-outer-space-17px`}>
                    Изпратихме ви имейл за потвърждение. Моля, проверете входящата си поща и потвърдете регистрацията
                    си.
                </p>

                <div className={styles["actions-wrapper"]}>
                    <button
                        onClick={() => {
                            setIsRegistrationSuccessful(false);
                            navigate(`/${lang}/`)
                        }}
                        className={`${styles["action-icon"]} montserrat-normal-outer-space-15px`}>
                        Към началната страница
                    </button>

                    <button
                        onClick={() => {
                            setIsRegistrationSuccessful(false);
                            navigate(`/${lang}/login`)
                        }}
                        className={`${styles["action-icon"]} montserrat-normal-outer-space-15px`}>
                        Към логин страницата
                    </button>
                </div>
            </ReactModal>
        </div>
    );
}

export default Register;