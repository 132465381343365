import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/redux/store-config/globalStoreConfig';
import { User } from '../../../auth/interfaces/User';
import { fetchUserReqAction, logoutReqAction } from '../../../../store/redux/auth/authReducers';
import { SESSION_ID } from '../../../../common/constants';
import { useCookies } from 'react-cookie';
import { cookieRememberMe } from '../../../../cookies/constants';

interface UserStateMappingProps {
  children?: React.ReactNode;
}

const UserStateMapping: FC<UserStateMappingProps> = (props) => {
  const [cookie] = useCookies([cookieRememberMe]);
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.persistedAuthData);
  const authData = useSelector((state: RootState) => state.authData);

  const user: User = state?.xxfh?.user;

  useEffect(() => {
    const sessionId = sessionStorage.getItem(SESSION_ID);
    const rememberMe: boolean = cookie[cookieRememberMe] ? JSON.parse(cookie[cookieRememberMe]) : false;

    if (!!authData?.error) {
      return;
    }

    if (sessionId === null && state.xxfh && !rememberMe) {
      dispatch(
        logoutReqAction({
          authProvider: state.xxfh?.authProvider,
          keycloakReqBody: null,
          googleReqBody: null,
          error: null,
        })
      );

      return;
    }

    if (state.xxfh && !state.xxfh.user && state.loading === false) {
      dispatch(
        fetchUserReqAction({
          authProvider: state.xxfh?.authProvider,
          user: null,
          error: null,
        })
      );
    }
  }, [state.loading, state.xxfh, user]);

  useEffect(() => {}, []);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default UserStateMapping;
