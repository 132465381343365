import React, {FC, useState} from 'react';
import styles from './GalleryBox.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import arrow from "../../../../images/expand-right-3@2x.png";
import {useLang} from "../../../../store/context/lang-context";
import MiniLoader from "../Loader/MiniLoader";
import {t} from "i18next";

interface GalleryBoxProps {
    id: string;
    title: string;
    mainImage: string;
    imageCount: number;
}

const GalleryBox: FC<GalleryBoxProps> = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const {lang} = useLang();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div
            className={styles.GalleryBox}
            data-testid="GalleryBox"
            onClick={() => navigate(`/${lang}/gallery/${props.id}`, {state: {from: location}})}
        >
            {!isLoaded && <MiniLoader/>}
            <div style={{display: isLoaded ? 'inherit' : 'none'}}>
                <img className={styles["main-image"]}
                     src={props.mainImage} alt={"make-up"}
                     onLoad={() => setIsLoaded(true)}
                />
                <div className={styles["info-wrapper"]}>
                    <h3 className={"arnopro-caption-normal-black-25px"}>{props.title}</h3>
                    <p className={"montserrat-normal-mine-shaft-17px"}>{props.imageCount}&nbsp;снимки</p>
                    <div className={styles["show-more-wrapper"]}>
                        <p className={"montserrat-normal-mine-shaft-17px"}>{t('SHOW_MORE')}</p>
                        <img className={styles.arrow} src={arrow} alt={"arrow"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GalleryBox;
