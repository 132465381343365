import React, {FC, useState} from 'react';
import styles from './ConfirmModal.module.css';
import ReactModal from "react-modal";
import confirmIcon from "../../../../images/confirm.svg";
import cancelIcon from "../../../../images/cancel.svg";
import {ModalProps} from "../../../../interfaces/ModalProps";
import useDocumentBodyOverflowForModal from "../../../../hooks/body-overflow";

interface ConfirmModalProps extends ModalProps {
}

/**
 * @param props {ConfirmModalProps} - props for the component
 *
 * @info:  Consider using a useState hook to control the isOpen prop in the parent component. So you can manipulate the modal isOpen by setting the state the onConfirm and onModalClose functions.
 *
 * @example
 *     const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
 *     <ConfirmModal
 *         isOpen={isConfirmationModalOpen}
 *         onConfirm={() => {
 *             toast.success(locales.PROFILE_APPOINTMENTS_SIDE_NAV_CANCEL_SUCCESS, toastObj);
 *             setIsConfirmationModalOpen(false);
 *         }}
 *         confirmMessage={locales.PROFILE_APPOINTMENTS_CANCEL_CONFIRM}
 *         onModalClose={() => setIsConfirmationModalOpen(false)}
 *     />
 */
const ConfirmModal: FC<ConfirmModalProps> = (props) => {
    const {
        onConfirm,
        onModalClose,
        confirmMessage,
        isOpen
    } = props;

    const [shouldAnimateOnClose, setShouldAnimateOnClose] = useState(false);
    useDocumentBodyOverflowForModal(props.isOpen);

    const onModalCloseRequest = (callback: Function | null | undefined) => {
        setShouldAnimateOnClose(true);

        setTimeout(() => {
            callback && callback();
            setTimeout(() => setShouldAnimateOnClose(false), 325);
        }, 500);
    }

    return (
        <ReactModal
            isOpen={isOpen}
            className={`${styles.modal} animate__animated ${!shouldAnimateOnClose && 'animate__zoomInDown'} ${shouldAnimateOnClose && 'animate__zoomOutRight'}`}
            overlayClassName={`${styles.overlay} animate__animated ${!shouldAnimateOnClose && 'animate__zoomIn'} ${shouldAnimateOnClose && 'animate__zoomOut'}`}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            preventScroll={false}
            parentSelector={() => document.body}
        >
            <p className={`${styles["confirm-sentence"]} montserrat-normal-outer-space-17px`}>{confirmMessage}</p>

            <div className={styles["actions-wrapper"]}>
                <img
                    onClick={() => onModalCloseRequest(onConfirm)}
                    className={styles["action-icon"]}
                    src={confirmIcon}
                    alt={"confirm"}/>

                <img
                    onClick={() => onModalCloseRequest(onModalClose)}
                    className={styles["action-icon"]}
                    src={cancelIcon}
                    alt={"close"}/>
            </div>
        </ReactModal>
    );
}

export default ConfirmModal;
