import Axios from 'axios-observable';
import { RequestTokenInterceptor } from '../interceptors/request/RequestTokenInteceptor';

const DefInstance = Axios.create({});
const AuthInstance = Axios.create({});

AuthInstance.interceptors.request.use(RequestTokenInterceptor.onFulfilled, RequestTokenInterceptor.onRejected);

export const _Axios = {
  DefInstance,
  AuthInstance,
};
