import {
  addSelectedImagesToVisitedMakeUpServiceAction,
  addVisitedMakeUpServiceAction,
  clearMakeUpFromStateAction,
  fetchMakeUpCoursesReqAction,
  fetchMakeUpServicesReqAction,
  removeGalleryFromVisitedMakeUpServiceAction,
  replaceSelectedImagesInVisitedMakeUpServiceAction,
  replaceVisitedMakeUpServiceAction,
  updateMainImageInVisitedMakeUpServiceAction,
  updateMakeUpMainImageAction,
} from './reducers';
import { useDispatch } from 'react-redux';
import { MakeUpType } from '../../../enums/enums';
import { MakeUpFullData } from '../../../interfaces/MakeUp';
import { Image } from '../../../interfaces/Image';

export const useMakeUpActions = () => {
  const dispatch = useDispatch();

  const fetchMakeUpServices = (limit: number, offset: number) => {
    dispatch(
      fetchMakeUpServicesReqAction({
        reqRsltLimit: limit,
        reqRsltOffset: offset,
        makeUpType: MakeUpType.SERVICE,
      })
    );
  };

  const fetchMakeUpCourses = (limit: number, offset: number) => {
    dispatch(
      fetchMakeUpCoursesReqAction({
        reqRsltLimit: limit,
        reqRsltOffset: offset,
        makeUpType: MakeUpType.COURSE,
      })
    );
  };

  const addVisitedMakeUpService = (makeUpService: MakeUpFullData) => {
    dispatch(
      addVisitedMakeUpServiceAction({
        makeUpService,
      })
    );
  };

  const replaceVisitedMakeUpService = (makeUpService: MakeUpFullData) => {
    dispatch(
      replaceVisitedMakeUpServiceAction({
        makeUpService,
      })
    );
  };

  const addSelectedImagesToMakeUpService = (images: Image[], makeUpId: string) => {
    dispatch(
      addSelectedImagesToVisitedMakeUpServiceAction({
        images,
        makeUpId,
      })
    );
  };

  const replaceSelectedImagesInMakeUpService = (images: Image[], makeUpId: string) => {
    dispatch(
      replaceSelectedImagesInVisitedMakeUpServiceAction({
        images,
        makeUpId,
      })
    );
  };

  const updateMakeUpMainImageUrlInVisitedMakeUpService = (makeUpId: string, imageUrl: string) => {
    dispatch(
      updateMainImageInVisitedMakeUpServiceAction({
        makeUpId,
        imageUrl,
      })
    );
  };

  const updateMakeUpMainImageUrl = (makeUpId: string, imageUrl: string, makeUpType: MakeUpType) => {
    dispatch(
      updateMakeUpMainImageAction({
        makeUpId,
        imageUrl,
        makeUpType,
      })
    );
  };

  const removeGalleryFromVisitedMakeUpService = (makeUpId: string, galleryId: string) => {
    dispatch(
      removeGalleryFromVisitedMakeUpServiceAction({
        makeUpId,
        galleryId,
      })
    );
  };

  const clearMakeUpFromState = (makeUpId: string, makeUpType: MakeUpType) => {
    dispatch(
      clearMakeUpFromStateAction({
        makeUpId,
        makeUpType,
      })
    );
  };
  return {
    fetchMakeUpServices,
    fetchMakeUpCourses,
    addVisitedMakeUpService,
    addSelectedImagesToMakeUpService,
    replaceVisitedMakeUpService,
    replaceSelectedImagesInMakeUpService,
    updateMakeUpMainImageUrlInVisitedMakeUpService,
    updateMakeUpMainImageUrl,
    removeGalleryFromVisitedMakeUpService,
    clearMakeUpFromState,
  };
};
