import {createContext, useContext} from 'react';

export type AuthLoaderContextType = {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

export const AuthLoaderContext = createContext<AuthLoaderContextType>({
    isLoading: false, setIsLoading: isLoading => console.warn('no theme provider')
});

export const useAuthLoader = () => useContext(AuthLoaderContext);