import {
    ClearDeletedGalleryImagesAct, ClearGalleryImagesStateAct,
    FetchGalleryImagesFailAct,
    FetchGalleryImagesReqAct,
    FetchGalleryImagesScssAct,
    GalleryImagesState, UpdateImageLikesAct
} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";

const initialState: GalleryImagesState = {
    images: {},
    loading: false,
    fetched: false,
    error: null,
};

const fetchGalleryImagesReq: CaseReducer<GalleryImagesState, FetchGalleryImagesReqAct> = (state: GalleryImagesState, action) => {
    state.loading = true;
    state.fetched = false;
    state.error = null;
};

const fetchGalleryImagesSuccess: CaseReducer<GalleryImagesState, FetchGalleryImagesScssAct> = (state: GalleryImagesState, action) => {
    if (action.payload.page === 1) {
        state.images = {
            ...state.images,
            [action.payload.galleryId]: action.payload.images
        };
    } else if (action.payload.images.length !== 0) {
        state.images = {
            ...state.images,
            [action.payload.galleryId]: [
                ...state.images[action.payload.galleryId],
                ...action.payload.images
            ]
        };
    }
    state.loading = false;
    state.fetched = true;
    state.error = null;
};

const fetchGalleryImagesFailure: CaseReducer<GalleryImagesState, FetchGalleryImagesFailAct> = (state: GalleryImagesState, action) => {
    state.loading = false;
    state.fetched = true;
    state.error = action.payload.error || null;
};

const clearDeletedGalleryImages: CaseReducer<GalleryImagesState, ClearDeletedGalleryImagesAct> = (state: GalleryImagesState, action) => {
    state.images = {
        ...state.images,
        [action.payload.galleryId]: state.images[action.payload.galleryId]
            .filter(image => !action.payload.imageIds.includes(image.id))
    };
}

const updateImageLikes: CaseReducer<GalleryImagesState, UpdateImageLikesAct> = (state: GalleryImagesState, action) => {
    state.images = {
        ...state.images,
        [action.payload.galleryId]: state.images[action.payload.galleryId]
            .map(image => {
                if (image.id === action.payload.imageId) {
                    return {
                        ...image,
                        likesCount: action.payload.likes,
                        hasCurrentUserLiked: action.payload.hasCrrUserLiked
                    }
                }

                return image;
            })
    };
}

const clearGalleryImagesState: CaseReducer<GalleryImagesState, ClearGalleryImagesStateAct> = (state: GalleryImagesState, action) => {
    return initialState;
}

export const GalleryImagesSlice = createSlice({
    name: "galleryImages",
    initialState,
    reducers: {
        fetchGalleryImagesReq,
        fetchGalleryImagesSuccess,
        fetchGalleryImagesFailure,
        clearDeletedGalleryImages,
        updateImageLikes,
        clearGalleryImagesState
    }
});

export const {
    fetchGalleryImagesReq: fetchGalleryImagesReqAction,
    fetchGalleryImagesSuccess: fetchGalleryImagesSuccessAction,
    fetchGalleryImagesFailure: fetchGalleryImagesFailureAction,
    clearDeletedGalleryImages: clearDeletedGalleryImagesAction,
    updateImageLikes: updateImageLikesAction,
    clearGalleryImagesState: clearGalleryImagesStateAction
} = GalleryImagesSlice.actions;