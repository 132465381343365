import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Lang, useLang } from '../../../../store/context/lang-context';
import { useCookies } from 'react-cookie';
import i18n from 'i18next';
import { cookieLang } from '../../../../cookies/constants';

const LangDeterminator = (props: any) => {
  const [cookie, setCookie] = useCookies([cookieLang]);
  const [langCookie, setLangCookie] = useState(cookie.lang);
  const [isLangCookieSet, setIsLangCookieSet] = useState(false);
  const [langUrl, setLangUrl] = useState<string | null>(null);
  const location = useLocation();
  const { lang, setLang } = useLang();

  useEffect(() => {
    if (!isLangCookieSet) {
      !cookie.lang &&
        setCookie(cookieLang, Lang.bg, {
          path: '/',
          sameSite: 'strict',
          expires: getCookieExpDate(),
        });

      setLangCookie(cookie.lang);
      setIsLangCookieSet(true);

      i18n.changeLanguage(langCookie).then();
    }
  }, [cookie.lang, isLangCookieSet, langCookie, langUrl, setCookie]);

  useEffect(() => {
    const setLangFuncObj: any = {
      bg: () => {
        setLang(Lang.bg);
        setCookie(cookieLang, Lang.bg, {
          path: '/',
          sameSite: 'strict',
          expires: getCookieExpDate(),
        });
        setLangFuncObj.translate(Lang.bg);
      },
      en: () => {
        setLang(Lang.en);
        setCookie(cookieLang, Lang.en, {
          path: '/',
          sameSite: 'strict',
          expires: getCookieExpDate(),
        });
        setLangFuncObj.translate(Lang.en);
      },
      translate: (lang: Lang) => {
        i18n.changeLanguage(lang).then(() => {});
      },
    };

    const lanUrl: string = location.pathname.split('/')[1];
    setLangUrl(lanUrl);

    if (lang === lanUrl) {
      return;
    }

    lanUrl === 'bg' || lanUrl === 'en' ? setLangFuncObj[lanUrl]() : setLangFuncObj['bg']();
  }, [location.pathname, lang, setLang, setCookie]);

  const getCookieExpDate = (): Date => {
    const expiresInDays = 10;
    const expirationDate = new Date();

    expirationDate.setDate(expirationDate.getDate() + expiresInDays);

    return expirationDate;
  };

  return <>{props.children}</>;
};
export default LangDeterminator;
