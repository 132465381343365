import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import styles from './MakeUpModificationModal.module.css';
import ReactModal from 'react-modal';
import { MakeUpType, ValidationContext } from '../../../../enums/enums';
import { isValidImageUpload, toastObj, validateDefInput, validatePriceInput } from '../../../../utils/utils';
import { t } from 'i18next';
import { Lang, useLang } from '../../../../store/context/lang-context';
import confirmIcon from '../../../../images/confirm.svg';
import cancelIcon from '../../../../images/cancel.svg';
import useServicesPageLocale from '../../../../pages/Services/ServicesPageLocale';
import { toast } from 'react-toastify';
import { Gallery, MakeUpData } from '../../../../@types/types';
import ImageService from '../../services/ImageService';
import { of, Subject, takeUntil, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import MakeUpService from '../../services/MakeUpService';
import { useMakeUpActions } from '../../../../store/redux/make-ups/useMakeUps';
import { MakeUpFullData } from '../../../../interfaces/MakeUp';
import { AxiosResponse } from 'axios';

export enum MakeUpModificationContext {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

interface MakeUpModificationModalProps {
  isModalOpen: boolean;
  context: MakeUpModificationContext;
  onClose: () => void;
  onSuccessClose: () => void;
  galleries?: Gallery[] | null;
  onSubmit?: (event?: FormEvent) => void;
  makeUpFullData?: MakeUpFullData | null;
}

const MakeUpModificationModal: FC<MakeUpModificationModalProps> = (props) => {
  const locales: any = useServicesPageLocale();
  const { lang } = useLang();
  const { fetchMakeUpCourses, fetchMakeUpServices, replaceVisitedMakeUpService } = useMakeUpActions();

  const [selectedServiceType, setSelectedServiceType] = useState<MakeUpType>(MakeUpType.SERVICE);
  const [selectedGalleries, setSelectedGalleries] = useState<Gallery[]>([]);
  const [isGalleryMenuOpen, setIsGalleryMenuOpen] = useState(false);
  const [imageUpload, setImageUpload] = useState<File | null>(null);
  const [shouldAnimateOnClose, setShouldAnimateOnClose] = useState(false);

  const titleBGRef = useRef<HTMLInputElement>(null);
  const titleENRef = useRef<HTMLInputElement>(null);
  const shortDescriptionBGRef = useRef<HTMLTextAreaElement>(null);
  const shortDescriptionENRef = useRef<HTMLTextAreaElement>(null);
  const descriptionBGRef = useRef<HTMLTextAreaElement>(null);
  const descriptionENRef = useRef<HTMLTextAreaElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const mainImageUrlRef = useRef<HTMLInputElement>(null);
  const imageDiv = useRef<HTMLButtonElement>(null);

  const firstStepSentenceBGRef = useRef<HTMLTextAreaElement>(null);
  const firstStepSentenceENRef = useRef<HTMLTextAreaElement>(null);
  const secondStepSentenceBGRef = useRef<HTMLTextAreaElement>(null);
  const secondStepSentenceENRef = useRef<HTMLTextAreaElement>(null);
  const thirdStepSentenceBGRef = useRef<HTMLTextAreaElement>(null);
  const thirdStepSentenceENRef = useRef<HTMLTextAreaElement>(null);
  const removalAdviceBGRef = useRef<HTMLTextAreaElement>(null);
  const removalAdviceENRef = useRef<HTMLTextAreaElement>(null);
  const removalFirstStepBGRef = useRef<HTMLTextAreaElement>(null);
  const removalFirstStepENRef = useRef<HTMLTextAreaElement>(null);
  const removalSecondStepBGRef = useRef<HTMLTextAreaElement>(null);
  const removalSecondStepENRef = useRef<HTMLTextAreaElement>(null);
  const removalThirdStepBGRef = useRef<HTMLTextAreaElement>(null);
  const removalThirdStepENRef = useRef<HTMLTextAreaElement>(null);

  const destroy$ = new Subject<void>();

  useEffect(() => {
    if (props.context != MakeUpModificationContext.EDIT || !props.makeUpFullData) {
      return;
    }

    const timer = setTimeout(() => {
      if (titleBGRef.current) titleBGRef.current.value = props.makeUpFullData!.titleBG;

      if (titleENRef.current) titleENRef.current.value = props.makeUpFullData!.titleEN;

      if (shortDescriptionBGRef.current) shortDescriptionBGRef.current.value = props.makeUpFullData?.shortDescriptionBG || '';

      if (shortDescriptionENRef.current) shortDescriptionENRef.current.value = props.makeUpFullData?.shortDescriptionEN || '';

      if (descriptionBGRef.current) descriptionBGRef.current.value = props.makeUpFullData?.descriptionBG || '';

      if (descriptionENRef.current) descriptionENRef.current.value = props.makeUpFullData?.descriptionEN || '';

      if (priceRef.current) priceRef.current.value = props.makeUpFullData?.price.toString() || '';

      if (firstStepSentenceBGRef.current) firstStepSentenceBGRef.current.value = props.makeUpFullData?.firstStepSentenceBG || '';

      if (firstStepSentenceENRef.current) firstStepSentenceENRef.current.value = props.makeUpFullData?.firstStepSentenceEN || '';

      if (secondStepSentenceBGRef.current) secondStepSentenceBGRef.current.value = props.makeUpFullData?.secondStepSentenceBG || '';

      if (secondStepSentenceENRef.current) secondStepSentenceENRef.current.value = props.makeUpFullData?.secondStepSentenceEN || '';

      if (thirdStepSentenceBGRef.current) thirdStepSentenceBGRef.current.value = props.makeUpFullData?.thirdStepSentenceBG || '';

      if (thirdStepSentenceENRef.current) thirdStepSentenceENRef.current.value = props.makeUpFullData?.thirdStepSentenceEN || '';

      if (removalAdviceBGRef.current) removalAdviceBGRef.current.value = props.makeUpFullData?.removalAdviceBG || '';

      if (removalAdviceENRef.current) removalAdviceENRef.current.value = props.makeUpFullData?.removalAdviceEN || '';

      if (removalFirstStepBGRef.current) removalFirstStepBGRef.current.value = props.makeUpFullData?.removalFirstStepBG || '';

      if (removalFirstStepENRef.current) removalFirstStepENRef.current.value = props.makeUpFullData?.removalFirstStepEN || '';

      if (removalSecondStepBGRef.current) removalSecondStepBGRef.current.value = props.makeUpFullData?.removalSecondStepBG || '';

      if (removalSecondStepENRef.current) removalSecondStepENRef.current.value = props.makeUpFullData?.removalSecondStepEN || '';

      if (removalThirdStepBGRef.current) removalThirdStepBGRef.current.value = props.makeUpFullData?.removalThirdStepBG || '';

      if (removalThirdStepENRef.current) removalThirdStepENRef.current.value = props.makeUpFullData?.removalThirdStepEN || '';
    }, 50);

    return () => {
      clearTimeout(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  useEffect(() => {
    return () => {
      destroy$.next();
      destroy$.complete();
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];

      if (isValidImageUpload(file, imageDiv, styles, locales.SELECT_VALID_MAIN_IMG)) {
        setImageUpload(file);
      } else {
        toast.error(locales.SELECT_VALID_MAIN_IMG, toastObj);
        event.target.value = '';
      }
    }
  };

  const galleryOptions = props.galleries?.map((gallery) => {
    const isGallerySelected = (galleryId: string) => {
      return selectedGalleries.includes(props.galleries?.find((g) => g.id === galleryId)!);
    };

    return (
      <p
        key={gallery.id}
        className={`${styles['menu-item']} ${isGallerySelected(gallery.id) && styles['menu-item-selected']}`}
        onClick={() => {
          isGallerySelected(gallery.id)
            ? setSelectedGalleries(selectedGalleries.filter((g) => g.id !== gallery.id))
            : setSelectedGalleries([...selectedGalleries, gallery]);
        }}
      >
        {lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
      </p>
    );
  });

  const handleSubmit = (event?: FormEvent) => {
    event?.preventDefault();

    const isValidTitleBG = validateDefInput(
      titleBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 2 })
    );

    const isValidTitleEN = validateDefInput(
      titleENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 2 })
    );

    const isValidShortDescriptionBG = validateDefInput(
      shortDescriptionBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 4 })
    );

    const isValidShortDescriptionEN = validateDefInput(
      shortDescriptionENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 4 })
    );

    const isValidDescriptionBG = validateDefInput(
      descriptionBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidDescriptionEN = validateDefInput(
      descriptionENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidPrice = validatePriceInput(priceRef, styles, ValidationContext.ON_SUBMIT, locales.PRICE_INPUT_VLD_MSG_ERR);

    const isValidMainImageUrl =
      props.context === MakeUpModificationContext.CREATE
        ? isValidImageUpload(imageUpload, imageDiv, styles, 'Моля прикачете валидна главна снимка на услугата.')
        : true;

    const isValidFirstStepSentenceBG = validateDefInput(
      firstStepSentenceBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidFirstStepSentenceEN = validateDefInput(
      firstStepSentenceENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidSecondStepSentenceBG = validateDefInput(
      secondStepSentenceBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidSecondStepSentenceEN = validateDefInput(
      secondStepSentenceENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidThirdStepSentenceBG = validateDefInput(
      thirdStepSentenceBGRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidThirdStepSentenceEN = validateDefInput(
      thirdStepSentenceENRef,
      styles,
      ValidationContext.ON_SUBMIT,
      t('DEF_INPUT_VLD_MSG_ERR', { length: 10 })
    );

    const isValidRemovalAdviceBG =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalAdviceBGRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalAdviceEN =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalAdviceENRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalFirstStepBG =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalFirstStepBGRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalFirstStepEN =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalFirstStepENRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalSecondStepBG =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalSecondStepBGRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalSecondStepEN =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalSecondStepENRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalThirdStepBG =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalThirdStepBGRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    const isValidRemovalThirdStepEN =
      selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE
        ? validateDefInput(removalThirdStepENRef, styles, ValidationContext.ON_SUBMIT, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }))
        : true;

    isValidTitleBG &&
      isValidTitleEN &&
      isValidShortDescriptionBG &&
      isValidShortDescriptionEN &&
      isValidDescriptionBG &&
      isValidDescriptionEN &&
      isValidPrice &&
      isValidMainImageUrl &&
      isValidFirstStepSentenceBG &&
      isValidFirstStepSentenceEN &&
      isValidSecondStepSentenceBG &&
      isValidSecondStepSentenceEN &&
      isValidThirdStepSentenceBG &&
      isValidThirdStepSentenceEN &&
      isValidRemovalAdviceBG &&
      isValidRemovalAdviceEN &&
      isValidRemovalFirstStepBG &&
      isValidRemovalFirstStepEN &&
      isValidRemovalSecondStepBG &&
      isValidRemovalSecondStepEN &&
      isValidRemovalThirdStepBG &&
      isValidRemovalThirdStepEN &&
      submitForm();
  };

  function clearForm() {
    setSelectedServiceType(MakeUpType.SERVICE);
    setSelectedGalleries([]);
    setImageUpload(null);
    titleBGRef.current!.value = '';
    titleENRef.current!.value = '';
    shortDescriptionBGRef.current!.value = '';
    shortDescriptionENRef.current!.value = '';
    descriptionBGRef.current!.value = '';
    descriptionENRef.current!.value = '';
    priceRef.current!.value = '';
    mainImageUrlRef.current!.value = '';
    firstStepSentenceBGRef.current!.value = '';
    firstStepSentenceENRef.current!.value = '';
    secondStepSentenceBGRef.current!.value = '';
    secondStepSentenceENRef.current!.value = '';
    thirdStepSentenceBGRef.current!.value = '';
    thirdStepSentenceENRef.current!.value = '';
    removalAdviceBGRef.current!.value = '';
    removalAdviceENRef.current!.value = '';
    removalFirstStepBGRef.current!.value = '';
    removalFirstStepENRef.current!.value = '';
    removalSecondStepBGRef.current!.value = '';
    removalSecondStepENRef.current!.value = '';
    removalThirdStepBGRef.current!.value = '';
    removalThirdStepENRef.current!.value = '';
  }

  const submitForm = () => {
    const formData: MakeUpData = {
      type: selectedServiceType,
      titleBG: titleBGRef.current!.value,
      titleEN: titleENRef.current!.value,
      shortDescriptionBG: shortDescriptionBGRef.current!.value,
      shortDescriptionEN: shortDescriptionENRef.current!.value,
      descriptionBG: descriptionBGRef.current!.value,
      descriptionEN: descriptionENRef.current!.value,
      firstStepSentenceBG: firstStepSentenceBGRef.current!.value,
      firstStepSentenceEN: firstStepSentenceENRef.current!.value,
      secondStepSentenceBG: secondStepSentenceBGRef.current!.value,
      secondStepSentenceEN: secondStepSentenceENRef.current!.value,
      thirdStepSentenceBG: thirdStepSentenceBGRef.current!.value,
      thirdStepSentenceEN: thirdStepSentenceENRef.current!.value,
      removalAdviceBG: removalAdviceBGRef.current?.value || null,
      removalAdviceEN: removalAdviceENRef.current?.value || null,
      removalFirstStepBG: removalFirstStepBGRef.current?.value || null,
      removalFirstStepEN: removalFirstStepENRef.current?.value || null,
      removalSecondStepBG: removalSecondStepBGRef.current?.value || null,
      removalSecondStepEN: removalSecondStepENRef.current?.value || null,
      removalThirdStepBG: removalThirdStepBGRef.current?.value || null,
      removalThirdStepEN: removalThirdStepENRef.current?.value || null,
      price: parseFloat(priceRef.current!.value),
      mainImageUrl: props.context === MakeUpModificationContext.CREATE ? mainImageUrlRef.current!.value : null,
      galleryIds: props.context === MakeUpModificationContext.CREATE ? selectedGalleries!.map((g) => g.id) : null,
      isUpdated: props.context === MakeUpModificationContext.CREATE ? false : true,
    };

    props.context === MakeUpModificationContext.CREATE ? createMakeUp(formData) : updateMakeUp(formData, props.makeUpFullData!.id);
  };

  const errorHandler = (err: Error) => {
    toast.error(err.message, toastObj);
    return of(err);
  };

  const createMakeUp = (formData: MakeUpData) => {
    ImageService.uploadImage(imageUpload!)
      .pipe(
        switchMap((res) => {
          formData.mainImageUrl = res.data;

          return MakeUpService.createMakeUp(formData).pipe(
            tap((res) => {
              if (res.status !== 201) {
                throw new Error(`Error creating makeup, server responded with status ${res.status}`);
              }

              selectedServiceType === MakeUpType.SERVICE ? fetchMakeUpServices(50, 1) : fetchMakeUpCourses(50, 1);

              props.onSuccessClose();
              clearForm();
            })
          );
        }),
        takeUntil(destroy$),
        catchError(errorHandler)
      )
      .subscribe();
  };

  const updateMakeUp = (formData: MakeUpData, makeUpId: string) => {
    MakeUpService.updateMakeUpData(makeUpId, formData)
      .pipe(
        tap((res: AxiosResponse<MakeUpFullData>) => {
          if (res.status !== 200) {
            throw new Error(`Error updating makeup, server responded with status ${res.status}`);
          }

          selectedServiceType === MakeUpType.SERVICE ? fetchMakeUpServices(50, 1) : fetchMakeUpCourses(50, 1);
          replaceVisitedMakeUpService(res.data);

          props.onSuccessClose();
        }),
        takeUntil(destroy$),
        catchError(errorHandler)
      )
      .subscribe();
  };

  const onModalCloseRequest = (callback: Function | null | undefined) => {
    setShouldAnimateOnClose(true);

    setTimeout(() => {
      callback && callback();
      setTimeout(() => setShouldAnimateOnClose(false), 800);
    }, 500);
  };

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      className={`${styles.modal} animate__animated ${!shouldAnimateOnClose && 'animate__bounceInLeft'} ${
        shouldAnimateOnClose && 'animate__bounceOutRight'
      }`}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={300}
      preventScroll={false}
      parentSelector={() => document.body}
    >
      <form className={styles.form} onSubmit={props.onSubmit}>
        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_TITLE_BG_LABEL}
          <input
            onBlur={() => validateDefInput(titleBGRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 2 }))}
            onChange={() => validateDefInput(titleBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.input}
            type="text"
            ref={titleBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_TITLE_EN_LABEL}
          <input
            onBlur={() => validateDefInput(titleENRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 2 }))}
            onChange={() => validateDefInput(titleENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.input}
            type="text"
            ref={titleENRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_PRICE_LABEL}
          <input
            onBlur={() => validatePriceInput(priceRef, styles, ValidationContext.ON_BLUR, locales.PRICE_INPUT_VLD_MSG_ERR)}
            onChange={() => validatePriceInput(priceRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.input}
            type="number"
            step="0.01"
            ref={priceRef}
          />
        </label>

        {props.context === MakeUpModificationContext.CREATE && (
          <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
            <input
              style={{ display: 'none' }}
              onChange={handleFileChange}
              className={styles.input}
              type="file"
              ref={mainImageUrlRef}
            />
            <button
              type="button"
              ref={imageDiv}
              className={`${styles['upload-btn']} montserrat-normal-outer-space-17px`}
              onClick={() => mainImageUrlRef.current?.click()}
            >
              {locales.SERVICES_FORM_IMG_LABEL}
            </button>
            <p className={styles.image}>{imageUpload?.name}</p>
          </label>
        )}

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_TYPE_LABEL}
          <select
            defaultValue={props.context === MakeUpModificationContext.CREATE ? MakeUpType.SERVICE : props.makeUpFullData?.type}
            className={`${styles.select} montserrat-normal-outer-space-17px`}
            onChange={(e) => setSelectedServiceType(e.target.value as MakeUpType)}
          >
            <option value={MakeUpType.SERVICE}>{locales.SERVICES_MAKEUP_LABEL}</option>
            <option value={MakeUpType.COURSE}>{locales.SERVICES_COURSE_LABEL}</option>
          </select>
        </label>

        {props.context === MakeUpModificationContext.CREATE && (
          <div className={`${styles.label} montserrat-normal-outer-space-17px`}>
            <label>{locales.SERVICES_FORM_GALLERY_LABEL}</label>

            <div
              onClick={() => setIsGalleryMenuOpen(!isGalleryMenuOpen)}
              className={`${styles.select} ${styles['galleries-toggle']} montserrat-normal-outer-space-17px`}
            >
              Галерии:
            </div>

            <div
              style={{
                display: isGalleryMenuOpen ? 'block' : 'none',
              }}
              className={`${styles['services-menu']} montserrat-normal-outer-space-17px`}
            >
              <span className={`${styles.remove} ${styles['float-right']}`} onClick={() => setIsGalleryMenuOpen(false)}>
                &#x274C;
              </span>
              {galleryOptions}
            </div>
          </div>
        )}

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_SHORT_DESC_BG_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(shortDescriptionBGRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 4 }), 4)
            }
            onChange={() => validateDefInput(shortDescriptionBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={shortDescriptionBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_SHORT_DESC_EN_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(shortDescriptionENRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 4 }), 4)
            }
            onChange={() => validateDefInput(shortDescriptionENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={shortDescriptionENRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_DESC_BG_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(descriptionBGRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }), 10)
            }
            onChange={() => validateDefInput(descriptionBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={descriptionBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_FORM_DESC_EN_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(descriptionENRef, styles, ValidationContext.ON_BLUR, t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }), 10)
            }
            onChange={() => validateDefInput(descriptionENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={descriptionENRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_ONE_BG_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                firstStepSentenceBGRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(firstStepSentenceBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={firstStepSentenceBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_ONE_EN_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                firstStepSentenceENRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(firstStepSentenceENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={firstStepSentenceENRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_TWO_BG_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                secondStepSentenceBGRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(secondStepSentenceBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={secondStepSentenceBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_TWO_EN_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                secondStepSentenceENRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(secondStepSentenceENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={secondStepSentenceENRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_THREE_BG_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                thirdStepSentenceBGRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(thirdStepSentenceBGRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={thirdStepSentenceBGRef}
          />
        </label>

        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
          {locales.SERVICES_STEP_THREE_EN_LABEL}
          <textarea
            onBlur={() =>
              validateDefInput(
                thirdStepSentenceENRef,
                styles,
                ValidationContext.ON_BLUR,
                t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                10
              )
            }
            onChange={() => validateDefInput(thirdStepSentenceENRef, styles, ValidationContext.ON_CHANGE)}
            className={styles.textarea}
            ref={thirdStepSentenceENRef}
          />
        </label>

        {selectedServiceType === MakeUpType.SERVICE && props.makeUpFullData?.type !== MakeUpType.COURSE && (
          <React.Fragment>
            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_BG_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalAdviceBGRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalAdviceBGRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalAdviceBGRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_EN_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalAdviceENRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalAdviceENRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalAdviceENRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_ONE_BG_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalFirstStepBGRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalFirstStepBGRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalFirstStepBGRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_ONE_EN_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalFirstStepENRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalFirstStepENRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalFirstStepENRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_TWO_BG_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalSecondStepBGRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalSecondStepBGRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalSecondStepBGRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_TWO_EN_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalSecondStepENRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalSecondStepENRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalSecondStepENRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_THREE_BG_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalThirdStepBGRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalThirdStepBGRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalThirdStepBGRef}
              />
            </label>

            <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
              {locales.SERVICES_REMOVAL_STEP_THREE_EN_LABEL}
              <textarea
                onBlur={() =>
                  validateDefInput(
                    removalThirdStepENRef,
                    styles,
                    ValidationContext.ON_BLUR,
                    t('DEF_INPUT_VLD_MSG_ERR', { length: 10 }),
                    10
                  )
                }
                onChange={() => validateDefInput(removalThirdStepENRef, styles, ValidationContext.ON_CHANGE)}
                className={styles.textarea}
                ref={removalThirdStepENRef}
              />
            </label>
          </React.Fragment>
        )}
        <button type={'submit'} style={{ display: 'none' }} />
      </form>

      <div className={`${styles['selected-gallery-wrapper']} montserrat-normal-outer-space-17px`}>
        {selectedGalleries.map((gallery, index) => {
          return (
            <p key={gallery.id} className={styles['selected-gallery-name']}>
              {lang === Lang.bg ? gallery.nameBG : gallery.nameEN}
              <span
                className={styles.remove}
                onClick={() => setSelectedGalleries(selectedGalleries.filter((g) => g.id !== gallery.id))}
              >
                &#x274C;
              </span>
            </p>
          );
        })}
      </div>

      <div className={styles['actions-wrapper']}>
        <img onClick={() => onModalCloseRequest(handleSubmit)} className={styles['action-icon']} src={confirmIcon} alt={'confirm'} />

        <img onClick={() => onModalCloseRequest(props.onClose)} className={styles['action-icon']} src={cancelIcon} alt={'close'} />
      </div>
    </ReactModal>
  );
};

export default MakeUpModificationModal;
