import {useTranslation} from "react-i18next";

export default function useAppointmentLocale() {
    const {t} = useTranslation();
        const PAGE_TITLE = t('APPOINTMENT_TITLE');
        const APPOINTMENT_FIRSTNAME_LABEL = t('APPOINTMENT_FIRSTNAME_LABEL');
        const REACT_APP_APPOINTMENT_LASTNAME_LABEL = t('APPOINTMENT_LASTNAME_LABEL');
        const REACT_APP_APPOINTMENT_PHONE_LABEL = t('APPOINTMENT_PHONE_LABEL');
        const REACT_APP_APPOINTMENT_MAIL_LABEL = t('APPOINTMENT_MAIL_LABEL');
        const REACT_APP_APPOINTMENT_CITY_LABEL = t('APPOINTMENT_CITY_LABEL');
        const REACT_APP_APPOINTMENT_STREET_LABEL = t('APPOINTMENT_STREET_LABEL');
        const REACT_APP_APPOINTMENT_STREET_NUMBER_LABEL = t('APPOINTMENT_STREET_NUMBER_LABEL');
        const REACT_APP_APPOINTMENT_POSTAL_CODE_LABEL = t('APPOINTMENT_POSTAL_CODE_LABEL');
        const REACT_APP_APPOINTMENT_BUILDING_NUMBER_LABEL = t('APPOINTMENT_BUILDING_NUMBER_LABEL');
        const REACT_APP_APPOINTMENT_APARTMENT_NUMBER_LABEL = t('APPOINTMENT_APARTMENT_NUMBER_LABEL');
        const REACT_APP_APPOINTMENT_ENTRANCE_LABEL = t('APPOINTMENT_ENTRANCE_LABEL');
        const REACT_APP_APPOINTMENT_SERVICE_LABEL = t('APPOINTMENT_SERVICE_LABEL');
        const APPOINTMENT_REQUESTED_LABEL = t('APPOINTMENT_REQUESTED_LABEL');
        const APPOINTMENT_SENTENCE = t('APPOINTMENT_SENTENCE');
        const APPOINTMENT_SUCCESS_MSG = t('APPOINTMENT_SUCCESS_MSG');
        const APPOINTMENT_ERROR_MSG = t('APPOINTMENT_ERROR_MSG');
        const APPOINTMENT_PHONE_ERR_MSG = t('APPOINTMENT_PHONE_ERR_MSG');
        const APPOINTMENT_STREET_NUMBER_ERR_MSG = t('APPOINTMENT_STREET_NUMBER_ERR_MSG');
        const REGISTER_ERROR_NAME = t('REGISTER_ERROR_NAME');
        const REGISTER_ERROR_MAIL = t('REGISTER_ERROR_MAIL');
        const APPOINTMENT_SERVICE_ERR_MSG = t('APPOINTMENT_SERVICE_ERR_MSG');
        const LOGIN_EMAIL_ERR_MSG = t('LOGIN_EMAIL_ERR_MSG');
        const PROFILE_PHONE_INVALID_ERR_MSG = t('PROFILE_PHONE_INVALID_ERR_MSG');

    return {
        PAGE_TITLE,
        APPOINTMENT_FIRSTNAME_LABEL,
        REACT_APP_APPOINTMENT_LASTNAME_LABEL,
        REACT_APP_APPOINTMENT_PHONE_LABEL,
        REACT_APP_APPOINTMENT_MAIL_LABEL,
        REACT_APP_APPOINTMENT_CITY_LABEL,
        REACT_APP_APPOINTMENT_STREET_LABEL,
        REACT_APP_APPOINTMENT_STREET_NUMBER_LABEL,
        REACT_APP_APPOINTMENT_POSTAL_CODE_LABEL,
        REACT_APP_APPOINTMENT_BUILDING_NUMBER_LABEL,
        REACT_APP_APPOINTMENT_APARTMENT_NUMBER_LABEL,
        REACT_APP_APPOINTMENT_ENTRANCE_LABEL,
        REACT_APP_APPOINTMENT_SERVICE_LABEL,
        APPOINTMENT_REQUESTED_LABEL,
        APPOINTMENT_SENTENCE,
        APPOINTMENT_SUCCESS_MSG,
        APPOINTMENT_ERROR_MSG,
        APPOINTMENT_PHONE_ERR_MSG,
        APPOINTMENT_STREET_NUMBER_ERR_MSG,
        REGISTER_ERROR_NAME,
        REGISTER_ERROR_MAIL,
        APPOINTMENT_SERVICE_ERR_MSG,
        LOGIN_EMAIL_ERR_MSG,
        PROFILE_PHONE_INVALID_ERR_MSG
    }
}

