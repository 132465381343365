import {catchError, filter, map, switchMap} from "rxjs/operators";
import {
    appointmentHistoryFailureAction,
    appointmentHistoryReqAction, appointmentHistorySuccessAction,
    appointmentInquiriesFailureAction,
    appointmentInquiriesReqAction, appointmentInquiriesSuccessAction, clearAdminAppointmentsStateAction,
    incomingAppointmentsFailureAction,
    incomingAppointmentsReqAction,
    incomingAppointmentsSuccessAction
} from "./reducers";
import {
    FetchAppointmentHistoryReqAct,
    FetchAppointmentInquiriesReqAct,
    FetchIncomingAppointmentsReqAct
} from "./interfaces";
import AppointmentService from "../../../modules/shared/services/AppointmentService";
import {Appointment} from "../../../interfaces/Appointment";
import {AxiosResponse} from "axios";
import {of} from "rxjs";
import {logoutSuccessAction} from "../auth/authReducers";

export const fetchIncomingAppointmentsEpic = (action$: any) => action$.pipe(
    filter(incomingAppointmentsReqAction.match),
    switchMap((action: FetchIncomingAppointmentsReqAct) => {
        let reqRsltLimit: number = action.payload.reqRsltLimit || 10;
        let reqRsltOffset: number = action.payload.reqRsltOffset || 1;

        return AppointmentService.fetchAllIncomingAppointments(reqRsltLimit, reqRsltOffset).pipe(
            map((res: AxiosResponse<Appointment[]>) => {
                if (res.status !== 200) {
                    return {
                        type: incomingAppointmentsFailureAction.type,
                        payload: {
                            error: "Server error"
                        }
                    }
                }

                return {
                    type: incomingAppointmentsSuccessAction.type,
                    payload: {
                        incomingAppointments: res.data,
                        error: null
                    }
                }
            }),
            catchError(fetchIncomingAppointmentsErrorHandler)
        )
    }),
    catchError(fetchIncomingAppointmentsErrorHandler)
);

export const fetchAppointmentsInquiriesEpic = (action$: any) => action$.pipe(
    filter(appointmentInquiriesReqAction.match),
    switchMap((action: FetchAppointmentInquiriesReqAct) => {
        let reqRsltLimit = action.payload.reqRsltLimit || 10;
        let reqRsltOffset = action.payload.reqRsltOffset || 1;

        return AppointmentService.fetchAdminAppointmentsInquiries(reqRsltLimit, reqRsltOffset).pipe(
            map((res: AxiosResponse<Appointment[]>) => {
                    if (res.status !== 200) {
                        return {
                            type: appointmentInquiriesFailureAction.type,
                            payload: {
                                error: "Server error"
                            }
                        }
                    }

                    return {
                        type: appointmentInquiriesSuccessAction.type,
                        payload: {
                            appointmentInquiries: res.data,
                            error: null
                        }
                    }
                }
            ),
            catchError(fetchAppointmentsInquiriesErrorHandler)
        )
    }),
    catchError(fetchAppointmentsInquiriesErrorHandler)
);

export const fetchAdminAppointmentsHistoryEpic = (action$: any) => action$.pipe(
    filter(appointmentHistoryReqAction.match),
    switchMap((action: FetchAppointmentHistoryReqAct) => {
        let reqRsltLimit = action.payload.reqRsltLimit || 10;
        let reqRsltOffset = action.payload.reqRsltOffset || 1;
        let date = action.payload.date;

        return AppointmentService.fetchAdminAppointmentsHistory(date, reqRsltLimit, reqRsltOffset).pipe(
            map((res: AxiosResponse<Appointment[]>) => {
                if (res.status !== 200) {
                    return {
                        type: appointmentHistoryFailureAction.type,
                        payload: {
                            error: "Server error"
                        }
                    }
                }

                return {
                    type: appointmentHistorySuccessAction.type,
                    payload: {
                        appointmentHistory: res.data,
                    }
                }
            })
        )
    })
);

export const clearAdminAppointmentsEpic = (action$: any) => action$.pipe(
    filter(logoutSuccessAction.match),
    switchMap(() => {
        return of({
            type: clearAdminAppointmentsStateAction.type,
            payload: {}
        })
    })
)

const fetchIncomingAppointmentsErrorHandler = (error: Error) => {
    return of({
        type: incomingAppointmentsFailureAction.type,
        payload: {
            error: error.message
        }
    })
}

const fetchAppointmentsInquiriesErrorHandler = (error: Error) => {
    return of({
        type: appointmentInquiriesFailureAction.type,
        payload: {
            error: error.message
        }
    })
}