import {useDispatch} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";
import {
    clearDeletedGalleryImagesAction,
    clearGalleryImagesStateAction,
    fetchGalleryImagesReqAction,
    updateImageLikesAction
} from "./reducers";

const useGalleryImages = () => {
    const dispatch: Dispatch = useDispatch();

    const fetchGalleryImages = (galleryId: string, authenticated: boolean, page: number, limit: number) => {
        dispatch(fetchGalleryImagesReqAction({
            authenticated,
            galleryId,
            page,
            limit
        }));
    }

    const clearDeletedGalleryImages = (galleryId: string, imageIds: string[]) => {
        dispatch(clearDeletedGalleryImagesAction({
            galleryId,
            imageIds
        }));
    }

    const updateImageLikes = (galleryId: string, imageId: string, likes: number, hasCrrUserLiked: boolean) => {
        dispatch(updateImageLikesAction({
            galleryId,
            imageId,
            likes,
            hasCrrUserLiked
        }));
    }

    const clearState = () => {
        dispatch(clearGalleryImagesStateAction({}));
    }

    return {
        fetchGalleryImages,
        clearDeletedGalleryImages,
        updateImageLikes,
        clearImageState: clearState
    }
}

export default useGalleryImages;