import {useTranslation} from "react-i18next";

export default function useFooterLocale () {
    const {t} = useTranslation();
    const FIRST_SECTION_TITLE: string =t('FOOTER_FIRST_SECTION_TITLE');
    const FIRST_SECTION_SENTENCE: string =t('FOOTER_FIRST_SECTION_SHORT_SENTENCE');
    const FIRST_SECTION_EMAIL_SENTENCE: string =t('FOOTER_FIRST_SECTION_EMAIL_SENTENCE');
    const SENT_BTN_LABEL: string =t('FOOTER_FIRST_SECTION_BTN_LABEL');

    const SECOND_SECTION_TITLE: string =t('FOOTER_SECOND_SECTION_TITLE');
    const SECOND_SECTION_SENTENCE: string =t('FOOTER_SECOND_SECTION_SHORT_SENTENCE');
    const SECOND_SECTION_PHONE_NUMBER: string =t('FOOTER_SECOND_SECTION_PHONE_NUMBER');

    const SOCIAL_NETWORKS_SENTENCE: string =t('FOOTER_THIRD_SECTION_SOCIAL_NETWORKS');
    const INSTAGRAM_LINK: string =t('FOOTER_THIRD_SECTION_INSTAGRAM_LINK');
    const FACEBOOK_LINK: string =t('FOOTER_THIRD_SECTION_FACEBOOK_LINK');
    const ALL_RIGHTS_RESERVED: string =t('ALL_RIGHTS_RESERVED');
    const PRIVACY_POLICY: string =t('PRIVACY_POLICY');
    const GENERAL_TERMS: string =t('GENERAL_TERMS');
    const DOMAIN: string =t('DOMAIN');

    return{
        FIRST_SECTION_TITLE,
        FIRST_SECTION_SENTENCE,
        FIRST_SECTION_EMAIL_SENTENCE,
        SENT_BTN_LABEL,
        SECOND_SECTION_TITLE,
        SECOND_SECTION_SENTENCE,
        SECOND_SECTION_PHONE_NUMBER,
        SOCIAL_NETWORKS_SENTENCE,
        INSTAGRAM_LINK,
        FACEBOOK_LINK,
        ALL_RIGHTS_RESERVED,
        PRIVACY_POLICY,
        GENERAL_TERMS,
        DOMAIN
    }
}