import React from 'react';
import ReactDOM from 'react-dom/client';
import './localization/Locale';
import './index.css';
import App from './App';

window.addEventListener('error', function(e) {
    // check if the error message is the one you want to suppress
    if (e.message.includes('Cannot read properties of undefined (reading \'autoplay\')')) {
        e.preventDefault();
    }
}, false);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <App/>
);

