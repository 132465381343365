import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './LeaveReview.module.css';
import flower from '../../images/group-1-1@2x.svg';
import useLeaveReviewLocale from './locales';
import SectionHeading from '../../modules/shared/components/SectionHeading/SectionHeading';
import Rating from '../../modules/shared/components/Rating/Rating';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/redux/store-config/globalStoreConfig';
import { toastObj, validateDefInput } from '../../utils/utils';
import { ValidationContext } from '../../enums/enums';
import ReviewService from '../../modules/shared/services/ReviewService';
import { CreateReviewData } from '../../@types/types';
import { User } from '../../modules/auth/interfaces/User';
import { tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthState } from '../../store/redux/auth/interfaces';
import {t} from "i18next";

interface LeaveReviewProps {}

const LeaveReview: FC<LeaveReviewProps> = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
  const [userData, setUserData] = useState<User | null>(null);
  const [rating, setRating] = useState(0); // Moved rating state up to LeaveReview

  const locale = useLeaveReviewLocale();
  const reviewInput = useRef<HTMLTextAreaElement>(null);
  const firstNameInput = useRef<HTMLInputElement>(null);
  const lastNameInput = useRef<HTMLInputElement>(null);
  const startRef = useRef<HTMLDivElement>(null);

  const userStateData: AuthState = useSelector((state: RootState) => state.persistedAuthData);

  useEffect(() => {
    if (userStateData.xxfh?.user) {
      setUserData(userStateData.xxfh.user);
      setIsUserAuthenticated(true);
    }
  }, [userStateData]);

  useEffect(() => {
    if (!userStateData) {
      toast.error(t('REVIEW_NOT_LOGGED_IN_ERR'), toastObj);
    }
  }, []);

  const validateRating = () => {
    if (rating === 0) {
      toast.error(t('REVIEW_STAR_NOT_SELECTED'), toastObj);
      startRef.current?.classList.add(styles['err']);
      return false;
    }

    startRef.current?.classList.remove(styles['err']);
    return true;
  };

  const onFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!isUserAuthenticated) {
      toast.error(t('REVIEW_NOT_LOGGED_IN_ERR'), toastObj);
      return;
    }

    const isValidFirstName = validateDefInput(firstNameInput, styles, ValidationContext.ON_SUBMIT, locale.LEAVE_REVIEW_ERROR_NAME);
    const isValidLastName = validateDefInput(lastNameInput, styles, ValidationContext.ON_SUBMIT, locale.LEAVE_REVIEW_ERROR_NAME);
    const isValidReview = validateDefInput(reviewInput, styles, ValidationContext.ON_SUBMIT, locale.LEAVE_REVIEW_TEXT_ERR_MSG, 10);
    const isValidRating = validateRating();

    if (!isValidFirstName || !isValidLastName || !isValidReview || !isValidRating) {
      toast.error(locale.LEAVE_REVIEW_ERROR_MSG, toastObj);
      return;
    }

    const data: CreateReviewData = {
      rating: rating,
      content: reviewInput.current!.value,
      isUpdated: false,
    };

    ReviewService.createReview(data)
      .pipe(
        tap((res) => {
          if (res.status !== 201) throw new Error('Нещо се обърка при създаването на мнението ви');
          toast.success(locale.LEAVE_REVIEW_SUCCESS_MSG, toastObj);
          reviewInput.current!.value = '';
        }),
        catchError((err) => {
          toast.error(err.message, toastObj);
          return err;
        })
      )
      .subscribe();
  };

  return (
    <section className={styles.LeaveReview} data-testid="LeaveReview">
      <ToastContainer />
      <SectionHeading title={locale.LEAVE_REVIEW_TITLE} sentence={locale.LEAVE_REVIEW_SENTENCE} flowerSrc={flower} />

      <div className={styles['rating-wrapper']}>
        <label className={`${styles['rating-label']} montserrat-normal-outer-space-17px`}>{locale.LEAVE_REVIEW_RATING}</label>

        <div ref={startRef} className={styles['stars-wrapper']}>
          <Rating
            readonly={false}
            stars={rating}
            onClick={() => {
              if (startRef.current?.classList.contains(styles['err'])) {
                startRef.current?.classList.remove(styles['err']);
              }
            }}
            setRating={setRating} // Pass setRating down to Rating component
          />
        </div>

        <form onSubmit={onFormSubmit} className={styles['rating-form']} autoComplete={'true'}>
          <input
            type={'text'}
            ref={firstNameInput}
            onBlur={() => validateDefInput(firstNameInput, styles, ValidationContext.ON_BLUR, locale.LEAVE_REVIEW_ERROR_NAME)}
            onChange={() => validateDefInput(firstNameInput, styles, ValidationContext.ON_CHANGE, locale.LEAVE_REVIEW_ERROR_NAME)}
            placeholder={locale.LEAVE_REVIEW_FIRSTNAME_LABEL}
            disabled={true}
            defaultValue={userData?.firstname}
            className={`${styles['input']} montserrat-medium-outer-space-15px`}
          />

          <input
            type={'text'}
            ref={lastNameInput}
            onBlur={() => validateDefInput(lastNameInput, styles, ValidationContext.ON_BLUR, locale.LEAVE_REVIEW_ERROR_NAME)}
            onChange={() => validateDefInput(lastNameInput, styles, ValidationContext.ON_CHANGE, locale.LEAVE_REVIEW_ERROR_NAME)}
            placeholder={locale.LEAVE_REVIEW_LASTNAME_LABEL}
            disabled={true}
            defaultValue={userData?.lastname}
            className={`${styles['input']} montserrat-medium-outer-space-15px`}
          />

          <textarea
            ref={reviewInput}
            onBlur={() => validateDefInput(reviewInput, styles, ValidationContext.ON_BLUR, locale.LEAVE_REVIEW_TEXT_ERR_MSG, 5)}
            onChange={() => validateDefInput(reviewInput, styles, ValidationContext.ON_CHANGE, locale.LEAVE_REVIEW_TEXT_ERR_MSG, 5)}
            placeholder={locale.LEAVE_REVIEW_TEXT_AREA}
            className={`${styles['review-input']} montserrat-normal-outer-space-15px`}
          />

          <button className={`${styles['submit-btn']} montserrat-medium-outer-space-17px`} type={'submit'}>
            {locale.LEAVE_REVIEW_BTN}
          </button>
        </form>
      </div>
    </section>
  );
};

export default LeaveReview;
