export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER"
}

export enum ValidationContext {
    ON_BLUR,
    ON_SUBMIT,
    ON_CHANGE
}

export enum AppointmentStatus {
    SCHEDULED = "SCHEDULED",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED",
    CONFIRMED = "CONFIRMED"
}

export enum MakeUpType {
    SERVICE = "SERVICE",
    COURSE = "COURSE"
}

export enum AuthProvider {
    KEYCLOAK = "KEYCLOAK",
    GOOGLE = "GOOGLE"
}