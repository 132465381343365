import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './Auth.module.css';
import backgroundPicture from '../../images/picture-login-1@1x.png';
import Login from '../../modules/auth/Login/Login';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Register from '../../modules/auth/Register/Register';
import SectionHeading from '../../modules/shared/components/SectionHeading/SectionHeading';
import flower from '../../images/group-1-1@2x.svg';
import { useLang } from '../../store/context/lang-context';
import useAuthLocale from './AuthLocale';
import googleIcon from '../../images/google-icon@2x.png';
import { useGoogleLogin } from '@react-oauth/google';
import { AuthService } from '../../modules/auth/services/authService';
import { catchError, map } from 'rxjs/operators';
import { generateUUID, mapAuthData, toastObj } from '../../utils/utils';
import { AuthProvider } from '../../enums/enums';
import { TokenContext } from '../../store/redux/auth/enums';
import { toast } from 'react-toastify';
import { of, Subject, takeUntil } from 'rxjs';
import Loader from '../../modules/shared/components/Loader/Loader';
import useAuthActions from '../../store/redux/auth/useAuthActions';
import { RememberMeContext } from '../../store/context/remember-me-context';
import { cookieRememberMe } from '../../cookies/constants';
import { useCookies } from 'react-cookie';
import { AuthLoaderContext, useAuthLoader } from '../../store/context/auth-loader';
import { SESSION_ID } from '../../common/constants';
import useGalleryImages from '../../store/redux/gallery-images/useGalleryImages';
import useFooterLocale from '../../modules/shared/components/Footer/FooterLocale';

interface LoginPageProps {}

enum AuthPageContext {
  LOGIN = 'login',
  REGISTER = 'register',
}

const Auth: FC<LoginPageProps> = () => {
  /**
   * The state of the auth loader
   */
  const { isLoading, setIsLoading } = useAuthLoader();
  const [isLoginLoadingState, setIsLoginLoadingState] = useState(isLoading);

  /**
   * The state of the remember me checkbox
   */
  const { rememberMe } = useContext(RememberMeContext);
  const [rememberMeState, setRememberMeState] = useState(rememberMe);

  const [urlPath, setUrlPath] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookie, setCookie] = useCookies([cookieRememberMe]);

  const { lang } = useLang();
  const { clearImageState } = useGalleryImages();

  const location = useLocation();
  const locales = useAuthLocale();
  const footerLocale = useFooterLocale();
  const navigate = useNavigate();
  const rememberMeRef = useRef<HTMLInputElement>(null);

  const destroy$ = new Subject<void>();

  useEffect(() => {
    return () => {
      destroy$.next();
      destroy$.complete();
    };
  }, []);

  useEffect(() => {
    setUrlPath(location.pathname);
  }, [location.pathname, urlPath]);

  const { storeAuthData } = useAuthActions();

  const login = useGoogleLogin({
    /**
     * further steps
     * 1. exchange code for access token and refresh token and id token
     * 2. safe the refresh token for further use. It does not expire
     * 3. use the id token to authenticate the user on the backend
     * @param res
     */
    onSuccess: ({ code }) => {
      setIsLoading(true);

      AuthService.exchangeGoogleCode(code)
        .pipe(
          map(({ data }) => {
            const authData = mapAuthData(data, AuthProvider.GOOGLE, TokenContext.OBTAIN);
            navigate(`/${lang}/`);
            setIsLoading(false);

            sessionStorage.setItem(SESSION_ID, generateUUID());

            const expiresInDays = 10;
            const expirationDate = new Date();

            expirationDate.setDate(expirationDate.getDate() + expiresInDays);

            setCookie(cookieRememberMe, rememberMeState, {
              path: '/',
              sameSite: 'strict',
              expires: expirationDate,
            });

            clearImageState();
            return storeAuthData(authData, AuthProvider.GOOGLE, TokenContext.OBTAIN);
          }),
          takeUntil(destroy$),
          catchError((error: Error) => {
            console.error(error);
            toast.error(locales.LOGIN_ERROR_MSG, toastObj);
            setIsLoading(false);

            return of({ error: error.message });
          })
        )
        .subscribe();
    },
    flow: 'auth-code',
  });
  return (
    <RememberMeContext.Provider value={{ rememberMe: rememberMeState, setRememberMe: setRememberMeState }}>
      <AuthLoaderContext.Provider value={{ isLoading: isLoginLoadingState, setIsLoading: setIsLoginLoadingState }}>
        {isLoginLoadingState && <Loader />}
        <section className={styles.AuthPage} data-testid="Auth">
          <img src={backgroundPicture} alt="background" className={styles.background} />

          <div className={styles['auth-container']}>
            <SectionHeading
              title={urlPath.includes('login') ? locales.LOGIN_TITLE : locales.REGISTER_TITLE}
              sentence={undefined}
              flowerSrc={flower}
            />
            {urlPath.includes(AuthPageContext.LOGIN) ? <Login /> : <Register />}

            <hr />
            <p
              className={`${
                urlPath.includes(AuthPageContext.LOGIN) ? styles.or : styles['or-reg']
              } montserrat-medium-outer-space-17px`}
            >
              {locales.OR}
            </p>

            <button onClick={login} className={`${styles['google-btn']} montserrat-medium-outer-space-17px`}>
              <span>
                <img src={googleIcon} alt={'google'} className={styles['google-icon']} />
              </span>
              <p className={styles['google-label']}>{locales.LOGIN_GOOGLE_BTN}</p>
            </button>
            <p className={`${styles['google-toc']} montserrat-medium-outer-space-12px`}>*{locales.GOOGLE_TOC}*</p>

            <label className={styles['container']}>
              <p className={`${styles['remember-me-text']} montserrat-medium-outer-space-17px`}>{locales.AUTH_KEEP_ME_LOGGED_IN}</p>
              <input type={'checkbox'} ref={rememberMeRef} onChange={() => setRememberMeState(rememberMeRef.current!.checked)} />
              <span className={styles['checkmark']}></span>
            </label>
            <div className={styles['toc-wrapper']}>
              <Link to={`/${lang}/terms-and-conditions`} className={styles['toc-link']}>
                {footerLocale.PRIVACY_POLICY}
              </Link>
              <Link to={`/${lang}/privacy-policy`} className={styles['toc-link']}>
                {footerLocale.GENERAL_TERMS}
              </Link>
            </div>
          </div>
        </section>
      </AuthLoaderContext.Provider>
    </RememberMeContext.Provider>
  );
};

export default Auth;
