import {useDispatch} from 'react-redux';
import {GoogleLogoutReqBody, KeycloakLogoutReqBody} from "../../../modules/auth/interfaces/LogoutResBody";
import {AuthProvider} from "../../../enums/enums";
import {TokenContext} from "./enums";
import {loginReqAction, logoutReqAction} from "./authReducers";
import {AuthData} from "../../../modules/auth/interfaces/AuthData";

const useAuthActions = () => {
    const dispatch = useDispatch();

    const storeAuthData = (payload: AuthData, authProvider: AuthProvider, tokenCtx: TokenContext) => {
        dispatch(loginReqAction({
            authProvider: authProvider,
            authData: payload,
            tokenCtx: tokenCtx,
            error: null
        }))
    }

    const doKeycloakLogout = (keycloakLogoutReqBody: KeycloakLogoutReqBody) => {
        dispatch(logoutReqAction({
            authProvider: AuthProvider.KEYCLOAK,
            keycloakReqBody: keycloakLogoutReqBody,
            googleReqBody: null,
            error: null
        }))
    }

    const doGoogleLogout = (googleLogoutReqBody: GoogleLogoutReqBody) => {
        dispatch(logoutReqAction({
            authProvider: AuthProvider.GOOGLE,
            keycloakReqBody: null,
            googleReqBody: googleLogoutReqBody,
            error: null
        }))
    }

    return {
        storeAuthData,
        doKeycloakLogout,
        doGoogleLogout
    }
};

export default useAuthActions;