import {catchError, filter, map, switchMap} from "rxjs/operators";
import {
    appointmentsFailureAction,
    appointmentsReqAction,
    appointmentsSuccessAction,
    clearAppointmentsAction
} from "./appointmentReducers";
import {FetchAppointmentsAct} from "./interfaces";
import AppointmentService from "../../../modules/shared/services/AppointmentService";
import {AxiosResponse} from "axios";
import {Appointment} from "../../../interfaces/Appointment";
import {of} from "rxjs";
import {logoutSuccessAction} from "../auth/authReducers";

export const appointmentsEpic = (action$: any,) => action$.pipe(
    filter(appointmentsReqAction.match),
    switchMap((action: FetchAppointmentsAct) => {
        return AppointmentService.fetchAppointments(action.payload.reqRsltLimit!, action.payload.reqRsltOffset!).pipe(
            map((res: AxiosResponse<Appointment[]>) => {
                if (res.status !== 200) {
                    return {
                        type: appointmentsFailureAction.type,
                        payload: {
                            appointments: null,
                            reqRsltLimit: null,
                            reqRsltOffset: null,
                            error: "No appointments found"
                        }
                    }
                }

                return {
                    type: appointmentsSuccessAction.type,
                    payload: {
                        appointments: res.data,
                        reqRsltLimit: action.payload.reqRsltLimit,
                        reqRsltOffset: action.payload.reqRsltOffset,
                        error: null
                    }
                }
            }),
            catchError(errorHandler)
        )
    }),
    catchError(errorHandler)
);

export const clearAppointmentsEpic = (action$: any) => action$.pipe(
    filter(logoutSuccessAction.match),
    switchMap(() => {
        return of({
            type: clearAppointmentsAction.type,
            payload: {
                appointments: null,
                reqRsltLimit: null,
                reqRsltOffset: null,
                error: null
            }
        })
    })
);

const errorHandler = (err: Error) => {
    return of({
        type: appointmentsFailureAction.type,
        payload: {
            appointments: null,
            reqRsltLimit: null,
            reqRsltOffset: null,
            error: err.message
        }
    })
}