import {useEffect} from "react";

const useDocumentBodyOverflowForModal = (isOpen: boolean) => {
    useEffect(() => {
        isOpen
            ? document.body.style.overflow = "hidden"
            : document.body.style.overflow = "unset"
    }, [isOpen])
}

export default useDocumentBodyOverflowForModal;