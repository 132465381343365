import {AppointmentsState, FetchAppointmentsAct} from "./interfaces";
import {CaseReducer, createSlice} from "@reduxjs/toolkit";
import {Action} from "redux";

const initialState: AppointmentsState = {
    appointments: null,
    loading: false,
    fetched: false,
    error: null,
}

const fetchAppointmentsReq: CaseReducer<AppointmentsState, FetchAppointmentsAct> = (state, action: FetchAppointmentsAct) => {
    state.appointments = null;
    state.loading = true;
    state.fetched = false;
    state.error = null;
}

const fetchAppointmentsSuccess: CaseReducer<AppointmentsState, FetchAppointmentsAct> = (state, action: FetchAppointmentsAct) => {
    state.appointments = action.payload?.appointments || null;
    state.loading = false;
    state.fetched = true;
    state.error = null;
}

const fetchAppointmentsFailure: CaseReducer<AppointmentsState, FetchAppointmentsAct> = (state, action: FetchAppointmentsAct) => {
    state.appointments = null;
    state.loading = false;
    state.fetched = true;
    state.error = action.payload?.error || null;
}

const clearAppointments: CaseReducer<AppointmentsState, Action<string>> = (state, action: Action) => {
    state.appointments = null;
    state.loading = false;
    state.fetched = false;
    state.error = null;
}

export const UserAppointmentsSlice = createSlice({
    name: 'userAppointments',
    initialState,
    reducers: {
        fetchAppointmentsReq,
        fetchAppointmentsSuccess,
        fetchAppointmentsFailure,
        clearAppointments,
    }
});

export const {
    fetchAppointmentsReq: appointmentsReqAction,
    fetchAppointmentsSuccess: appointmentsSuccessAction,
    fetchAppointmentsFailure: appointmentsFailureAction,
    clearAppointments: clearAppointmentsAction,
} = UserAppointmentsSlice.actions;