import {_Axios} from "../../../services-config/axios/axios-conf";
import {AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from "axios";
import {retry, catchError} from 'rxjs/operators';
import qs from 'qs';
import {
    CONTENT_TYPE_FORM_URL_ENCODED,
    ACCEPT_JSON, CLIENT_ID, GRAND_TYPE_REFRESH_TOKEN, AUTH_CODE_GRANT_TYPE, CONTENT_TYPE, CONTENT_TYPE_JSON
} from "../constants/auth-constants";
import {LoginReqBody} from "../interfaces/LoginReqBody";
import {Observable, of, throwError} from "rxjs";
import {GoogleLogoutReqBody, KeycloakLogoutReqBody} from "../interfaces/LogoutResBody";
import {KeycloakLoginResBody} from "../interfaces/KeycloakLoginResBody";
import {User} from "../interfaces/User";
import {AuthProvider} from "../../../enums/enums";
import {GoogleLoginResBody} from "../interfaces/GoogleLoginResBody";
import {RegisterReqBody} from "../interfaces/RegisterReqBody";
import {UpdateCredsReqBody} from "../interfaces/Credentials";

const authHost: string | undefined = process.env.REACT_APP_AUTH_HOST;
const apiHost: string | undefined = process.env.REACT_APP_API_HOST;
const googleApiHost: string | undefined = process.env.REACT_APP_GOOGLE_API_HOST;
const googleClientId: string | undefined = process.env.REACT_APP_GOOGLE_API_ID;
const googleClientSecret: string | undefined = process.env.REACT_APP_GOOGLE_API_SECRET;
const tokenEndpoint: string = `${authHost}/realms/make-up-app/protocol/openid-connect/token`;
const logoutEndPoint: string = `${authHost}/realms/make-up-app/protocol/openid-connect/logout`;
const keycloakUserEndPoint: string = `${apiHost}/api/user/me`;
const googleUserEndPoint: string = `${apiHost}/api/user/login?auth_provider=google`;
const googleTokenEndpoint: string = `${googleApiHost}/token`;
const googleRevokeEndpoint: string = `${googleApiHost}/revoke`;
const registerEndPoint: string = `${apiHost}/api/user/register`;
const credentialsEndPoint: string = `${apiHost}/api/user/credentials`;

export const AuthService = {
    login: (body: LoginReqBody): Observable<AxiosResponse<KeycloakLoginResBody>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        const reqBody: Object = qs.stringify(body);

        return _Axios.DefInstance.post(tokenEndpoint, reqBody, options)
    },

    logout: (body: KeycloakLogoutReqBody | null): Observable<AxiosResponse> => {
        if (!body) return throwError(() => new Error('Logout body is null'));


        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers,
        }

        let reqBody: Object = qs.stringify(body);

        return _Axios.DefInstance.post(logoutEndPoint, reqBody, options);
    },

    fetchUser: (authProvider: AuthProvider | null): Observable<AxiosResponse<User> | null> => {
        const endpoint: string = authProvider === AuthProvider.GOOGLE
            ? googleUserEndPoint
            : keycloakUserEndPoint;

        const headers: AxiosRequestHeaders = {
            accept: ACCEPT_JSON,
            //  ...(etag ? {'If-None-Match': etag} : {}),
            'Cache-Control': 'no-cache',
        }

        const options: AxiosRequestConfig = {
            headers,
        }

        return _Axios.AuthInstance.get(endpoint, options)
            .pipe(
                catchError((err: AxiosError) => {
                    if (err.response?.status === 401) {
                        return _Axios.AuthInstance.get(endpoint, options);
                    }

                    return of(null);
                }),
                retry(0)
            );
    },

    refreshKeycloakAccessToken: (refreshToken: string): Observable<AxiosResponse<KeycloakLoginResBody>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        const reqBody: Object = qs.stringify({
            client_id: CLIENT_ID,
            grant_type: GRAND_TYPE_REFRESH_TOKEN,
            refresh_token: refreshToken,
        });

        return _Axios.DefInstance.post(tokenEndpoint, reqBody, options)
    },

    refreshGoogleAccessToken: (refreshToken: string): Observable<AxiosResponse<GoogleLoginResBody>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        const reqBody: Object = qs.stringify({
            client_id: googleClientId,
            client_secret: googleClientSecret,
            grant_type: GRAND_TYPE_REFRESH_TOKEN,
            refresh_token: refreshToken,
        });

        return _Axios.DefInstance.post(googleTokenEndpoint, reqBody, options)
    },

    /**
     * Exchanges Google code for access token & refresh token
     * @param idToken
     */
    exchangeGoogleCode: (idToken: string): Observable<AxiosResponse<GoogleLoginResBody>> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        const reqBody: Object = qs.stringify({
            client_id: googleClientId,
            client_secret: googleClientSecret,
            code: idToken,
            grant_type: AUTH_CODE_GRANT_TYPE,
            redirect_uri: `${window.location.origin}`,
        });

        return _Axios.DefInstance.post(googleTokenEndpoint, reqBody, options)
    },

    googleLogout: (reqBody: GoogleLogoutReqBody): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_FORM_URL_ENCODED,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers,
        }

        return _Axios.DefInstance.post(googleRevokeEndpoint, qs.stringify(reqBody), options)
    },

    register: (body: RegisterReqBody): Observable<AxiosResponse> => {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        return _Axios.DefInstance.post(registerEndPoint, JSON.stringify(body), options);
    },

    updateUserCredentials(body: UpdateCredsReqBody): Observable<AxiosResponse> {
        const headers: AxiosRequestHeaders = {
            [CONTENT_TYPE]: CONTENT_TYPE_JSON,
            accept: ACCEPT_JSON,
        }

        const options: AxiosRequestConfig = {
            headers
        }

        return _Axios.AuthInstance.put(credentialsEndPoint, JSON.stringify(body), options);
    }
};

Object.freeze(AuthService);

/**
 * Google logout / revoke access token & refresh token
 *
 * Revoking a token
 * In some cases a user may wish to revoke access given to an application.
 * A user can revoke access by visiting Account Settings.
 * See the Remove site or app access section of the Third-party sites & apps with access to your account support document for more information.
 *
 * It is also possible for an application to programmatically revoke the access given to it.
 * Programmatic revocation is important in instances where a user unsubscribes, removes an application,
 * or the API resources required by an app have significantly changed. In other words,
 * part of the removal process can include an API request to ensure the permissions previously granted to the application are removed.
 *
 * To programmatically revoke a token, your application makes a request to https://oauth2.googleapis.com/revoke and includes the token as a parameter:
 *
 * curl -d -X -POST --header "Content-type:application/x-www-form-urlencoded" \
 *         https://oauth2.googleapis.com/revoke?token={token}
 * The token can be an access token or a refresh token. If the token is an access token, and it has a corresponding refresh token,
 * the refresh token will also be revoked.
 *
 * If the revocation is successfully processed, then the HTTP status code of the response is 200. For error conditions,
 * an HTTP status code 400 is returned along with an error code.
 */