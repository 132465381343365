import React, {FC} from 'react';
import styles from './ToC.module.css';

interface ToCProps {
}

const ToC: FC<ToCProps> = () => (
    <div className={styles.ToC} data-testid="ToC">

        <h1>Общи условия</h1>

        <p>
            Този документ определя общите условия за вашето използване на <a
            href="https://blagadimitrova.bg">blagadimitrova.bg</a> уебсайт (“Уебсайт” или “Услуга”) и всичките му
            свързани
            продукти и услуги (общо “Услуги”). Това Споразумение е правно обвързващо между вас (“Потребител”, “вие” или
            “ваш”)
            и оператора на този уебсайт (“Оператор”, “ние”, “нас” или “наш”). Ако влизате в това споразумение от името
            на бизнес
            или друг юридически субект, представлявате, че имате правомощията да свържете такъв субект към това
            споразумение, в което случай термините “Потребител”, “вие” или “ваш” се отнасят до такъв субект. Ако
            нямате такива
            правомощия, или ако не се съгласявате с условията на това споразумение, не трябва да приемате това
            споразумение и не
            може да имате достъп до и да използвате уебсайта и услугите. Като имате достъп до и използвате уебсайта и
            услугите,
            признавате, че сте прочели, разбрали и се съгласявате да бъдете обвързани от условията на това Споразумение.
            Признавате, че това Споразумение е договор между вас и Оператора, въпреки че е електронен и не е физически
            подписан
            от вас, и регулира вашето използване на уебсайта и услугите.
        </p>
        <div className="wpembed-toc">

            <h3>Съдържание</h3>

            <ol className="wpembed-toc">
                <li><a href="#our-services">Услуги</a></li>
                <li><a href="#accounts-and-membership">Акаунти и членство</a></li>
                <li><a href="#user-content">Съдържание на потребителя</a></li>
                <li><a href="#backups">Резервни копия</a></li>
                <li><a href="#links-to-other-resources">Връзки към други ресурси</a></li>
                <li><a href="#prohibited-uses">Забранени употреби</a></li>
                <li><a href="#intellectual-property-rights">Права върху интелектуална собственост</a></li>
                <li><a href="#limitation-of-liability">Ограничение на отговорността</a></li>
                <li><a href="#indemnification">Обезщетение</a></li>
                <li><a href="#severability">Разделност</a></li>
                <li><a href="#dispute-resolution">Решаване на спорове</a></li>
                <li><a href="#changes-and-amendments">Промени и допълнения</a></li>
                <li><a href="#acceptance-of-these-terms">Приемане на тези условия</a></li>
                <li><a href="#contacting-us">Връзка с нас</a></li>
            </ol>
        </div>
        <h2 id="our-services">1.&nbsp; &nbsp;Услуги и курсове</h2>

        <p>Ние предлагаме разнообразие от услуги по грим ("Услуги") и курсове по грим ("Курсове"), подробно описани на
            нашия уебсайт. Запазваме си правото да добавяме, променяме или преустановяваме някоя от нашите Услуги или
            Курсове по наша преценка. Всяки нови или променени функции също са предмет на тези условия.</p>

        <h3 id="no-guarantee">Без гаранция</h3>

        <p>Въпреки че, се стремим да предоставяме висококачествени Услуги и Курсове, не можем да гарантираме конкретни
            резултати от използването на нашите Услуги или Курсове. Приемането и прилагането на наученото от нашите
            Курсове е изцяло отговорност на участника. Не носим отговорност за никакви загуби или вреди, произтичащи от
            използването на нашите Услуги или Курсове.</p>

        <h2 id="accounts-and-membership">2.&nbsp; &nbsp;Акаунти и членство</h2>
        <p>Ако създадете акаунт на уебсайта, вие сте отговорни за поддържането на сигурността на вашия акаунт и сте
            напълно
            отговорни за всички дейности, които се извършват под акаунта и всички други действия, предприети във връзка
            с него.
            Ние можем, но не сме задължени, да наблюдаваме и преглеждаме новите акаунти преди да можете да влезете и да
            започнете да използвате услугите. Предоставянето на лъжлива контактна информация от каквото и да било
            естество може
            да доведе до прекратяване на вашия акаунт. Вие трябва незабавно да ни уведомите за всички неоторизирани
            използвания
            на вашия акаунт или за всички други нарушения на сигурността. Ние няма да носим отговорност за каквито и да
            било
            действия или пропуски от ваша страна, включително за всички щети от всякакъв вид, причинени в резултат на
            такива
            действия или пропуски. Можем да спрем, деактивираме или изтрием вашия акаунт (или негова част), ако
            определим, че
            сте нарушили кое да е от условията на това споразумение или че вашето поведение или съдържание биха могли да
            навредят на нашата репутация и добро име. Ако изтрием вашия акаунт по горните причини, не можете да се
            регистрирате
            отново за нашите услуги. Можем да блокираме вашия имейл адрес и IP адрес, за да предотвратим по-нататъшна
            регистрация.</p>
        <h2 id="user-content">3.&nbsp; &nbsp;Съдържание на потребителя</h2>

        <p>Ние не притежаваме никакви данни, информация или материали (общо, &#8220;Съдържание&#8221;), които вие
            представяте на
            уебсайта по време на използването на Услугата. Вие носите единствена отговорност за точността, качеството,
            цялостта,
            законността, надеждността, подходящостта, собствеността на интелектуалната собственост или правото на
            използване на
            всичко представено Съдържание. Можем, но не сме задължени, да наблюдаваме и преглеждаме Съдържанието на
            уебсайта,
            представено или създадено чрез нашите услуги от вас. Вие ни давате разрешение да имаме достъп, да копираме,
            разпространяваме, съхраняваме, предаваме, преформатираме, показваме и изпълняваме Съдържанието на вашия
            потребителски акаунт, само когато е необходимо за целта на предоставянето на Услугите към вас. Без
            ограничаване на
            кое да е от тези представления или гаранции, ние имаме правото, макар и не задължението, по наше собствено
            усмотрение, да отказваме или премахваме всяко Съдържание, което според нашето разумно мнение нарушава някоя
            от
            нашите политики или по някакъв начин е вредно или неприемливо. Вие също ни давате лиценз да използваме,
            възпроизвеждаме, адаптираме, модифицираме, публикуваме или разпространяваме Съдържанието, създадено от вас
            или
            съхранено във вашия потребителски акаунт, за търговски, маркетингови или други подобни цели.</p>

        <h2 id="backups">4.&nbsp; &nbsp;Резервни копия</h2>
        <p>Ние извършваме редовни резервни копия на уебсайта и неговото Съдържание и ще направим всичко по силите си, за
            да
            гарантираме пълнотата и точността на тези резервни копия. В случай на хардуерна грешка или загуба на данни,
            ще
            възстановим резервните копия автоматично, за да минимизираме въздействието и времето за прекъсване на
            работа.</p>

        <h2 id="links-to-other-resources">5.&nbsp; &nbsp;Връзки към други ресурси</h2>
        <p>Въпреки че Уебсайтът и Услугите може да водят до други ресурси (като уебсайтове, мобилни приложения и др.),
            ние не подразбираме, директно или косвено, одобрение, асоциация, спонсорство, подкрепа или афилиация с
            каквато и да било свързана ресурс, освен ако не е специално посочено тук. Ние не носим отговорност за
            изследването или оценката, и не гарантираме предложенията на каквито и да било предприятия или физически
            лица или съдържанието на техните ресурси. Ние не поемаме никаква отговорност или отговорност за действията,
            продуктите, услугите и съдържанието на каквито и да било други трети страни. Вие трябва внимателно да
            прегледате правните изявления и другите условия за използване на всеки ресурс, до който имате достъп чрез
            връзка на Уебсайта. Вашето свързване с каквито и да било други извънсайтови ресурси е на ваш собствен
            риск.</p>

        <h2 id="prohibited-uses">6.&nbsp; &nbsp;Забранени използвания</h2>
        <p>Допълнително към другите условия, посочени в Споразумението, ви е забранено да използвате уебсайта и услугите
            или съдържанието: (a) за каквато и да е незаконна цел; (b) да подбуждате други да извършват или участват в
            каквито и да е незаконни действия; (c) да нарушавате каквито и да е международни, федерални, провинциални
            или държавни регламенти, правила, закони или местни наредби; (d) да нарушавате нашите права на интелектуална
            собственост или правата на интелектуална собственост на други; (e) да тормозите, злоупотребявате, обиждате,
            вредите, клеветите, кълвате, пренебрегвате, заплашвате или дискриминирате на базата на пол, сексуална
            ориентация, религия, етническа принадлежност, раса, възраст, национален произход или увреждане; (f) да
            представяте грешна или подвеждаща информация; (g) да качвате или изпращате вируси или всякакъв друг вид
            вреден код, който ще бъде или може да бъде използван по начин, който ще повлияе на функционалността или
            работата на уебсайта и услугите, продуктите и услугите на трети страни или интернет; (h) да изпращате спам,
            рибарите, подправяте, претекстите, паяжините, сканирате или скребете; (i) за всякаква непристойна или
            аморална цел; или (j) да нарушавате или заобикаляте сигурностните функции на уебсайта и услугите, продуктите
            и услугите на трети страни или интернет. Ние запазваме правото да прекратим използването ви на уебсайта и
            услугите за нарушаване на някое от забранените използвания.</p>

        <h2 id="intellectual-property-rights">7.&nbsp; &nbsp;Права на интелектуална собственост</h2>
        <p>&#8220;Права на интелектуална собственост&#8221; означава всички настоящи и бъдещи права, предоставени от
            закон, обичайно право или справедливост във връзка с авторските права и свързаните права, марките,
            дизайните, патентите, изобретенията, добрата воля и правото на иск за недопустима конкуренция, права върху
            изобретения, права за използване, и всички други права на интелектуална собственост, във всеки случай,
            независимо дали са регистрирани или не, и включително всички заявления и права за кандидатстване и
            придобиване, права за приоритет от такива права и всички подобни или еквивалентни права или форми на защита
            и всички други резултати от интелектуална дейност, които съществуват или ще съществуват сега или в бъдеще в
            която и да е част на света. Това Споразумение не прехвърля на вас никаква интелектуална собственост,
            притежавана от Оператора или трети страни, и всички права, заглавия и интереси към такова имущество ще
            останат (между страните) изключително с Оператора. Всички търговски марки, услуги, графики и лога,
            използвани във връзка с уебсайта и услугите, са търговски марки или регистрирани търговски марки на
            Оператора или на неговите лицензианти. Други търговски марки, услуги, графики и лога, използвани във връзка
            с уебсайта и услугите, могат да бъдат търговски марки на други трети страни. Използването на уебсайта и
            услугите не ви дава право или лиценз да възпроизвеждате или по друг начин да използвате каквито и да е
            търговски марки на Оператора или трети страни.</p>

        <h2 id="limitation-of-liability">8.&nbsp; &nbsp;Ограничаване на отговорността</h2>
        <p>До максималната степен, допустима от приложимото право, при никакви обстоятелства Операторът, неговите
            филиали, директори, служители, агенти, доставчици или лицензори няма да носят отговорност пред кого и да е
            за непреки, случайни, специални, наказателни, допълнителни или последващи щети (включително, без
            ограничение, щети за загубени печалби, приходи, продажби, добра воля, използване на съдържание, въздействие
            върху бизнеса, прекъсване на бизнеса, загуба на очаквани спестявания, загуба на бизнес възможности) както и
            да са причинени, по каквато и да е теория за отговорност, включително, без ограничение, договор, деликт,
            гаранция, нарушение на законово задължение, небрежност или по друг начин, дори ако страната, отговаряща за
            щетите, е била информирана за възможността за такива щети или можеше да предвиди такива щети. До
            максималната степен, допустима от приложимото право, общата отговорност на Оператора и неговите филиали,
            служители, агенти, доставчици и лицензори, свързани с услугите, ще бъде ограничена до сума, не по-голяма от
            един лев или до каквито суми сте платили наистина в брой на Оператора за едномесечния период преди първото
            събитие или обстоятелство, даващо повод за такава отговорност. Ограниченията и изключенията също се
            прилагат, ако това средство не ви компенсира напълно за каквито и да е загуби или не успее да изпълни своята
            съществена цел.</p>

        <h2 id="indemnification">9.&nbsp; &nbsp;Обезщетение</h2>
        <p>Вие се съгласявате да обезщетите и да предпазите Оператора и неговите филиали, директори, служители, агенти,
            доставчици и лицензори от и срещу всякакви задължения, загуби, щети или разходи, включително разумни
            адвокатски такси, понесени във връзка с или произтичащи от трети страни обвинения, искове, действия, спорове
            или искания, заявени срещу която и да е от тях в резултат на или във връзка с вашия Съдържание, вашето
            използване на Уебсайта и Услугите или всяко умишлено престъпно действие от ваша страна.</p>

        <h2 id="severability">10.&nbsp; &nbsp;Разделимост</h2>
        <p>Всички права и ограничения, съдържащи се в това Споразумение, могат да бъдат упражнявани и ще бъдат приложими
            и обвързващи само до степента, в която те не нарушават приложимите закони и се предвижда да бъдат ограничени
            до степента, необходима, така че те да не направят това Споразумение незаконно, невалидно или неприложимо.
            Ако някоя разпоредба или част от това Споразумение бъде обявено за незаконно, невалидно
            или неприложимо от съд, компетентен по въпроса, е намерението на страните останалите разпоредби или части от
            тях да представляват тяхното споразумение по отношение на предмета на това, и всички такива останали
            разпоредби или части от тях да останат в пълна сила и ефект.</p>

        <h2 id="dispute-resolution">11.&nbsp; &nbsp;Решаване на спорове</h2>
        <p>Създаването, тълкуването и изпълнението на това Споразумение и всички спорове, произтичащи от него, трябва да
            бъдат регулирани от материалните и процедурните закони на България без отчитане на нейните правила за
            конфликти или избор на закон и, до приложимата степен, законите на България. Ексклузивната юрисдикция и
            място за действия, свързани с предмета на настоящото, трябва да бъдат съдилища, разположени в България, и
            вие по този начин се подчинявате на личната юрисдикция на такива съдилища. Вие по този начин отказвате всяко
            право на съдебен процес по всяко производство, произтичащо от или свързано с това Споразумение. Конвенцията
            на Обединените нации за договорите за международна продажба на стоки не се прилага за това Споразумение.</p>

        <h2 id="changes-and-amendments">12.&nbsp; &nbsp;Промени и поправки</h2>
        <p>Запазваме си правото да променяме това Споразумение или условията, свързани с Уебсайта и Услугите, по всяко
            време по наше усмотрение. Когато го направим, ще променим датата на актуализация в долната част на тази
            страница, ще публикуваме известие на основната страница на Уебсайта, ще ви изпратим имейл, за да ви
            уведомим. Можем също да ви уведомим по други начини по наше усмотрение, например чрез контактната
            информация, която сте предоставили.</p>
        <p>Актуализирана версия на това Споразумение ще влезе в сила незабавно след публикуването на преработеното
            Споразумение, освен ако не е посочено друго. Вашето продължаващо използване на Уебсайта и Услугите след
            влизането в сила на преработеното Споразумение (или друг акт, посочен по това време) ще се счита за съгласие
            с тези промени.</p>

        <h2 id="acceptance-of-these-terms">13.&nbsp; &nbsp;Приемане на тези условия</h2>
        <p>Потвърждавате, че сте прочели това Споразумение и се съгласявате с всичките му условия. Като получавате
            достъп и използвате Уебсайта и Услугите, вие се съгласявате да бъдете обвързани от това Споразумение. Ако не
            се съгласявате да спазвате условията на това Споразумение, не сте упълномощени да получавате достъп или да
            използвате Уебсайта и Услугите.
        </p>

        <h2 id="contacting-us">14.&nbsp; &nbsp;Свържете се с нас</h2>
        <p>Ако имате въпроси, опасения или жалби относно това Споразумение, ви препоръчваме да се свържете с нас, като
            използвате детайлите по-долу:</p>
        <p><a
            href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#115;e&#114;&#118;&#105;&#99;e&#64;&#98;la&#103;ad&#105;&#109;it&#114;ova.eu">s&#101;rvi&#99;e&#64;&#98;&#108;a&#103;&#97;di&#109;it&#114;o&#118;a&#46;e&#117;</a>
        </p>
        <p>Този документ беше последно актуализиран на 21 май 2023 година</p>

    </div>
);

export default ToC;
