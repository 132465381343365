import styles from "./GalleryModificationModal.module.css";
import React from "react";
import {GalleryNameInputProps} from "./types";

export function GalleryNameInput(props: GalleryNameInputProps): JSX.Element {
    const {
        onBlur,
        onChange,
        value,
        language,
        reference
    }: GalleryNameInputProps = props;

    return (
        <label className={`${styles.label} montserrat-normal-outer-space-17px`}>
            {`Име на галерията(${language})`}
            <input
                onBlur={onBlur}
                onChange={onChange}
                className={styles["input"]}
                type="text"
                defaultValue={value || ''}
                ref={reference}
            />
        </label>
    );
}