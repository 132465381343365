import React, {FC} from 'react';
import styles from './Loader.module.css';

interface LoaderProps {
}

const Loader: FC<LoaderProps> = () => (
        <React.Fragment>
            <div className={styles.overlay}/>
            <div className={styles.Loader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </React.Fragment>
    )
;

export default Loader;
