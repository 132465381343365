import React, {FC} from 'react';
import styles from './AppointmentAgitation.module.css';
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import flower from '../../../../images/path-1@2x.svg';
import useHomePageLocale from "../../locales/HomePageLocales";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface AppointmentAgitationProps {
}


const AppointmentAgitation: FC<AppointmentAgitationProps> = () => {
    const locales = useHomePageLocale();

    return (
        <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__rollIn"
            duration={0.8}>
            <section className={styles.AppointmentAgitation} data-testid="AppointmentAgitation">
                <h2 className={`${styles.title} monotypecorsiva-regular-normal-outer-space-40px`}>{locales.AGITATION_SECTION_TITLE}</h2>
                <p className={`${styles.sentence} text-51 montserrat-normal-midnight-17px`}>{locales.AGITATION_SECTION_SHORT_SENTENCE}</p>
                <ShowMoreBtn location={"/appointment"} label={locales.AGITATION_SECTION_BTN_LABEL}/>
                <img className={styles.flower} src={flower} alt={"flower"}/>
            </section>
        </AnimationOnScroll>
    );
}

export default AppointmentAgitation;
