import styles from './PromotionSlider.module.css';
import React, { FC } from 'react';
import SwiperCore, { Navigation, Pagination, Keyboard, EffectCreative, EffectFlip, Autoplay, EffectCube } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-creative';
import useWindowDimensions from '../../../../hooks/screen-resolution-checker';

interface PromotionSliderProps {
  slides: Array<string>;
  slidesMobile: Array<string>;
}

function imageMapper(styleClass: string, slides: Array<string>): Array<JSX.Element> {
  return slides.map((slide, index) => {
    return (
      <SwiperSlide zoom={false} className={styleClass} key={index}>
        <div>
          <img src={slide} alt={'slide'} />
        </div>
      </SwiperSlide>
    );
  });
}

SwiperCore.use([Pagination, Navigation, EffectFlip, Autoplay, EffectCube]);

const PromotionSlider: FC<PromotionSliderProps> = (props) => {
  return (
    <header className={styles['slider-container']}>
      {useWindowDimensions().width > 1200 ? (
        <React.Fragment>
          <Swiper
            loop={true}
            speed={1000}
            effect={'creative'}
            creativeEffect={{
              prev: {
                translate: [0, 0, -400],
              },
              next: {
                translate: ['120%', 0, 0],
              },
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            keyboard={true}
            modules={[Pagination, Navigation, EffectCreative, Autoplay, Keyboard]}
            className={styles['swiper-container-flip']}
          >
            {imageMapper(styles['swiper-slide'], props.slides)}
          </Swiper>
        </React.Fragment>
      ) : (
        <div
          style={{
            maxWidth: '500px',
            maxHeight: '500px',
            margin: '0 auto',
          }}
        >
          <Swiper
            effect={'cube'}
            loop={true}
            speed={1000}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectCube, Pagination, Autoplay]}
            className={styles['swiper-cube-container']}
          >
            {imageMapper(styles['swiper-slide-mobile'], props.slidesMobile)}
          </Swiper>
        </div>
      )}
    </header>
  );
};

export default PromotionSlider;
