import React, {FC} from 'react';
import styles from './HomeMyWorkMobile.module.css';
import myWorkFourth from '../../../../images/my-work-1.jpg';
import myWorkFifth from '../../../../images/my-work-4.jpg';
import myWorkSixth from '../../../../images/my-work-5.jpg';
import flower from "../../../../images/group-1-1@2x.svg";
import SectionHeading from "../../../shared/components/SectionHeading/SectionHeading";
import ShowMoreBtn from "../../../shared/components/ShowMoreBtn/ShowMoreBtn";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import {EffectCreative, Autoplay} from "swiper";
import useHomePageLocale from "../../locales/HomePageLocales";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface HomeMyWorkMobileProps {
}

const HomeMyWorkMobile: FC<HomeMyWorkMobileProps> = () => {
    const locales = useHomePageLocale();

    return (
        <section id="home-my-work" className={styles.HomeMyWorkMobile} data-testid="HomeMyWorkMobile">
            <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__flip"
                duration={1.2}
            >
                <div className={styles["info-wrapper"]}>
                    <SectionHeading title={locales.MY_WORK_TITLE} sentence={locales.MY_WORK_SHORT_SENTENCE}
                                    flowerSrc={flower}/>
                </div>
            </AnimationOnScroll>
            <Swiper
                grabCursor={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                }}
                effect={"creative"}
                creativeEffect={{
                    prev: {
                        shadow: false,
                        translate: ["-50%", 10, -800],
                    },
                    next: {
                        shadow: false,
                        translate: ["50%", 10, -800],
                    },
                }}
                modules={[EffectCreative, Autoplay]}
                className={styles["swiper-container"]}
            >
                <SwiperSlide className={styles.slide}><img src={myWorkFifth} alt={"alt"}/></SwiperSlide>
                <SwiperSlide className={styles.slide}><img src={myWorkSixth} alt={"alt"}/></SwiperSlide>
                <SwiperSlide className={styles.slide}><img src={myWorkFourth} alt={"alt"}/></SwiperSlide>
            </Swiper>
            <div id={styles["show-more-wrapper"]}>
                <ShowMoreBtn location={"/galleries"} label={locales.ABOUT_ME_BTN_LABEL}/>
            </div>
        </section>
    );
}

export default HomeMyWorkMobile;
