import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'bg',
    lng: 'bg',
    resources: {
        bg: {
            translations: require('../localization/bg/translations.json')
        },
        en: {
            translations: require('../localization/en/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
}).then(r => console.log("i18n initialized"));

i18n.languages = ['bg', 'en'];
